import React, { useState, SetStateAction, FC, useEffect } from 'react';

import TaskChildren from './TaskChildren';
import { StyledListContentText, StyledFilterList, StyledFilterButton } from './TaskDetails.styles';
import { STATUS } from 'Pages/Tasks/Tasks.types';
import { ButtonWrapper, ExpandButtonWrapper } from './TaskDetails.styles';

import { Grid, Typography } from '@material-ui/core';
import AnimateHeight from 'react-animate-height';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

interface Props {
  taskName: string;
  taskId: string;
  taskResults: Record<string, any>;
  height: number | string;
  setHeight: (value: SetStateAction<number | string>) => void;
  handleChildRefreshButtonClick: () => void;
}
export interface TaskResults {
  status: STATUS;
  children: unknown[];
  waiting: boolean;
}

const Status: { id: STATUS; label: string }[] = [
  { id: 'SUCCESS', label: 'Success' },
  { id: 'STARTED', label: 'Running' },
  { id: 'PENDING', label: 'Pending' },
  { id: 'FAILURE', label: 'Failure' },
  { id: 'REVOKED', label: 'Revoked' },
];

const TaskContent: FC<Props> = ({
  taskResults,
  height,
  setHeight,
  handleChildRefreshButtonClick,
}: any) => {
  const { children } = taskResults;
  const [filteredChildren, setFilteredChildren] = useState(children);

  const [loadingResults, setLoadingResults] = useState<boolean>(false);

  useEffect(() => {
    setFilteredChildren(children);
    setLoadingResults(false);
  }, [taskResults]);

  const showDetails = () => {
    setHeight('auto');
  };

  const hideDetails = () => {
    setHeight(0);
  };

  const handleSeeAllButtonClick = () => {
    setFilteredChildren(children);
    showDetails();
  };

  const childrenFilter = (status: STATUS | STATUS[]) => {
    return children.filter(({ taskResults }: { taskResults: TaskResults }) =>
      status.includes(taskResults.status)
    );
  };

  const completedCount = children.length - childrenFilter(['PENDING', 'STARTED']).length;

  // #TODO: Refacter the inline functions in the Status.map Typography element to states

  return (
    <>
      <StyledListContentText>
        <Typography variant="body2" component="p">
          <b>Completed Count:</b> {completedCount} / {children.length}
        </Typography>
        <Typography variant="body2" component="div">
          <StyledFilterList>
            {Status.map((row) => (
              <StyledFilterButton
                variant="form"
                color="default"
                status={childrenFilter(row.id).length !== 0 ? row.id : 'other'}
                key={row.label}
                onClick={() => setFilteredChildren(childrenFilter(row.id))}
              >
                <Grid onClick={showDetails}>{row.label}</Grid>
                <Grid>
                  <b>{childrenFilter(row.id).length}</b>
                </Grid>
              </StyledFilterButton>
            ))}
            <StyledFilterButton variant="form" color="primary" onClick={handleSeeAllButtonClick}>
              See All
            </StyledFilterButton>
          </StyledFilterList>
        </Typography>
        <ButtonWrapper>
          <ExpandButtonWrapper>
            {height === 'auto' ? (
              <ExpandLessIcon fontSize="large" onClick={hideDetails} />
            ) : (
              <ExpandMoreIcon fontSize="large" onClick={showDetails} />
            )}
          </ExpandButtonWrapper>
        </ButtonWrapper>
      </StyledListContentText>
      <AnimateHeight duration={500} height={height}>
        {filteredChildren.map(
          ({
            childId,
            parentId,
            taskResults,
          }: {
            childId: string;
            parentId: string;
            taskResults: TaskResults;
          }) => (
            <TaskChildren
              key={childId}
              id={childId}
              parentId={parentId}
              taskResults={taskResults}
              handleChildRefreshButtonClick={handleChildRefreshButtonClick}
              setLoadingResults={setLoadingResults}
              loadingResults={loadingResults}
            />
          )
        )}
      </AnimateHeight>
    </>
  );
};

export default TaskContent;
