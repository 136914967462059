import styled from 'styled-components';

export const StyledList = styled.ul`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 1.5rem;
`;

export const VerticalSpacer = styled.div`
  height: 20px;
`;
