import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';

export type StorageSignature = {
  readonly expiry: number;
  readonly signature: string;
};

export type FirebaseUserDetails = {
  readonly uid: string;
  readonly email: string;
  readonly displayName?: string;
  readonly role?: string;
};

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  databaseURL: 'https://nl-urkel.firebaseio.com/',
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const functions = getFunctions(app, 'australia-southeast1');
const storageSignatureFunction = httpsCallable<unknown, StorageSignature>(
  functions,
  'storageSignature'
);

const listAllFirebaseUsersFunction = httpsCallable<unknown, FirebaseUserDetails[]>(
  functions,
  'listUsers'
);

const refreshClaimsFunction = httpsCallable<unknown, unknown>(functions, 'refreshClaims');

export { auth, storageSignatureFunction, listAllFirebaseUsersFunction, refreshClaimsFunction };
