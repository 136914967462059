import { FC, useState, useEffect, FormEvent, useContext } from 'react';

import { useQuery, useLazyQuery, useMutation, ApolloError } from '@apollo/client';
import {
  GET_CHATTER_LOCATIONS_BY_COLLECTION,
  RUN_SCRAPER,
  INSERT_CHATTER_COLLECTION,
  INSERT_CHATTER_LOCATION,
} from 'Queries';

// Providers
import { ThemeContext } from 'Providers/ThemeProvider';
import { useScraperContext } from 'Providers/ScraperProvider/ScraperProvider';

// Components
import Spacer from 'Components/Spacer';
import Error from 'Components/Error';
import { Errors } from 'Components/Error/Error.types';
import SubmitJob from 'Components/SubmitJob';
import ChatterCollectionForm from './ChatterCollectionForm';

// Types
import { ChatterCollection } from 'Pages/Order/Order.types';
import {
  ScrapeChatterLocationsProps,
  ChatterCollectionsData,
  NewChatterCollection,
  NewChatterLocation,
  ScraperList,
} from './ChatterScraper.types';

// Styling
import { Grid } from '@material-ui/core';
import AnimateHeight from 'react-animate-height';

// Helpers
import { parseMUIDate } from 'helpers';

const ChatterScraper: FC<ScrapeChatterLocationsProps> = ({
  order_id,
  orderAreas,
  wilson_order_id,
}) => {
  const [height, setHeight] = useState<number | string>(0);

  const [queryError, setQueryError] = useState<ApolloError>();
  const [mutationError, setMutationError] = useState<Errors>();
  const [taskId, setTaskId] = useState<string>('');

  const { chatterCollections, setChatterCollections, selectedLocations, setSelectedLocations } =
    useScraperContext();
  const colorContext = useContext(ThemeContext);

  const { loading } = useQuery<ChatterCollectionsData>(GET_CHATTER_LOCATIONS_BY_COLLECTION, {
    variables: { order_id: `${order_id}` },
    onCompleted: (res) => {
      setChatterCollections(res.chatter_collections);
    },
    onError: (error) => {
      setQueryError(error);
    },
  });

  const [refreshChatterCollections] = useLazyQuery<ChatterCollectionsData>(
    GET_CHATTER_LOCATIONS_BY_COLLECTION,
    {
      variables: { order_id: `${order_id}` },
      onCompleted: (res) => {
        setChatterCollections(res.chatter_collections);
      },
      onError: (error) => {
        setQueryError(error);
      },
    }
  );

  const [insertChatterCollection] = useMutation(INSERT_CHATTER_COLLECTION, {
    onCompleted: () => {
      refreshChatterCollections();
    },
    onError: (error) => {
      setMutationError({ message: `${error.message}` } as Errors);
    },
  });

  const [insertChatterLocation] = useMutation(INSERT_CHATTER_LOCATION, {
    onCompleted: () => {
      refreshChatterCollections();
    },
    onError: (error) => {
      setMutationError({ message: `${error.message}` } as Errors);
    },
  });

  const submitNewCollection = (newChatterCollection: NewChatterCollection) => {
    if (!newChatterCollection) {
    } else {
      insertChatterCollection({
        variables: {
          order_id: order_id,
          date_from: newChatterCollection.date_from,
          date_to: newChatterCollection.date_to,
          image_storage_location: `nl-urkel-storage/chatter/${wilson_order_id}/`,
        },
      });
    }
  };

  const submitNewLocation = (newChatterLocation: NewChatterLocation) => {
    if (!newChatterLocation) {
    } else {
      insertChatterLocation({
        variables: {
          chatter_collection_id: newChatterLocation.chatter_collection_id,
          source_location_identifier: newChatterLocation.source_location_identifier,
          timezone: newChatterLocation.timezone,
          source_link: newChatterLocation.source_link,
          description: newChatterLocation.description,
          order_area_ids: newChatterLocation.orderAreaIds.map((oa) => ({ order_area_id: oa })),
        },
      });
    }
  };

  const scrapeList: ScraperList[] = selectedLocations.map(
    ({
      chatter_location_id,
      source_location_identifier,
      timezone,
      collection_timeframe,
      source_link,
    }) => {
      return {
        Neighbourhood: source_location_identifier,
        TZ_Name: timezone,
        CAPTURE_FROM: parseMUIDate(new Date(collection_timeframe.date_from))!,
        CAPTURE_TO: parseMUIDate(new Date(collection_timeframe.date_to))!,
        LocationID: source_location_identifier,
        InstagramLink: source_link,
        ChatterLocationID: chatter_location_id,
      };
    }
  );

  const [scrapeChatterLocations] = useMutation(RUN_SCRAPER, {
    onCompleted: ({ runScraper: { taskId } }) => {
      if (taskId) setTaskId(taskId);
    },
    onError: (err) => {
      setMutationError({ message: `${err.message}` } as Errors);
    },
  });

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    return scrapeChatterLocations({
      variables: {
        order_id,
        wilson_order_id,
        scraperCsv: JSON.stringify(scrapeList),
      },
    });
  };

  useEffect(() => {
    const locations = chatterCollections.flatMap(
      ({ chatter_locations }: ChatterCollection) => chatter_locations
    );
    setSelectedLocations(locations);

    setHeight(() => 'auto');

    colorContext.color != 'primary' && colorContext.setColor('primary');
  }, [chatterCollections]);

  if (queryError) return <Error error={queryError} />;

  return (
    <AnimateHeight duration={500} height={height}>
      <Spacer>
        <Grid container alignContent="flex-start">
          <Grid item xs={12}>
            <ChatterCollectionForm
              orderAreas={orderAreas}
              disabled={loading}
              submitNewCollection={submitNewCollection}
              submitNewLocation={submitNewLocation}
            />
          </Grid>
        </Grid>
        <SubmitJob
          taskClickAction={onSubmit}
          taskId={taskId}
          taskName={'Chatter Scrape'}
          errors={mutationError ? mutationError : undefined}
          loading={loading}
          disabled={selectedLocations.length === 0}
        />
      </Spacer>
    </AnimateHeight>
  );
};

export default ChatterScraper;
