import styled from 'styled-components';
import Button from 'Components/Button';

import { Chip, Table, TableHead, TableCell, TextField } from '@material-ui/core';

import { CHIP_STATUS } from './TaggingJobList.types';
import { TaggingChipStatusColors } from 'Constants';

export const TableHeading = styled.h1`
  font-size: 20px;
  padding: 1rem;
`;

export const StyledTableCell = styled(TableCell)`
  text-transform: capitalize;
`;

export const TaggingStatusChip = styled(Chip)<{ status: CHIP_STATUS }>`
  min-width: 170px;
  margin: 0.3rem 0;
  background-color: ${(props) => TaggingChipStatusColors[`${props.status}` as CHIP_STATUS]};
  color: #fff;
  cursor: pointer;
`;

export const StyledTable = styled(Table)`
  margin-bottom: 1rem;
  border: 2px solid #ccc;
`;

export const CollectionHeader = styled(TableHead)`
  background-color: white;
  display: flex;
`;

export const LocationHeader = styled(TableHead)`
  background-color: #eee;
`;

export const StyledHeaderCell = styled(TableCell)`
  font-weight: bold;
`;

export const StyledLocationHeaderCell = styled(TableCell)`
  font-weight: bold;
  &:first-child {
    min-width: 15rem;
  }
  &:nth-child(2) {
    min-width: 15rem;
    padding-right: 2rem;
  }
  &:nth-child(3) {
    width: 85rem;
    max-width: 85rem;
  }
  &:nth-child(4) {
    min-width: 27rem;
  }
`;

export const StyledButton = styled(Button)`
  white-space: nowrap;
`;

export const StyledTextField = styled(TextField)`
  width: 5rem;
`;

export const LongDiv = styled.div`
  min-width: 15rem;
`;
