import styled from 'styled-components';
import { Colors } from 'Constants';

export const TableWrapper = styled.div`
  display: table;
  overflow: hidden;
  white-space: wrap;
  padding-top: 1rem;
`;

export const ErrorHeader = styled.ul`
  list-style-position: inside;
  padding-top: 1rem;
  padding-left: 1rem;
  white-space: wrap;
  color: ${Colors['neighbourlytics-pink']};
`;

export const ErrorMessage = styled.li``;

export const TableHeader = styled.div`
  display: table-header-group;
  > div {
    cursor: default;
    color: #777;
  }
`;

export const TableRow = styled.div`
  display: table-row;
  > div {
    &:first-child {
      min-width: 110px;
    }
    &:nth-child(2) {
      min-width: 110px;
    }
    &:nth-child(3) {
      min-width: 200px;
    }
    &:nth-child(5) {
      white-space: nowrap;
      font-size: 13px;
    }
  }
`;

export const TableCell = styled.div`
  display: table-cell;
  min-width: 120px;
  background-color: white;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 0.5rem;
`;

export const StyledWarning = styled.div`
  color: ${Colors['neighbourlytics-pink']};
`;

export const StyledSpan = styled.span`
  color: ${Colors['neighbourlytics-blue']};
  font-weight: bold;
`;

export const FlagItem = styled.div`
  display: flex;
  align-items: center;
  vertical-align: bottom;
`;
