import React, { FC } from 'react';
import ClearIcon from '@material-ui/icons/Clear';
import Modal from '@material-ui/core/Modal';
import { StyledModal, StyledCloseButton } from './Modal.styles';
import { useModal } from './Modal.hook';

interface ModalProps {
  title?: string;
  description?: string;
  reset: () => any;
  children: any[];
}

/**
 *
 * @param param0
 * This modal uses a context HoC to allow it to be controlled by several components in a tree. See Modal.hook for details
 */
const SimpleModal: FC<ModalProps> = ({ title = '', description = '', children }) => {
  const { modal, setCloseModal } = useModal();

  const body = (
    <StyledModal>
      <StyledCloseButton onClick={setCloseModal} variant="form" color="secondary">
        <ClearIcon />
      </StyledCloseButton>
      <h2 id="simple-modal-title">{title}</h2>
      <p id="simple-modal-description">{description}</p>
      {children}
    </StyledModal>
  );

  return (
    <div>
      <Modal
        open={modal}
        onClose={setCloseModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
};
export default SimpleModal;
