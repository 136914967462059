import { FC } from 'react';
// Types
import { OrderArea } from 'Pages/Order/Order.types';

interface RecategorisePlacesProps {
  order_id: string;
  orderAreas: OrderArea[];
}

const RecategorisePlaces: FC<RecategorisePlacesProps> = ({
  order_id,
  orderAreas,
}: RecategorisePlacesProps) => {
  return (
    <div>
      <h3>Coming Soon...</h3>
      <div style={{ display: 'none' }}>{order_id}</div>
      <div style={{ display: 'none' }}>{orderAreas.map((oa) => oa.order_area_id)}</div>
    </div>
  );
};

export default RecategorisePlaces;
