export const categoryIdsSorted = [
  { chatter_category_id: 32, sorted_id: 1 },
  { chatter_category_id: 31, sorted_id: 2 },
  { chatter_category_id: 33, sorted_id: 3 },
  { chatter_category_id: 57, sorted_id: 4 },
  { chatter_category_id: 34, sorted_id: 5 },
  { chatter_category_id: 27, sorted_id: 6 },
  { chatter_category_id: 28, sorted_id: 7 },
  { chatter_category_id: 29, sorted_id: 8 },
  { chatter_category_id: 30, sorted_id: 9 },
  { chatter_category_id: 24, sorted_id: 10 },
  { chatter_category_id: 25, sorted_id: 11 },
  { chatter_category_id: 26, sorted_id: 12 },
  { chatter_category_id: 15, sorted_id: 13 },
  { chatter_category_id: 13, sorted_id: 14 },
  { chatter_category_id: 40, sorted_id: 15 },
  { chatter_category_id: 14, sorted_id: 16 },
  { chatter_category_id: 41, sorted_id: 17 },
  { chatter_category_id: 42, sorted_id: 18 },
  { chatter_category_id: 44, sorted_id: 19 },
  { chatter_category_id: 46, sorted_id: 20 },
  { chatter_category_id: 45, sorted_id: 21 },
  { chatter_category_id: 43, sorted_id: 22 },
  { chatter_category_id: 47, sorted_id: 23 },
  { chatter_category_id: 58, sorted_id: 24 },
  { chatter_category_id: 59, sorted_id: 25 },
  { chatter_category_id: 3, sorted_id: 26 },
  { chatter_category_id: 2, sorted_id: 27 },
  { chatter_category_id: 4, sorted_id: 28 },
  { chatter_category_id: 5, sorted_id: 29 },
  { chatter_category_id: 60, sorted_id: 30 },
  { chatter_category_id: 48, sorted_id: 31 },
  { chatter_category_id: 49, sorted_id: 32 },
  { chatter_category_id: 50, sorted_id: 33 },
  { chatter_category_id: 38, sorted_id: 34 },
  { chatter_category_id: 36, sorted_id: 35 },
  { chatter_category_id: 35, sorted_id: 36 },
  { chatter_category_id: 39, sorted_id: 37 },
  { chatter_category_id: 37, sorted_id: 38 },
  { chatter_category_id: 23, sorted_id: 39 },
  { chatter_category_id: 21, sorted_id: 40 },
  { chatter_category_id: 22, sorted_id: 41 },
  { chatter_category_id: 16, sorted_id: 42 },
  { chatter_category_id: 9, sorted_id: 43 },
  { chatter_category_id: 18, sorted_id: 44 },
  { chatter_category_id: 19, sorted_id: 45 },
  { chatter_category_id: 10, sorted_id: 46 },
  { chatter_category_id: 8, sorted_id: 47 },
  { chatter_category_id: 1, sorted_id: 48 },
  { chatter_category_id: 17, sorted_id: 49 },
  { chatter_category_id: 11, sorted_id: 50 },
  { chatter_category_id: 54, sorted_id: 51 },
  { chatter_category_id: 7, sorted_id: 52 },
  { chatter_category_id: 12, sorted_id: 53 },
  { chatter_category_id: 52, sorted_id: 54 },
  { chatter_category_id: 51, sorted_id: 55 },
  { chatter_category_id: 53, sorted_id: 56 },
  { chatter_category_id: 20, sorted_id: 57 },
  { chatter_category_id: 56, sorted_id: 58 },
  { chatter_category_id: 6, sorted_id: 59 },
  { chatter_category_id: 55, sorted_id: 60 },
];

export const tagColors = [
  { color: 'orange', hex: '#FDB64E', ids: [27, 28, 29, 30] }, // chatter_category_ids [27, 28, 29, 30]
  { color: 'yellow', hex: '#F6ED60', ids: [32, 31, 33, 57, 34] }, // chatter_category_ids [32, 31, 33, 57, 34]
  { color: 'gross_yellow', hex: '#CAC437', ids: [11, 54, 7, 12] }, // chatter_category_ids [11, 54, 7, 12]
  { color: 'apple_green', hex: '#AED361', ids: [31, 32, 33] }, // chatter_category_ids [31, 32, 33]
  { color: 'green', hex: '#8EC975', ids: [24, 25, 26] }, // chatter_category_ids [24, 25, 26]
  { color: 'mint', hex: '#8ED0B5', ids: [44, 46, 45, 43, 47] }, // chatter_category_ids [44, 46, 45, 43, 47]
  { color: 'lighter_blue', hex: '#76D5E8', ids: [9, 18, 19, 10, 8, 1, 17] }, // chatter_category_ids [9, 18, 19, 10, 8, 1, 17]
  { color: 'blue', hex: '#70C3ED', ids: [3, 2, 4, 5] }, // chatter_category_ids [3, 2, 4, 5]
  { color: 'blue_violet', hex: '#6783C2', ids: [38, 36, 35, 39, 37] }, // chatter_category_ids [38, 36, 35, 39, 37]
  { color: 'violet', hex: '#756BB0', ids: [15, 13, 40, 14, 41, 42] }, // chatter_category_ids [15, 13, 40, 14, 41, 42]
  { color: 'mauve', hex: '#9A6DB0', ids: [52, 51, 53] }, // chatter_category_ids [52, 51, 53]
  { color: 'mauve_pink', hex: '#D671AD', ids: [58, 59] }, // chatter_category_ids [58, 59]
  { color: 'pink', hex: '#F15F90', ids: [20, 56, 6, 55] }, // chatter_category_ids [20, 56, 6, 55]
  { color: 'red_orange', hex: '#F15B40', ids: [23, 21, 22, 16] }, // chatter_category_ids [23, 21, 22, 16]
  { color: 'grey', hex: '#BFBFBF', ids: [60, 61] }, // chatter_category_ids [60, 61]
  { color: 'moss_green', hex: '#AED361', ids: [48, 49, 50] },
];
