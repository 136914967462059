import { FC } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { useAuth } from 'Providers';

import { Grid, Container } from '@material-ui/core';

import Order from 'Pages/Order';
import OrderList from 'Pages/OrderList';
import Tasks from 'Pages/Tasks';
import ImportOrder from 'Pages/ImportOrder';
import AllOrders from 'Pages/AllOrders';
import ExportKml from 'Pages/ExportKml';
import SingleTask from 'Pages/SingleTask';
import Tagging from 'Pages/Tagging';
import TaggingJobList from 'Pages/TaggingJobList';
import TaggingLocationList from 'Pages/TaggingLocationList';
import TaggingInterface from 'Pages/TaggingInterface';
import OrderImageLibrary from 'Pages/OrderImageLibrary';

import { Wrapper } from './Main.styles';

import { accessCheck, RolesType } from 'helpers';

const Main: FC = () => {
  const { getInfo } = useAuth();
  const { roles }: RolesType = getInfo();

  return (
    <Wrapper>
      <Container maxWidth={'xl'}>
        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
          <Grid item xs={12}>
            <Switch>
              <Route path="/tasks/:taskId">
                <SingleTask />
              </Route>
              <Route path="/tasks">
                <Tasks />
              </Route>
              <Route path="/order/:order_id">
                <Order />
              </Route>
              <Route path="/orders">
                <OrderList />
              </Route>
              <Route path="/import-order">
                <ImportOrder />
              </Route>
              <Route path="/all-orders">
                <AllOrders />
              </Route>
              <Route path="/export-kml">
                <ExportKml />
              </Route>
              <Route path="/tagging">
                <Tagging />
              </Route>
              <Route path="/tagging-job/:taggingJobId">
                <TaggingInterface />
              </Route>
              <Route path="/tagging-locations">
                <TaggingLocationList />
              </Route>
              <Route path="/order-image-library">
                <OrderImageLibrary />
              </Route>
              <Route path="/tagging-jobs">
                <TaggingJobList />
              </Route>
              <Route path="/">
                {accessCheck(['user'], roles) ? (
                  <Redirect to="/orders" />
                ) : (
                  <Redirect to="/tagging-jobs" />
                )}
              </Route>
            </Switch>
          </Grid>
        </Grid>
      </Container>
    </Wrapper>
  );
};

export default Main;
