import { FC, useState, useEffect, ChangeEvent, SetStateAction, useContext } from 'react';

import { BartThemeContext } from 'Providers/BartThemeProvider';

// Components, Functions
import { MenuItem } from '@material-ui/core';
import { getFirstItem } from './TaggingInterface';

// Styling
import { StyledSelect, TaggedImagesCounter } from './TaggingInterface.styles';

// Types
import { JobStatus, TaggingJobImage } from './TaggingInterface.types';

interface TaggingFilterProps {
  selectedFilter: string;
  setSelectedFilter: (value: SetStateAction<string>) => void;
  taggingJobImages: TaggingJobImage[];
  filteredImages: TaggingJobImage[];
  setFilteredImages: (value: SetStateAction<TaggingJobImage[]>) => void;
  setSelectedId: (value: SetStateAction<number>) => void;
  setSelectedTag: (value: SetStateAction<number | null>) => void;
  setReviewStatus: (value: SetStateAction<boolean>) => void;
}

const imagesCount = (
  selectedFilter: string,
  filteredImages: TaggingJobImage[],
  taggingJobImages: TaggingJobImage[]
) => {
  // Will return ie '2 / 21' - count of selected filter out of total job images
  let filteredCount = filteredImages.length;
  switch (selectedFilter) {
    case 'No Filter': {
      filteredCount = taggingJobImages.filter((img) => img.chatter_category_id !== null).length;
      break;
    }
    case 'Untagged': {
      filteredCount = taggingJobImages.filter((img) => img.chatter_category_id === null).length;
      break;
    }
    case 'For Review': {
      filteredCount = filteredImages.filter((img) => img.review_status !== null).length;
      break;
    }
  }
  return `${filteredCount} / ${taggingJobImages.length}`;
};

export const imageFiltersList = (
  reviewImages: TaggingJobImage[],
  untaggedImages: TaggingJobImage[],
  sortedTags: Set<string>
) => {
  // Will return a list of 'No Filter', conditionally 'For Review' and 'Untagged', and all tagged categories
  const filterList = ['No Filter'];
  if (reviewImages.length > 0) {
    filterList.push('For Review');
  }
  if (untaggedImages.length > 0) {
    filterList.push('Untagged');
  }
  return filterList.concat(Array.from(sortedTags));
};

const TaggingInterfaceFilter: FC<TaggingFilterProps> = ({
  selectedFilter,
  setSelectedFilter,
  taggingJobImages,
  filteredImages,
  setFilteredImages,
  setSelectedId,
  setSelectedTag,
  setReviewStatus,
}) => {
  const [filterList, setFilterList] = useState<string[]>(['No Filter']);

  const themeBgStyle = useContext(BartThemeContext).backgroundStyle;

  const taggingJobTags: string[] = taggingJobImages
    .filter((img) => img.chatter_category !== null)
    .flatMap((img) => img.chatter_category!.chatter_label);
  const reviewImages: TaggingJobImage[] = taggingJobImages.filter(
    (img) => img.review_status !== null
  );
  const untaggedImages: TaggingJobImage[] = taggingJobImages.filter(
    (img) => img.chatter_category === null
  );

  const sortedTags = new Set(taggingJobTags.sort((a, z) => a.localeCompare(z)));

  const handleImageChange = (newItem: TaggingJobImage) => {
    if (!newItem) {
      // To handle if a user has filtered out a category with only 1 image
      // And if they pursue to change the tag for that image
      setSelectedFilter('No Filter');
      return getFirstItem(taggingJobImages);
    }
    setSelectedId(newItem.tagging_job_image_id);
    setSelectedTag(newItem.chatter_category_id);
    setReviewStatus(newItem.review_status === 'for_review');
  };

  useEffect(() => {
    setFilterList(imageFiltersList(reviewImages, untaggedImages, sortedTags));

    switch (selectedFilter) {
      case 'No Filter': {
        setFilteredImages(taggingJobImages);
        break;
      }
      case 'Untagged': {
        setFilteredImages(untaggedImages);
        handleImageChange(untaggedImages[0]);
        break;
      }
      case 'For Review': {
        setFilteredImages(
          reviewImages.filter(
            (img) => img.review_status! === 'for_review' || img.review_status! === 'reviewed'
          )
        );
        handleImageChange(reviewImages[0]);
        break;
      }
      default:
        const taggedFilterImages = taggingJobImages.filter(
          (img) =>
            img.chatter_category !== null && img.chatter_category!.chatter_label === selectedFilter
        );
        setFilteredImages(taggedFilterImages);
        handleImageChange(taggedFilterImages[0]);
    }
  }, [selectedFilter, taggingJobImages]);

  return (
    <>
      <StyledSelect
        value={selectedFilter}
        variant="standard"
        fontcolor={themeBgStyle}
        onChange={(e: ChangeEvent<{ value: unknown }>) => {
          // You must cast a provided value from MUI this way.
          const val = e.target.value as JobStatus;
          setSelectedFilter(val);
        }}
      >
        {filterList.map((item) => {
          return (
            <MenuItem value={item} key={item}>
              &nbsp;{item}
            </MenuItem>
          );
        })}
      </StyledSelect>
      <TaggedImagesCounter color={themeBgStyle}>
        {imagesCount(selectedFilter, filteredImages, taggingJobImages)}
      </TaggedImagesCounter>
    </>
  );
};

export default TaggingInterfaceFilter;
