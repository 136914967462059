import { FC, SyntheticEvent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import { Grid, FormLabel, TextField, Select, MenuItem, Box } from '@material-ui/core';
import Button from 'Components/Button';

import { CREATE_TAGGING_JOB } from 'Queries';

import { useUsers } from 'Providers/UserProvider';

import { TaggingAddNewJobProps } from './TaggingLocationList.types';

import {
  TableHeading,
  FormContainer,
  FormColumn,
  StyledFormControl,
  StyledStaticFormContent,
} from './TaggingLocationList.styles';

const TaggingAddNewJob: FC<TaggingAddNewJobProps> = ({ data: job, onComplete }) => {
  const history = useHistory();
  const { users, loggedInUser } = useUsers();

  //Form Variables
  const [selectedUser, setSelectedUser] = useState<number>(loggedInUser?.firebase_user_id || -1);
  const [notes, setNotes] = useState<string>('');
  const [modulus, setModulus] = useState<number>(1);

  const [insertTaggingJob] = useMutation(CREATE_TAGGING_JOB, {
    variables: {
      chatter_location_id: job.chatter_location_id,
      created_by_user: loggedInUser?.firebase_user_id,
      tagger_user_id: selectedUser,
      job_status: 'pending',
      notes: notes,
      modulus: modulus,
    },
    onCompleted: () => {
      history.push('/tagging-jobs');
    },
    onError: () => {
      onComplete(); //Todo: Proper error handling
    },
  });

  const onSaveClick = (e: SyntheticEvent) => {
    e.preventDefault();
    if (modulus > 0) insertTaggingJob();
  };

  const onCancelClick = () => {
    onComplete();
  };

  return (
    <>
      <TableHeading>Add New Tagging Job</TableHeading>
      <FormContainer>
        <form onSubmit={onSaveClick}>
          <Grid container spacing={2}>
            <Grid item xs={2} md={3}>
              <FormColumn>
                <StyledFormControl fullWidth>
                  <FormLabel>Order ID/Name</FormLabel>
                  <StyledStaticFormContent>
                    {job.chatter_collection.order.order_id}: {job.chatter_collection.order.name}
                  </StyledStaticFormContent>
                </StyledFormControl>
                <StyledFormControl fullWidth>
                  <FormLabel>Location ID</FormLabel>
                  <StyledStaticFormContent>{job.chatter_location_id}</StyledStaticFormContent>
                </StyledFormControl>
              </FormColumn>
            </Grid>
            <Grid item xs={3} md={4}>
              <FormColumn>
                <StyledFormControl fullWidth>
                  <FormLabel>Select Tagger</FormLabel>
                  <Select
                    required
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                    value={selectedUser}
                    onChange={(e) => {
                      const val = e.target.value as string;
                      setSelectedUser(parseInt(val));
                    }}
                  >
                    {users.map((user, index) => (
                      <MenuItem key={index} value={user.firebase_user_id}>
                        {user.display_name}
                      </MenuItem>
                    ))}
                  </Select>
                </StyledFormControl>
                <StyledFormControl fullWidth>
                  <FormLabel>Notes</FormLabel>
                  <TextField
                    value={notes}
                    onChange={(e) => {
                      const val = e.target.value as string;
                      setNotes(val);
                    }}
                  />
                </StyledFormControl>
              </FormColumn>
            </Grid>
            <Grid item xs={3} md={4}>
              <FormColumn>
                <StyledFormControl fullWidth>
                  <FormLabel>Number of Images @ Location</FormLabel>
                  <StyledStaticFormContent>
                    {job.chatter_images_aggregate.aggregate.count}
                  </StyledStaticFormContent>
                </StyledFormControl>
                <StyledFormControl fullWidth>
                  <FormLabel>Modulus (step)</FormLabel>
                  <TextField
                    type="number"
                    required
                    value={modulus}
                    onChange={(e) => {
                      const val = e.target.value ? (e.target.value as string) : '1';
                      setModulus(parseInt(val));
                    }}
                  />
                </StyledFormControl>
                <StyledFormControl fullWidth>
                  <FormLabel>Total Number of Images to Be Scraped</FormLabel>
                  <StyledStaticFormContent>
                    {modulus >= 1
                      ? Math.ceil(job.chatter_images_aggregate.aggregate.count / modulus)
                      : 'Please enter a positive modulus value'}
                  </StyledStaticFormContent>
                </StyledFormControl>
              </FormColumn>
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <Button variant="form" color="secondary" onClick={onCancelClick}>
                  Cancel
                </Button>
                <Button type="submit" variant="form" color="primary" disabled={modulus <= 0}>
                  Add New Job
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </FormContainer>
    </>
  );
};

export default TaggingAddNewJob;
