import styled from 'styled-components';
import { Colors } from 'Constants';

const getColor = (props: any) => {
  if (props.isDragAccept) {
    return `${Colors['neighourlytics-green']}`;
  }
  if (props.isDragReject) {
    return 'grey';
  }
  if (props.isDragActive) {
    return `${Colors['neighourlytics-green']}`;
  }
  return '#eeeeee';
};

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${(props: any) =>
    props.id === 'upload-csv' &&
    `
    padding: 20px;
    border-width: 2px;
    border-color: ${(props: any) => getColor(props)};
    border-style: dashed;
    cursor: pointer;
    background-color: ${Colors['neighbourlytics-secondary-blue']};
    &:hover {
      background-color: ${Colors['neighourlytics-green']};
    }
    color: #efefef;
    outline: none;
    margin-bottom: 1.5rem;
    transition: border 0.24s ease-in-out;
    transition: background 0.24s ease-in-out;
  `}
`;
