import { Reducer } from 'react';
import { ModalReducerState, Action } from './Tasks.types';

export const initialState: ModalReducerState = {
  checked: { confirm: false, force: false },
  deleteResult: null,
  error: null,
};

export const modalReducer: Reducer<ModalReducerState, Action> = (state, action) => {
  const { type } = action;
  switch (type) {
    case 'ERRORS': {
      return { ...state, error: action.error };
    }
    case 'RESULT': {
      if (action && action.removeTaskResults) {
        return {
          ...state,
          deleteResult: {
            result: action.removeTaskResults.result,
            id: action.removeTaskResults.id,
          },
        };
      }
      return state;
    }
    case 'CHECKED': {
      return {
        ...state,
        checked: {
          confirm:
            action.confirm === false || action.confirm === true
              ? action.confirm
              : state.checked.confirm,
          force:
            action.force === false || action.force === true ? action.force : state.checked.force,
        },
      };
    }
    case 'RESET': {
      return { ...initialState };
    }
    default: {
      throw new Error(`Unsupported action type: ${action.type}`);
    }
  }
};
