import { FC, Fragment, SyntheticEvent } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { useAuth } from 'Providers';

import { Divider, Collapse } from '@material-ui/core';
import ChevronRightOutlinedIcon from '@material-ui/icons/ChevronRightOutlined';
import ExpandMore from '@material-ui/icons/ExpandMore';

import { StyledSubListItem, StyledListItemText } from './Menu.styles';

import { accessCheck, RolesType } from 'helpers';

import { MenuData } from './menu.data';

interface SubMenuProps {
  childMenu: MenuData[];
  activeMenus: string[];
  handleClick: (event: SyntheticEvent) => void;
  parentName: string;
}

const SubMenu: FC<SubMenuProps> = ({ childMenu, activeMenus, handleClick, parentName }) => {
  const { getInfo } = useAuth();
  const { roles }: RolesType = getInfo();
  const location = useLocation();

  return (
    <Collapse in={activeMenus.includes(`parent-${parentName}`)} timeout="auto" unmountOnExit>
      {childMenu
        .filter(({ access }: MenuData) => accessCheck(access, roles))
        .map(({ name, to, submenu, id }: MenuData) => (
          <Fragment key={id}>
            <StyledSubListItem
              button
              name={`${name}`}
              component={Link}
              to={`${to}`}
              onClick={(event: SyntheticEvent) => handleClick(event)}
              selected={location.pathname === to}
            >
              <StyledListItemText secondary={`${name}`} />
              {submenu && activeMenus.includes(`${name}`) ? (
                <ExpandMore />
              ) : (
                <ChevronRightOutlinedIcon />
              )}
            </StyledSubListItem>
            {submenu && (
              <SubMenu
                childMenu={submenu}
                activeMenus={activeMenus}
                handleClick={handleClick}
                parentName={name}
              />
            )}
            <Divider />
          </Fragment>
        ))}
    </Collapse>
  );
};

export default SubMenu;
