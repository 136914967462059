import styled from 'styled-components';
import Button from 'Components/Button';

export const StyledFormButton = styled(Button)`
  margin: 2rem 0 2rem 0;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  &:disabled {
    background-color: lightgrey;
  }
`;

export const StyledRexportContainer = styled.div`
  margin: 0 2rem;
`;
