import { FC } from 'react';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Error from 'Components/Error';
import { Errors } from 'Components/Error/Error.types';
import TableHeader from './TableHeader';

const TempTable: FC<Errors> = ({ error }) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHeader />
        {error && <Error error={error} />}
      </Table>
    </TableContainer>
  );
};

export default TempTable;
