import styled from 'styled-components';
import { COLORS } from 'Constants';

export const StyledTableWrapper = styled.div`
  display: table;
  padding: 1rem;
  overflow: hidden;
  white-space: wrap;
`;

export const CollectionHeader = styled.div`
  display: table-header;
  cursor: default;
`;

export const StyledTableHeader = styled.div`
  display: table-row;
  cursor: default;
  color: #777;
  cursor: default;
`;

export const ActiveRow = styled.div`
  display: table-row;
  cursor: pointer;
  border-radius: 20px;
`;

export const CollectionRow = styled(ActiveRow)<{ disabled: boolean }>`
  &:hover {
    outline: 2px solid ${(props) => (props.disabled ? '#ececec' : COLORS.neighbourlyticsPink)};
    cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  }
`;

export const StyledTableRow = styled(ActiveRow)`
  > div {
    &:first-child {
      min-width: 50px;
    }
    &:nth-child(2) {
      min-width: 50px;
    }
    &:nth-child(3) {
      min-width: 20vw;
    }
    &:nth-child(4) {
      min-width: 12vw;
    }
    &:nth-child(5) {
      min-width: 180px;
    }
  }

  &:hover {
    outline: 2px solid ${COLORS.neighbourlyticsBlue};
  }
`;

export const StyledTableCell = styled.div<{ minWidth?: number }>`
  display: table-cell;
  padding-left: 1rem;
  padding-right: 1rem;
  margin: 0.2rem;
  ${(props) => props.minWidth && `min-width: ${props.minWidth}vw`}
`;

export const OrderAreaDropdown = styled.div`
  max-height: 30vh;
  min-width: fit-content;
  overflow-y: scroll;
`;

export const NewItemRow = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const CreateNewRow = styled(ActiveRow)<{ color?: 'primary' | 'secondary' }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: default;
  padding: 1rem;

  outline: 2px solid
    ${(props) =>
      props.color === 'primary'
        ? COLORS.neighbourlyticsBlue
        : props.color === 'secondary'
        ? COLORS.neighbourlyticsPink
        : '#ececec'};
`;

export const OrderAreaPill = styled.div`
  display: inline-block;
  align-items: center;
  justify-content: center;
  max-width: 80px;

  border-radius: 20px;
  outline: 2px solid #ececec;

  margin: 1rem;
  padding: 0.3rem 1rem 0.3rem 1rem;
`;
