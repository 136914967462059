import { FC } from 'react';
import { ApolloClient, ApolloProvider, createHttpLink, ApolloLink, from } from '@apollo/client';
import { setContext, ContextSetter } from '@apollo/client/link/context';
import { getCookie } from 'helpers';
import { useAuth } from 'Providers';
import { cache } from './Cache';

interface ApolloContext extends ContextSetter {
  headers: Headers;
}

export let client: any;

const Apollo: FC = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const link = createHttpLink({
    uri: process.env.REACT_APP_HASURA_URL,
  });

  const authLink: ApolloLink = setContext(
    async (_: any, { headers, ...context }: ApolloContext) => {
      await isAuthenticated(15);
      const cookie = getCookie('access_token');

      return {
        headers: {
          ...headers,
          ...(cookie ? { authorization: `Bearer ${cookie}` } : {}),
        },
        ...context,
      };
    }
  );

  client = new ApolloClient({
    link: from([authLink, link]),
    cache,
    assumeImmutableResults: true,
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default Apollo;
