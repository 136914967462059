import { useState, useEffect, createContext, useContext } from 'react';
import { useCookies } from 'react-cookie';
import {
  auth,
  storageSignatureFunction,
  StorageSignature,
  refreshClaimsFunction,
} from 'libs/Firebase/firebaseIndex';
import { signInWithEmailAndPassword, signOut } from 'firebase/auth';
import jwt_decode from 'jwt-decode';
import { client } from 'libs/Apollo/ApolloProvider';
import { Role } from 'libs/Apollo/Cache.types';

interface UserInfo {
  'https://hasura.io/jwt/claims': Role;
  email: string;
  user_id: string;
}

type UserInfoType = {
  roles: Role;
  email: string;
  userId: string;
};

const deleteSettings = {
  path: '/',
  domain: process.env.NODE_ENV === 'production' ? '.neighbourlytics.com' : '',
};

const defaultCookieSettings = (exp: any): any => {
  const cookieExpire = new Date(0);
  return {
    path: '/',
    domain: process.env.NODE_ENV === 'production' ? '.neighbourlytics.com' : '',
    sameSite: process.env.NODE_ENV === 'production' ? true : false,
    secure: process.env.NODE_ENV === 'production' ? true : false,
    expires: cookieExpire.setUTCDate(exp),
  };
};

type AuthContextType = {
  getInfo: () => UserInfoType;
  signin: (email: string, password: string) => Promise<void>;
  signout: () => Promise<any>;
  error: any;
  loading: boolean;
  isAuthenticated: (offsetInMinutes?: number) => Promise<boolean>;
  storageSignature: StorageSignature | undefined;
  signSignature: (url: string) => string;
};

const AuthContext = createContext<AuthContextType>({
  isAuthenticated: async () => false,
  signin: async (_email, _password) => {
    return;
  },
} as AuthContextType);
export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [cookies, setCookie, removeCookie] = useCookies(['access_token']);
  const [storageSignature, setStorageSignature] = useState<StorageSignature>();
  const authCookie = cookies.access_token;
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const refreshAuthenticated = async () => {
      const isAuth = await isAuthenticated();
      if (isAuth) {
        await getStorageSignature();
      }
    };
    refreshAuthenticated();
  }, []);

  const authenticateCurrentUser = async () => {
    const user = auth.currentUser;
    if (user != null) {
      await refreshClaimsFunction();

      const idTokenResult = await user.getIdTokenResult(true);
      console.log(`New Id Token with expiry at ${idTokenResult.expirationTime}`, idTokenResult);
      setCookie(
        'access_token',
        idTokenResult.token,
        defaultCookieSettings(idTokenResult.expirationTime)
      );

      await getStorageSignature();
      return true;
    }

    return false;
  };

  const checkTokenExpiry = (offsetInMinutes?: number) => {
    const offset = offsetInMinutes ?? 0;
    const authCookie = cookies.access_token;
    if (!authCookie) return false;
    const { exp }: any = jwt_decode(authCookie);
    const result = Date.now() + offset * 60 * 1000 < exp * 1000;
    console.log(`Is authenticated (offset: ${offset}) for expiry at ${exp}: ${result}`);
    return result;
  };

  const getLocalStorageItem = <T,>(key: string): T | undefined => {
    const item = localStorage.getItem(key);
    if (item === null || item === 'null' || item === 'undefined') return undefined;

    try {
      return JSON.parse(item);
    } catch {}
  };

  const getStorageSignature = async () => {
    const key = 'storage_signature';
    const existingSignature = getLocalStorageItem<StorageSignature>(key);
    if (existingSignature && new Date(existingSignature.expiry) > new Date()) {
      setStorageSignature(existingSignature);
      return;
    }
    const result = await storageSignatureFunction();
    localStorage.setItem(key, JSON.stringify(result.data));
    setStorageSignature(result.data);
  };

  const isAuthenticated = async (offsetInMinutes?: number) => {
    if (checkTokenExpiry(offsetInMinutes)) return true;

    console.log(`User is not authenticated with ${offsetInMinutes} offset, re-authenticating`);
    return await authenticateCurrentUser();
  };

  const getInfo = (): UserInfoType => {
    const info: UserInfo = authCookie && jwt_decode(authCookie);

    const roles = info
      ? info['https://hasura.io/jwt/claims']
      : {
          'x-hasura-allowed-roles': ['none'],
          'x-hasura-default-role': 'none',
          'x-hasura-user-id': 'none',
        };

    return { roles, email: info?.email || 'none', userId: info?.user_id || 'none' };
  };

  const signin = async (email: string, password: string) => {
    try {
      setLoading(true);
      setError({});
      await signInWithEmailAndPassword(auth, email, password);
      await authenticateCurrentUser();
    } catch (err) {
      setError({ err });
    } finally {
      setLoading(false);
    }
  };

  const signout = async () => {
    try {
      await auth.signOut();
    } catch (err) {
      setError({ err });
    } finally {
      removeCookie('access_token', deleteSettings);
      client.clearStore();
    }
  };

  const signSignature = (url: string) => {
    return `${url}?${storageSignature?.signature}`;
  };

  return (
    <AuthContext.Provider
      value={{
        getInfo,
        isAuthenticated,
        signin,
        signout,
        error,
        loading,
        storageSignature,
        signSignature,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
