import { FC, useState, useEffect, FormEvent } from 'react';

// Styling
import { StyledForm } from './RunPlacesQuery.styles';
import {
  MenuItem,
  Select,
  FormControl,
  FormControlLabel,
  FormLabel,
  Checkbox,
  Paper,
  Grid,
  Typography,
} from '@material-ui/core';
import AnimateHeight from 'react-animate-height';

import { useLazyQuery } from '@apollo/client';
import { RUN_PLACES_QUERY, QueryGranularity } from 'Queries';

import Button from 'Components/Button';
import SubmitJob from 'Components/SubmitJob';
import { Errors } from 'Components/Error/Error.types';

import {
  apiStringValues,
  ApiStringType,
  granularityValues,
  GranularityType,
} from 'Pages/Order/Order.types';

interface Location {
  location_id: number;
  name: string;
}

interface RunPlacesQuery {
  order_id: number;
  locations: Location[];
}

interface RunPlacesQueryReturn {
  runPlaceQuery: {
    taskId?: string;
    error?: { message: string };
  };
}

const maxHeight = {
  maxHeight: '40px',
};

const RunPlacesQuery: FC<RunPlacesQuery> = ({ order_id, locations }) => {
  const [taskId, setTaskId] = useState<string | undefined>(undefined);
  const [errors, setErrors] = useState<Errors | undefined>(undefined);
  const [height, setHeight] = useState<number | string>(0);

  const locationIds = locations.map(({ location_id }: any) => location_id);
  // Form States
  const [selectedLocations, setSelectedLocations] = useState<any>(locationIds);
  const [selectedAPI, setSelectedAPI] = useState<ApiStringType | undefined>(undefined);
  const [selectedGranularity, setSelectedGranularity] = useState<GranularityType | undefined>(
    undefined
  );

  const [runPlaceQuery] = useLazyQuery<RunPlacesQueryReturn>(RUN_PLACES_QUERY, {
    onCompleted: ({ runPlaceQuery: { taskId, error } }: RunPlacesQueryReturn) => {
      if (taskId) setTaskId(taskId);
      if (error) setErrors(error as any);
    },
  });

  useEffect(() => {
    setHeight(() => 'auto');
    setSelectedLocations(locationIds);
  }, [locations]);

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!selectedAPI || !selectedGranularity) {
      return setErrors({
        message: `missing options: ${selectedAPI ? 'granularity' : 'API'}`,
      } as any);
    } else if (selectedLocations.length <= 0) {
      return setErrors({
        message: `Please select at least one location`,
      } as any);
    }
    return runPlaceQuery({
      variables: {
        order_id,
        apiStrings: selectedAPI,
        locationIds: selectedLocations,
        granularity: selectedGranularity,
      },
    });
  };

  const onCheckboxChange = (location_id: any) => {
    const newLocations = [...selectedLocations];
    const locationIndex = newLocations.indexOf(location_id);

    if (locationIndex === -1) newLocations.push(location_id);
    else newLocations.splice(locationIndex, 1);

    setSelectedLocations(newLocations);
  };

  if (taskId) {
    return (
      <>
        <Typography variant="body2" align="left">
          Success
        </Typography>
        <Button to={`/tasks/${taskId}`} variant="form" color="primary">
          Visit task
        </Button>
      </>
    );
  }

  return (
    <AnimateHeight duration={500} height={height}>
      <StyledForm>
        <Paper style={{ padding: 16 }}>
          <Grid container item xs={12} alignItems="flex-start" spacing={2}>
            <Grid item xs={4}>
              <Grid item xs={12} style={{ marginBottom: '20px' }}>
                <FormControl>
                  <FormLabel>Select API</FormLabel>
                  <Select
                    name="apiStrings"
                    id="apiStrings"
                    label="Select API"
                    required
                    onChange={(e) => {
                      const val = e.target.value as ApiStringType;
                      setSelectedAPI(val);
                    }}
                  >
                    {apiStringValues.map((val) => (
                      <MenuItem key={val} value={val}>
                        {val}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl>
                  <FormLabel>Select Granularity</FormLabel>
                  <Select
                    name="granularity"
                    id="granularity"
                    label="Select Granularity"
                    required
                    onChange={(e) => {
                      const val = e.target.value as GranularityType;
                      setSelectedGranularity(val);
                    }}
                  >
                    {granularityValues.map((val) => (
                      <MenuItem key={val} value={QueryGranularity[val as GranularityType]}>
                        {val}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={8}>
              <FormControl component="fieldset">
                <FormLabel>Select locations</FormLabel>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="locations"
                        color="primary"
                        value={'all'}
                        checked={selectedLocations.length === locationIds.length}
                        onChange={() => {
                          setSelectedLocations([...locationIds]);
                        }}
                      />
                    }
                    label="Select All"
                    style={maxHeight}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="locations"
                        color="primary"
                        value={'none'}
                        checked={selectedLocations.length <= 0}
                        onChange={() => {
                          setSelectedLocations([]);
                        }}
                      />
                    }
                    label="Select None"
                    style={maxHeight}
                  />
                </Grid>
                {locations.map(({ location_id, name }: any) => (
                  <FormControlLabel
                    key={location_id}
                    control={
                      <Checkbox
                        name="locations"
                        color="primary"
                        value={location_id}
                        onChange={() => {
                          onCheckboxChange(location_id);
                        }}
                        checked={selectedLocations.indexOf(location_id) > -1}
                      />
                    }
                    label={name}
                    style={maxHeight}
                  />
                ))}
              </FormControl>
            </Grid>
            <SubmitJob
              taskClickAction={onSubmit}
              taskId={taskId}
              taskName={'Places Query'}
              errors={errors}
              loading={false}
              disabled={
                selectedLocations.length === 0 ||
                selectedAPI === undefined ||
                selectedGranularity === undefined
              }
            />
          </Grid>
        </Paper>
      </StyledForm>
    </AnimateHeight>
  );
};

export default RunPlacesQuery;
