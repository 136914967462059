import { FC } from 'react';

import { TableCell } from '@material-ui/core';

import { TaggingRowOrderDetailsProps } from '../TaggingJobList.types';

const TaggingRowOrderDetails: FC<TaggingRowOrderDetailsProps> = ({
  urkelOrderId,
  orderNo,
  orderName,
  diplayOrderDetails,
}) => {
  if (!diplayOrderDetails) return <></>;
  return (
    <>
      <TableCell>
        <a href={`/order/${urkelOrderId}`}>{urkelOrderId}</a>
        <br />
        {orderNo}
      </TableCell>
      <TableCell variant="footer" padding="none">
        {orderName}
      </TableCell>
    </>
  );
};

export default TaggingRowOrderDetails;
