import { FC, useState, useEffect } from 'react';

import TaggingJobList from 'Pages/TaggingJobList';

import { useAuth } from 'Providers';
import { listAllFirebaseUsersFunction, FirebaseUserDetails } from 'libs/Firebase/firebaseIndex';

const Tagging: FC = () => {
  const { storageSignature } = useAuth();
  const [users, setUsers] = useState<FirebaseUserDetails[]>([]);

  const extendUrlWithSignature = (url: string) => `${url}?${storageSignature?.signature}`;

  useEffect(() => {
    const getUsers = async () => {
      const users = await listAllFirebaseUsersFunction();
      setUsers(users.data);
    };

    getUsers();
  }, []);

  const fullUrl = extendUrlWithSignature(
    'https://urkel.neighbourlytics.com/instagram-scraper/00355bea-b2c6-455a-ab89-ceb25a3d9fba/257663_20210402%2018h04m54s_CNJ5AfQrtwV.jpg'
  );
  return (
    <div>
      <img src={fullUrl} alt="image" />
      <ul>
        {users.map((u) => (
          <li key={u.uid}>
            {u.email} ({u.uid}) <b>{u.role}</b>{' '}
          </li>
        ))}
      </ul>
      <TaggingJobList order_id={undefined} />
    </div>
  );
};

export default Tagging;
