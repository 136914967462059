import React, { FC, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

import { LocationSelection, LatLonProps } from 'Pages/ExportKml/ExportKml.types';
import { Container } from './LatLonSelector.styles';
import Papa, { ParseResult } from 'papaparse';

export const REQUIRED_COLUMNS = ['locationName', 'latitude', 'longitude'];

const LatLonCsvImport: FC<LatLonProps> = ({
  locationsList,
  setLocationsList,
  setError,
  disabled,
}) => {
  const appendCsvLocations = (csv: LocationSelection[]) => {
    const results = REQUIRED_COLUMNS.map((columnName) => {
      const correctColumnCheck = csv.filter((o) => o.hasOwnProperty(columnName)).length > 0;
      return correctColumnCheck ? correctColumnCheck : columnName;
    });
    if (results.every((result) => result === true)) {
      setLocationsList(csv.concat(locationsList));
    } else {
      const missingResults = results.filter((result) => result !== true);
      setError(`${missingResults.join(', ')} were not present in csv`);
    }
  };

  const onDrop = useCallback(
    async (acceptedFiles) => {
      await Papa.parse(acceptedFiles[0], {
        header: true,
        dynamicTyping: false,
        complete: (results: ParseResult<any>) => {
          appendCsvLocations(results.data);
        },
      });
    },
    [appendCsvLocations]
  );

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    accept: '.csv',
  });

  return (
    <>
      <Container id="upload-csv" {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
        <input {...getInputProps()} />
        {!disabled && <p>Drag 'n' drop your csv, or click to select files</p>}
      </Container>
    </>
  );
};

export default LatLonCsvImport;
