import styled from 'styled-components';

export const TableWrapper = styled.div`
  display: table;
  padding: 0;
  overflow: hidden;
  white-space: wrap;
`;

export const ImageTableWrapper = styled.div`
  display: flex;
  height: 65rem;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
`;

export const TableHeader = styled.div`
  display: table-row;
  cursor: default;
  color: #777;
`;

export const TableRow = styled.div`
  display: table-row;
  > div {
    &:first-child {
      width: 130px;
    }
    &:nth-child(2) {
      width: 180px;
    }
    &:nth-child(3) {
      width: 240px;
    }
  }
`;

export const TableCell = styled.div`
  display: table-cell;

  padding-left: 1rem;
`;

export const ImageListThumbnail = styled.section`
  padding: 0.2rem;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: row;

  & img {
    max-width: 100%;
  }
`;

export const ImageOverlay = styled.div`
  position: absolute;
  padding: 2px;
  top: 0;
  display: flex;
  flex-direction: column;
  opacity: 50%;
`;

export const ImageOverlayText = styled.div`
  margin: 1px;
  border-radius: 3px;
  padding: 1px 6px 1px 6px;
  background-color: white;
`;
