import { ComponentType, createContext, useState, useContext } from 'react';

export interface ModalContextType {
  modal: boolean;
  setOpenModal: () => void;
  setCloseModal: () => void;
}

export const ModalContext = createContext<ModalContextType>({
  modal: false,
  setOpenModal: () => null,
  setCloseModal: () => null,
});

export const useModal = () => {
  const { modal, setOpenModal, setCloseModal } = useContext(ModalContext);
  return { modal, setOpenModal, setCloseModal };
};

export const withModalContext =
  <P extends any>(Component: ComponentType<P>) =>
  (props: P & ModalContextType) => {
    const [modal, setModal] = useState<boolean>(false);
    const setCloseModal = () => setModal(false);
    const setOpenModal = () => setModal(true);

    return (
      <ModalContext.Provider value={{ modal, setOpenModal, setCloseModal }}>
        <Component {...props} />
      </ModalContext.Provider>
    );
  };
