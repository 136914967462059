import { FC, FormEvent, useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CachedIcon from '@material-ui/icons/Cached';
import DeleteIcon from '@material-ui/icons/Delete';

import TaskDetails from 'Components/TaskDetails';
import Error from 'Components/Error';
import ConfirmModal from './ConfirmModal';

import { useAuth } from 'Providers';
import { useModal, withModalContext } from 'Components/Modal/Modal.hook';

import { ALL_CELERY_JOB_RESULTS, CELERY_JOB_RESULTS_BY_ORDER } from 'Queries';

import { convertToLocalDate } from 'helpers';

import {
  TaskListContainer,
  TaskListTitle,
  TaskListRefreshButton,
  TaskListLoading,
  TaskListProgress,
  TaskListPagination,
  TaskListItem,
  TaskListItemHeader,
  PaginationButton,
  DeleteButton,
} from './TasksList.styles';

import { Job, CeleryJob } from './Tasks.types';
import AdHocExecuteTask from 'Pages/Tasks/AdHocExecuteTask';

// The Modal needs to be converted from a HOC to a proper hook to allow us to type this properly :(
interface TaskListProps {
  orderId?: string;
}

const TasksList: FC<any> = ({ orderId = undefined }) => {
  const { getInfo } = useAuth();
  const { userId, email } = getInfo();

  const pageSize = 100;
  const [offset, setOffset] = useState<number>(0);

  const [confirm, setConfirm] = useState({ taskId: '' });
  const { setOpenModal } = useModal();

  const [taskList, setTaskList] = useState<CeleryJob[]>([]);

  const [getTasks, { loading, error }] = useLazyQuery(
    orderId !== undefined ? CELERY_JOB_RESULTS_BY_ORDER : ALL_CELERY_JOB_RESULTS,
    {
      variables: { order_id: orderId, offset: offset, pageSize: pageSize },
      fetchPolicy: 'network-only',
      onCompleted: ({ celery_job }: Job) => {
        return setTaskList(() =>
          celery_job.slice().sort((a: any, b: any) => {
            return b.celery_job_id - a.celery_job_id;
          })
        );
      },
    }
  );

  useEffect(() => {
    getTasks();
  }, [offset]);

  const handleModal = (taskId: string) => {
    setOpenModal();
    setConfirm({ taskId: taskId });
  };

  const handleRefreshClick = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    getTasks();
  };

  const handleRemoveTask = (taskId: string): void => {
    const newList = [...taskList.filter((el) => el.task_id !== taskId)];
    setTaskList(newList);
  };

  const handleOffsetUpdate = (newOffset: number) => {
    setOffset(newOffset);
  };

  return (
    <TaskListContainer>
      <AdHocExecuteTask orderId={orderId} />
      <TaskListTitle>
        <Typography variant="h5" component="h2" align="left">
          Tasks List
        </Typography>

        <TaskListRefreshButton
          data-id={0}
          variant="form"
          color="primary"
          align="right"
          onClick={handleRefreshClick}
        >
          <CachedIcon />
        </TaskListRefreshButton>
      </TaskListTitle>

      <TaskListPagination>
        <PaginationButton
          variant="form"
          color="primary"
          disabled={offset === 0}
          onClick={() => handleOffsetUpdate(0)}
        >
          First
        </PaginationButton>
        <PaginationButton
          variant="form"
          color="primary"
          disabled={offset === 0}
          onClick={() => handleOffsetUpdate(offset - pageSize)}
        >
          Prev {pageSize}
        </PaginationButton>
        <b>Page {offset / pageSize + 1}</b>
        <PaginationButton
          variant="form"
          color="primary"
          disabled={taskList.length < pageSize}
          onClick={() => handleOffsetUpdate(offset + pageSize)}
        >
          Next {pageSize}
        </PaginationButton>
      </TaskListPagination>

      {error && <Error error={error} />}
      {!error && loading && (
        <TaskListLoading>
          <TaskListProgress /> Please wait, collecting task details...
        </TaskListLoading>
      )}
      {!error &&
        !loading &&
        taskList.map(
          ({
            celery_job_id,
            date_of_creation,
            description,
            task_name,
            task_id,
            task_owner,
            related_id,
            celery_job_to_order,
          }: CeleryJob) => (
            <TaskListItem key={celery_job_id}>
              <TaskListItemHeader>
                <Grid container spacing={3}>
                  <Grid container item xs={11}>
                    <Grid item xs={6}>
                      <b>Order ID: </b> {related_id}
                    </Grid>
                    <Grid item xs={6}>
                      <b>Wilson Order ID: </b>
                      {celery_job_to_order?.order_no ? celery_job_to_order.order_no : '-'}
                    </Grid>
                    <Grid item xs={6}>
                      <b>Date: </b> {convertToLocalDate(date_of_creation)}
                    </Grid>
                    <Grid item xs={6}>
                      <b>Wilson Name: </b>
                      {celery_job_to_order?.name ? celery_job_to_order.name : `-`}
                    </Grid>
                  </Grid>
                  <Grid item xs={1}>
                    <DeleteButton
                      onClick={() => handleModal(task_id)}
                      variant="form"
                      color="secondary"
                    >
                      <DeleteIcon />
                    </DeleteButton>
                  </Grid>
                </Grid>
              </TaskListItemHeader>

              <TaskDetails
                taskId={task_id}
                taskJobId={celery_job_id}
                taskName={task_name}
                taskOwner={`${task_owner === userId ? email : task_owner}`}
                taskDescription={description}
              />
            </TaskListItem>
          )
        )}

      <ConfirmModal taskId={confirm.taskId} removeTask={handleRemoveTask} query={'REVOKE_TASK'} />
    </TaskListContainer>
  );
};

export default withModalContext(TasksList);
