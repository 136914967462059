import styled from 'styled-components';

import { Chip } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import Button from 'Components/Button';

import { CeleryStatusColor } from 'Constants';

import { STATUS } from './Tasks.types';

export const TaskListContainer = styled.div`
  margin: 1rem;
  padding: 0 0 1rem 0;
  min-height: 100%;
  background-color: #fff;
`;

export const TaskListItem = styled.div`
  margin: 1rem;
  border-bottom: 1px solid #aaa;
`;

export const TaskListTitle = styled.div`
  margin: 1rem;
  padding-top: 1rem;
  border-bottom: 1px solid #aaa;
  display: flex;
  align-items: center;
  justify-content: start;
`;

export const TaskListProgress = styled(CircularProgress)`
  width: 25px !important;
  height: 25px !important;
  color: #555;
  margin-right: 1rem;
`;

export const TaskListLoading = styled.div`
  margin: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TaskListPagination = styled.div`
  margin: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PaginationButton = styled(Button)`
  margin: 1rem 0.5rem;

  &:disabled {
    background-color: #ccc;
  }
`;

export const TaskListRefreshButton = styled(Button)`
  padding: 0.2rem 0.5rem;
  margin: 0 0.5rem;
  margin-left: auto;
`;

export const TaskListItemHeader = styled.div`
  margin: 1rem 0;
  border-bottom: 1px solid #ccc;
`;

export const DeleteButton = styled(Button)`
  padding: 0.2rem 0.5rem;
  margin-right: auto;
  cursor: pointer;
`;

export const StyledChip = styled(Chip)<{ status: STATUS }>`
  background-color: ${(props) => CeleryStatusColor[`${props.status}` as STATUS]};
  color: #fff;
  cursor: pointer;
`;

export const ChipWrapperMin = styled.div`
  min-width: 100%;
  display: block;
  padding: 1.5rem 0;
`;

export const RightAlignedButton = styled(Button)`
  float: right;
`;
