import React, { FC, useState, useEffect, useCallback } from 'react';
import ReactMapGL, {
  InteractiveMapProps,
  Source,
  Layer,
  LayerProps,
  FlyToInterpolator,
} from 'react-map-gl';
import { FeatureCollection, Geometry, GeoJsonProperties } from 'geojson';
import { OrderArea } from 'Pages/Order/Order.types';
import { COLORS } from 'Constants';

const MAPBOX_TOKEN =
  'pk.eyJ1IjoiamVzcy1uZWlnaGJvdXJseXRpY3MiLCJhIjoiY2prZGNuendoMGJpcDN2b3psZTA1cHFvOCJ9.i7-Zsiza-I_h0d5tX5VV0w';

interface AreaIdInfo {
  areas: OrderArea[];
  selectedAreas: number[];
  lastSelectedArea: number;
  color: 'primary' | 'secondary';
}

type LatLon = {
  latitude: number;
  longitude: number;
};

const PlacesMap: FC<AreaIdInfo> = ({ areas, selectedAreas, lastSelectedArea, color }) => {
  const [viewport, setViewport] = React.useState<Partial<InteractiveMapProps>>({
    longitude: 0,
    latitude: 0,
    zoom: 1,
    bearing: 0,
    pitch: 0,
  });
  const [polygonCentroids, setPolygonCentroids] = useState<LatLon[]>([]);
  const [data, setData] = useState<FeatureCollection<Geometry, GeoJsonProperties>>();

  const colorMatch = (color: string) => {
    switch (color) {
      case 'primary':
        return COLORS.neighbourlyticsBlue;
      case 'secondary':
        return COLORS.neighbourlyticsPink;
      default:
        return COLORS.neighourlyticsGreen;
    }
  };

  const layerFill: LayerProps = {
    id: 'fill',
    source: 'data',
    type: 'fill',
    paint: {
      'fill-color': ['get', 'color'], // blue color fill
      'fill-opacity': ['get', 'opacity'],
    },
  };

  const layerOutline: LayerProps = {
    id: 'outline',
    source: 'data',
    type: 'line',
    paint: {
      'line-color': ['get', 'color'],
      'line-width': 3,
    },
  };

  const layerText: LayerProps = {
    id: 'title',
    type: 'symbol',
    source: 'data',
    paint: {},
    layout: {
      'icon-image': ['get', 'name'],
      'text-field': ['get', 'name'],
      'text-offset': [0, 0],
      'text-anchor': 'center',
    },
  };

  const onSelectArea = useCallback((loc: LatLon) => {
    setViewport({
      ...loc,
      zoom: 12,
      transitionInterpolator: new FlyToInterpolator({ speed: 1000 }),
      transitionDuration: 'auto',
    });
  }, []);

  useEffect(() => {
    if (lastSelectedArea > 0 && polygonCentroids.length > 0) {
      const lastSelectedOA: number = areas.findIndex(
        (area) => area.order_area_id === lastSelectedArea
      );
      if (lastSelectedOA >= 0) {
        onSelectArea(polygonCentroids[lastSelectedOA]);
      }
    }
  }, [lastSelectedArea, polygonCentroids]);

  // Map Geometry for each area_id and set Map Center to mapAreas[0]
  useEffect(() => {
    if (!areas || areas.length <= 0) return;
    const stores: FeatureCollection<Geometry, GeoJsonProperties> = {
      type: 'FeatureCollection',
      features: areas.map((item) => ({
        properties: {
          name: item.name,
          color: selectedAreas.indexOf(item.order_area_id) >= 0 ? colorMatch(color) : 'lightgrey',
          opacity: selectedAreas.indexOf(item.order_area_id) >= 0 ? 0.6 : 0.05,
        },
        type: 'Feature',
        geometry: item.geometry,
      })),
    };
    setData(stores);
  }, [areas, selectedAreas]);

  // Map Centroid for each area_id
  useEffect(() => {
    if (!areas) return;

    const centerOfEachPolygon = areas.map((item) => {
      let latitude = 0;
      let longitude = 0;
      item.geometry.coordinates[0].forEach((pair: number[]) => {
        latitude += pair[1];
        longitude += pair[0];
      });
      latitude = latitude / item.geometry.coordinates[0].length;
      longitude = longitude / item.geometry.coordinates[0].length;
      const polygonCentroidsResult: LatLon = { latitude, longitude };
      return polygonCentroidsResult;
    });
    setPolygonCentroids(centerOfEachPolygon);
    // go to first area once centroids are known, this is to ensure it only happens once
    onSelectArea(centerOfEachPolygon[0]);
  }, [areas]);

  return (
    <ReactMapGL
      {...viewport}
      width="100%"
      height="650px"
      onViewportChange={setViewport}
      mapStyle="mapbox://styles/jess-neighbourlytics/ckt0s21910f9o18qrn1idol7k"
      mapboxApiAccessToken={MAPBOX_TOKEN}
    >
      <Source id="data" type="geojson" data={data}>
        <Layer {...layerOutline} />
        <Layer {...layerFill} />
        <Layer {...layerText} />
      </Source>
    </ReactMapGL>
  );
};

export default PlacesMap;
