import React, { useState, SetStateAction } from 'react';
import AnimateHeight from 'react-animate-height';
import { ListItemIcon, ListItemText } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import DeleteIcon from '@material-ui/icons/Delete';

import ConfirmModal from 'Pages/Tasks/ConfirmModal';
import { withModalContext, useModal } from 'Components/Modal/Modal.hook';
import Results, { ResultDetails } from './Results';
import { STATUS_VALS } from 'Pages/Tasks/Tasks.types';
import StatusChip from 'Components/StatusChip';
import { StyledListItem } from 'Components/Menu/Menu.styles';
import { StyledListHeader, StyledExpandButton, StyledDeleteButton } from './TaskDetails.styles';

interface TaskProps {
  id: string;
  childId: string;
  parentId?: string;
  taskName?: string;
  taskResults: Record<string, any>;
  handleChildRefreshButtonClick: () => void;
  setLoadingResults: (value: SetStateAction<number | string>) => void;
  loadingResults: boolean;
}

// #TODO: Type this Component... use TaskChildrenType
interface TaskChildrenType {
  id: string;
  parentId: string;
  taskResults: ResultDetails;
  handleChildRefreshButtonClick: () => void;
  setLoadingResults: (value: SetStateAction<boolean>) => void;
  loadingResults: boolean;
}

const TaskChildren = ({
  id,
  parentId,
  taskResults,
  handleChildRefreshButtonClick,
  setLoadingResults,
  loadingResults,
}: any) => {
  const [height, setHeight] = useState<number | string>(0);
  // `children` indicates task has child tasks (as in group task) whereas `childTasks` indicates this was a chained task current task was a parent, a tad different
  const { children, status, waiting, name } = taskResults;
  const { setOpenModal } = useModal();
  const [confirm, setConfirm] = useState({ taskId: '' });
  const [taskChildren, setTaskChildren] = useState<any>(children);

  let statusCheck = '';
  if (STATUS_VALS[status]) statusCheck = status;
  else if (status === 'false' && waiting === true) statusCheck = 'PENDING';
  else if (status === 'true') statusCheck = 'SUCCESS';
  else statusCheck = 'FAILURE';

  const removeTask = (taskId: string): void => {
    const newList = [...taskChildren.filter((el: any) => el.task_id !== taskId)];
    setTaskChildren(newList);
  };

  const handleModal = (taskId: string) => {
    setOpenModal();
    setConfirm({ taskId: taskId });
  };

  const handleResizeButtonClick = () => {
    if (height === 0) {
      setHeight('auto');
    } else {
      setHeight(0);
    }
  };

  const taskHeader = taskChildren ? 'Group Task ID: ' : 'Task:';
  const extendedTaskId = parentId ? `${id} (parent ${parentId})` : id;
  const taskIdentifier = taskChildren || !name ? extendedTaskId : `${name} [${extendedTaskId}]`;

  return (
    <>
      <StyledListItem
        label="button"
        component="div"
        alignItems="flex-start"
        onClick={handleResizeButtonClick}
      >
        <StyledListHeader>
          <ListItemIcon>
            <StatusChip
              loadingResults={loadingResults}
              setLoadingResults={setLoadingResults}
              taskChildren={taskChildren}
              statusCheck={statusCheck}
              status={status}
              handleChildRefreshButtonClick={handleChildRefreshButtonClick}
            />
          </ListItemIcon>
          <ListItemText primary={taskHeader} secondary={taskIdentifier} />
          <StyledExpandButton onClick={handleResizeButtonClick}>
            {height === 'auto' ? (
              <ExpandLessIcon fontSize="large" />
            ) : (
              <ExpandMoreIcon fontSize="large" />
            )}
          </StyledExpandButton>
        </StyledListHeader>
      </StyledListItem>
      {!taskChildren && (
        <AnimateHeight duration={500} height={height}>
          <Results taskId={id} {...taskResults} />
          {statusCheck !== 'FAILURE' && statusCheck !== 'SUCCESS' && statusCheck !== 'REVOKED' && (
            <StyledDeleteButton onClick={() => handleModal(id)} variant="form" color="secondary">
              <DeleteIcon /> Revoke Task
            </StyledDeleteButton>
          )}
        </AnimateHeight>
      )}
      {taskChildren &&
        taskChildren.map(
          ({ childId, parentId, taskResults }: TaskProps) =>
            childId && (
              <TaskChildren
                id={childId}
                parentId={parentId}
                taskResults={taskResults}
                handleChildRefreshButtonClick={handleChildRefreshButtonClick}
                setLoadingResults={setLoadingResults}
                loadingResults={loadingResults}
              />
            )
        )}
      <ConfirmModal taskId={confirm.taskId} removeTask={removeTask} query={'REVOKE_TASK'} />
    </>
  );
};

export default withModalContext(TaskChildren);
