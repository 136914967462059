import { JSXElementConstructor, ReactElement } from 'react';
import { Toaster, resolveValue, toast } from 'react-hot-toast';

import CustomToast from './CustomToast';

export function Toasty(): ReactElement<undefined, JSXElementConstructor<Element>> {
  const remove = (id: string): void => {
    toast.remove(id);
  };

  return (
    <Toaster
      position="top-right"
      reverseOrder={false}
      gutter={8}
      toastOptions={{
        error: {
          duration: Infinity,
        },
        custom: {
          duration: Infinity,
          style: {
            position: 'absolute',
            width: '13rem',
            padding: '.7rem',
            background: 'rgba(175, 75, 62, 0.1)',
            borderRadius: '1.6rem',
            transition: 'all 0.2s',
            transform: `translateY(50px)`,
          },
        },
      }}
    >
      {(poppedToast) => (
        <CustomToast
          remove={remove}
          id={poppedToast.id}
          visible={poppedToast.visible}
          message={poppedToast.message}
        >
          {resolveValue(poppedToast.message, poppedToast)}
        </CustomToast>
      )}
    </Toaster>
  );
}
