import { FC, useState, useCallback, useEffect } from 'react';
import { ApolloError, useMutation } from '@apollo/client';
import { useDropzone } from 'react-dropzone';
import Papa from 'papaparse';
import { Container } from './ScrapingCsvImport.styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';
import { convertConvertedCSV, errorBuilder } from 'helpers';
import AnimateHeight from 'react-animate-height';
import Spacer from 'Components/Spacer';
import Loader from 'Components/Loader';
import { RUN_SCRAPER } from 'Queries';
import Error from 'Components/Error';
import { SCRAPER_REQUIRED_COLUMNS, ERRORS } from 'Constants';
import Button from 'Components/Button';

interface ScraperCsvProps {
  wilson_order_id: number;
  order_id: number;
}

interface CsvChildTask {
  FolderID: string;
  Neighbourhood: string;
  SubFolders: string;
  TZ_Name: string;
  CAPTURE_FROM: string;
  CAPTURE_TO: string;
  LocationID: string;
  InstagramLink: string;
  ChatterLocationID: number;
  LoginUsername: string;
  LoginPassword: string;
}

type ApolloErrorReport = {
  message: string;
  errorMessages: string[];
};

type ScraperCsv = {
  FolderID: string[];
  Neighbourhood: string[];
  SubFolders: string[];
  TZ_Name: string[];
  CAPTURE_FROM: string[];
  CAPTURE_TO: string[];
  LocationID: string[];
  InstagramLink: string[];
  ChatterLocationID: number[];
  LoginUsername: string[] | null;
  LoginPassword: string[] | null;
};

const ScrapingCsvImport: FC<ScraperCsvProps> = ({ wilson_order_id, order_id }) => {
  const [height, setHeight] = useState<number | string>(0);
  const [loadedCSV, setCSV] = useState<any | undefined>(undefined);
  const [taskId, setTaskId] = useState<string | undefined>(undefined);
  const [error, setError] = useState<{ message: ERRORS } | undefined>(undefined);
  const [queryError, setQueryError] = useState<ApolloErrorReport | undefined>(undefined);

  useEffect(() => {
    setHeight(() => 'auto');
  }, []);

  const [uploadCSV, { loading }] = useMutation(RUN_SCRAPER, {
    variables: { order_id, wilson_order_id, scraperCsv: JSON.stringify(loadedCSV) },
    onCompleted: ({ runScraper: { taskId } }) => {
      if (taskId) setTaskId(taskId);
    },
    onError: (error: ApolloError) => {
      const errorReport: ApolloErrorReport = {
        message: error.message,
        errorMessages: error.graphQLErrors.map((gqle) => gqle.message),
      };
      setQueryError(errorReport);
    },
  });

  const validateCSV = (csv: ScraperCsv) => {
    setError(undefined);
    setQueryError(undefined);
    // Temp convert csv to object for validation
    const tempCsv: ScraperCsv = convertConvertedCSV(csv);

    // Confirm all required columns exist in csv
    const validColumnsPopulated = SCRAPER_REQUIRED_COLUMNS.every((item: string) =>
      tempCsv.hasOwnProperty(item)
    );

    // Confirm all values for all required columns are populated in the csv (no blank cells)
    const { LoginUsername, LoginPassword, ...requiredCsvInputs } = tempCsv;
    const validInput = !Object.values(requiredCsvInputs)
      .flatMap((value) => `${value}`)
      .includes('');

    if (validColumnsPopulated) {
      if (validInput) {
        setCSV(csv);
      } else {
        setCSV(undefined);
        setError(errorBuilder('POPULATE_SCRAPER_CSV'));
      }
    } else {
      setCSV(undefined);
      setError(errorBuilder('VALIDATE_SCRAPER_CSV'));
    }
  };

  const onDrop = useCallback(
    async (acceptedFiles) => {
      await Papa.parse(acceptedFiles[0], {
        header: true,
        dynamicTyping: false,
        complete: (results: any) => {
          validateCSV(results.data);
        },
      });
    },
    [validateCSV]
  );

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    accept: '.csv',
  });

  return (
    <AnimateHeight id="example-panel" duration={500} height={height}>
      <Spacer>
        <Container id="upload-csv" {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
          <input {...getInputProps()} />
          {!loading && <p>Drag 'n' drop your csv, or click to select files</p>}
          {loading && <p>Kicking off scraper...</p>}
        </Container>
        {error && <Error error={error} />}
        {loadedCSV && loadedCSV.length > 0 && (
          <Spacer>
            <p>
              Please review your CSV below. Each row will kick off an individual scraper task. If
              you are satisfied, click on the "Run Instagram Scraper" button!
            </p>

            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Folder ID</TableCell>
                    <TableCell>Neighbourhood</TableCell>
                    <TableCell>Subfolders</TableCell>
                    <TableCell>Timezone</TableCell>
                    <TableCell>Capture From</TableCell>
                    <TableCell>Capture To</TableCell>
                    <TableCell>Days Count</TableCell>
                    <TableCell>Location ID</TableCell>
                    <TableCell>InstagramLink</TableCell>
                    <TableCell>Chatter Location ID</TableCell>
                    <TableCell>Username</TableCell>
                    <TableCell>Password</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loadedCSV.map(function (row: CsvChildTask, i: number) {
                    const largerDate = new Date(row.CAPTURE_TO);
                    const smallerDate = new Date(row.CAPTURE_FROM);
                    const timeInMilliseconds = largerDate.getTime() - smallerDate.getTime();
                    const countOfDays = Math.ceil(timeInMilliseconds / (1000 * 60 * 60 * 24) + 1);
                    return (
                      <TableRow key={i}>
                        <TableCell>{row.FolderID}</TableCell>
                        <TableCell>{row.Neighbourhood}</TableCell>
                        <TableCell>{row.SubFolders}</TableCell>
                        <TableCell>{row.TZ_Name}</TableCell>
                        <TableCell>{row.CAPTURE_FROM}</TableCell>
                        <TableCell>{row.CAPTURE_TO}</TableCell>
                        <TableCell>{countOfDays}</TableCell>
                        <TableCell>{row.LocationID}</TableCell>
                        <TableCell>
                          <a href={row.InstagramLink}>{row.InstagramLink}</a>
                        </TableCell>
                        <TableCell>{row.ChatterLocationID}</TableCell>
                        <TableCell>{row.LoginUsername}</TableCell>
                        <TableCell>{row.LoginPassword}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>

            {!error && !queryError && (
              <Button onClick={uploadCSV} variant="form" color="primary">
                {!loading && <p>Run Instagram Scraper</p>}
                {loading && <Loader color="white" />}
              </Button>
            )}
            {queryError && <Error error={queryError} />}
            {taskId && <h4> Successfully uploaded : {`${taskId}`}</h4>}
          </Spacer>
        )}
      </Spacer>
    </AnimateHeight>
  );
};

export default ScrapingCsvImport;
