import { FC } from 'react';

// Pages
import TaggingJobs from 'Pages/TaggingJobList';

interface OrderTaggingJobProps {
  order_id: number;
}

const OrderTaggingJobs: FC<OrderTaggingJobProps> = ({ order_id }) => {
  return (
    <div>
      <TaggingJobs order_id={order_id} />
    </div>
  );
};

export default OrderTaggingJobs;
