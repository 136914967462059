import { FC, useState, useMemo, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';

import { useUsers } from 'Providers/UserProvider';
import { BartThemeContext } from 'Providers/BartThemeProvider';

// Queries
import {
  GET_TAGGING_JOB_DETAILS,
  GET_TAGGING_JOB_IMAGES,
  UPDATE_TAGGING_IMAGE_CATEGORY,
} from 'Queries';

// Components
import { CircularProgress, Grid } from '@material-ui/core';
import TaggingInterfaceFooter from './TaggingInterfaceFooter';
import TaggingInterfaceImageList from './TaggingInterfaceImageList';
import TaggingInterfaceImage from './TaggingInterfaceImage';
import TaggingInterfaceTagList from './TaggingInterfaceTagList';
import TaggingInterfaceFilter from './TaggingInterfaceFilter';

// Styling
import { TaggingContainer, ImageSidebar } from './TaggingInterface.styles';

// Types
import {
  JobStatus,
  TaggingJobDetailsQueryData,
  TaggingJobImage,
  TaggingJobImagesQueryData,
  JobAction,
} from './TaggingInterface.types';
import SkeletonLoader from 'Components/SkeletonLoader';

declare global {
  interface WindowEventMap {
    keydown: React.KeyboardEvent<HTMLInputElement>;
  }
}

type RoleType = 'admin' | 'tagger' | 'disabled';

type FiltersType = 'No Filter' | 'For Review' | 'Untagged' | string; // string handles for 61 chatter tags

const getJobAction = (
  taggingJobId: number,
  job_status: JobStatus,
  enabledSubmit: boolean,
  enabledClose: boolean
): JobAction => {
  switch (job_status) {
    case 'open':
      return { taggingJobId: taggingJobId, type: 'submit', enabled: enabledSubmit };
    case 'submitted':
      return { taggingJobId: taggingJobId, type: 'close', enabled: enabledClose };
    default:
      return { taggingJobId: taggingJobId, type: 'submit', enabled: false };
  }
};

export const getFirstItem = (jobImages: TaggingJobImage[]) => {
  const untaggedImages = jobImages.filter((img) => img.chatter_category_id === null);
  const unreviewedImages = jobImages.filter((img) => img.review_status === 'for_review');
  if (untaggedImages.length > 0) {
    return untaggedImages[0]; // Returns first untagged image
  } else if (unreviewedImages.length > 0) {
    return unreviewedImages[0]; // Returns first unreviewed image (if all tagged)
  }
  return jobImages[0];
};

const TaggingInterface: FC = () => {
  const { taggingJobId: taggingJobIdString }: { taggingJobId: string } = useParams();
  const { loggedInUser } = useUsers();
  const taggingJobId = parseInt(taggingJobIdString);

  // Data state management
  const [taggingJobImages, setTaggingJobImages] = useState<TaggingJobImage[]>([]);
  const [filteredImages, setFilteredImages] = useState<TaggingJobImage[]>([]);

  // UX state management
  const [selectedId, setSelectedId] = useState<number>(0);
  const [selectedTag, setSelectedTag] = useState<number | null>(null);
  const [reviewStatus, setReviewStatus] = useState<boolean>(false);

  const [selectedFilter, setSelectedFilter] = useState<FiltersType>('No Filter');

  const themeContext = useContext(BartThemeContext);

  const loggedInRoles = loggedInUser?.roles || [];
  const isTagAdmin = loggedInRoles.some((r) => ['user', 'tagadmin'].includes(r));

  const { data, loading: dataLoading } = useQuery<TaggingJobDetailsQueryData>(
    GET_TAGGING_JOB_DETAILS,
    {
      variables: { tagging_job_id: taggingJobId },
      fetchPolicy: 'network-only',
      onCompleted: () => {
        getTaggingJobImages({
          variables: {
            tagging_job_id: taggingJobId,
          },
        });
      },
    }
  );

  const [getTaggingJobImages] = useLazyQuery<TaggingJobImagesQueryData>(GET_TAGGING_JOB_IMAGES, {
    variables: { tagging_job_id: taggingJobId },
    fetchPolicy: 'network-only',
    onCompleted: (d) => {
      // Only select first item IF this is the first data fetch
      if (taggingJobImages.length <= 0) {
        const firstItem = getFirstItem(d.tagging_job_image);
        setSelectedId(firstItem.tagging_job_image_id);
        setReviewStatus(firstItem.review_status == 'for_review');
        setSelectedTag(firstItem.chatter_category_id);
      }
      setTaggingJobImages(d.tagging_job_image);
      setFilteredImages(d.tagging_job_image);
    },
  });

  // Save or update data management
  const onMutationComplete = () => {
    // Move to next image in list and updated tag (if applicable)
    const selectedIndex = taggingJobImages.findIndex(
      (img) => img.tagging_job_image_id === selectedId
    );
    if (selectedIndex + 1 < filteredImages.length) {
      const newItem = filteredImages[selectedIndex + 1];
      setSelectedId(newItem.tagging_job_image_id);
      setSelectedTag(newItem.chatter_category_id);
      setReviewStatus(newItem.review_status === 'for_review');
    }

    // Refetch data with updated values
    getTaggingJobImages();
  };

  const [updateTagCategory] = useMutation(UPDATE_TAGGING_IMAGE_CATEGORY, {
    onCompleted: onMutationComplete,
  });

  const selectedImage = taggingJobImages.find((img) => img.tagging_job_image_id === selectedId);

  // Job cannot be acted on unless tagging user matches and job status is "open"
  const canChangeTags: RoleType = useMemo(() => {
    if (!loggedInUser || !data) return 'disabled';

    const loggedInUserIsAssignedTagger =
      loggedInUser.firebase_user_id === data.tagging_job.tagger_user_id;

    const jobIsInOpenStatus = data.tagging_job.job_status === 'open';
    const jobIsInSubmittedStatus = data.tagging_job.job_status === 'submitted';

    if (loggedInUserIsAssignedTagger && jobIsInOpenStatus) return 'tagger';
    if (isTagAdmin && jobIsInSubmittedStatus) return 'admin';

    return 'disabled';
  }, [loggedInUser?.firebase_user_id, data?.tagging_job]);

  const onImageListClick = (id: number) => {
    const newItem = filteredImages.find((img) => img.tagging_job_image_id === id);

    if (!newItem) return;

    setSelectedTag(newItem.chatter_category_id);
    setSelectedId(id);
    setReviewStatus(newItem.review_status === 'for_review');
  };

  const handleUserKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { key } = event;
    const index: number = filteredImages.findIndex((x) => x.tagging_job_image_id === selectedId);

    switch (key) {
      case 'ArrowUp':
        index > 0 && onImageListClick(filteredImages[index - 1].tagging_job_image_id); // Nav to previous TaggingJobImage
        break;
      case 'ArrowDown':
        index != filteredImages.length - 1 &&
          onImageListClick(filteredImages[index + 1].tagging_job_image_id); // Nav to next TaggingJobImage
        break;
      case 'Shift':
        onSubmitClick(); // Save Tag & Next
        break;
      case 'Enter':
        onImageListClick(getFirstItem(filteredImages).tagging_job_image_id); // Reselect next actionable image
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (filteredImages.length <= 0) return;

    window.addEventListener('keydown', handleUserKeyPress);

    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  });

  if (dataLoading) return <SkeletonLoader />;

  if (!data) return <div>There should be some data here :( </div>;

  const onSubmitClick = () => {
    // Note - chatter_label 'ignore' is chatter_category_id 0
    if (selectedTag !== null) {
      const img = filteredImages.find((img) => img.tagging_job_image_id === selectedId);

      if (!img || !data.tagging_job.tagger_user_id) return;

      const newReviewStatus =
        (canChangeTags === 'admin' && img.review_status === 'for_review' && 'reviewed') ||
        (canChangeTags === 'tagger' && reviewStatus && 'for_review');

      updateTagCategory({
        variables: {
          author_user_id: loggedInUser?.firebase_user_id,
          chatter_category_id: selectedTag,
          tagging_job_image_id: selectedId,
          review_status: newReviewStatus ? newReviewStatus : null,
        },
      });
    }
  };

  const onIgnoreClick = () => {
    if (selectedTag === 0) {
      setSelectedTag(null); // To revert select
    } else {
      setSelectedTag(0);
    }
  };

  const onReviewClick = () => {
    setReviewStatus(!reviewStatus);
  };

  // every image is tagged or ignored
  const everyImageTagged = taggingJobImages.every((t) => t.chatter_category_id != null);
  const everyImageReviewed = taggingJobImages.every((t) => t.review_status !== 'for_review');

  const enableSubmit = canChangeTags === 'tagger' && everyImageTagged;
  const enableClose = isTagAdmin && everyImageTagged && everyImageReviewed;

  const jobAction = getJobAction(
    taggingJobId,
    data.tagging_job.job_status,
    enableSubmit,
    enableClose
  );

  return (
    <TaggingContainer backgroundColor={themeContext.backgroundStyle}>
      {/* #TODO: Implement background color styling from Context */}
      <Grid container spacing={0}>
        <Grid item xs={1}>
          <TaggingInterfaceFilter
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
            taggingJobImages={taggingJobImages}
            filteredImages={filteredImages}
            setFilteredImages={setFilteredImages}
            setSelectedId={setSelectedId}
            setSelectedTag={setSelectedTag}
            setReviewStatus={setReviewStatus}
          />
          <ImageSidebar>
            <TaggingInterfaceImageList
              selectedId={selectedId}
              updateSelectedId={onImageListClick}
              images={filteredImages}
            />
          </ImageSidebar>
          <TaggingInterfaceFooter jobAction={jobAction} />
        </Grid>

        <Grid container item xs={7}>
          {selectedImage ? (
            <TaggingInterfaceImage
              image={selectedImage}
              disableForm={canChangeTags === 'disabled'}
              disableSubmit={selectedTag === null}
              reviewStatus={reviewStatus}
              onSubmitClick={onSubmitClick}
              ignoreSelected={selectedTag === 0}
              onIgnoreClick={onIgnoreClick}
              onReviewClick={onReviewClick}
            />
          ) : (
            <CircularProgress />
          )}
        </Grid>

        <TaggingInterfaceTagList
          selectedTag={selectedTag}
          onTagClick={setSelectedTag}
          disableForm={canChangeTags === 'disabled'}
        />
      </Grid>
    </TaggingContainer>
  );
};

export default TaggingInterface;
