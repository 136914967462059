import { FC, SyntheticEvent, useState } from 'react';
import { useMutation } from '@apollo/client';
import { CREATE_TAGGING_JOB } from 'Queries';
import { useUsers } from 'Providers/UserProvider';

import Button from 'Components/Button';

import { OrderTaggingNewJobProps } from './TaggingJobList.types';

import {
  TextField,
  Select,
  MenuItem,
  Box,
  TableHead,
  TableRow,
  TableCell,
  FormHelperText,
} from '@material-ui/core';
import { StyledHeaderCell, StyledButton, StyledTextField } from './TaggingJobList.styles';

const OrderTaggingNewJob: FC<OrderTaggingNewJobProps> = ({ chatterLocation, refreshJobs }) => {
  const { users, loggedInUser } = useUsers();

  //Form Variables
  const [selectedUser, setSelectedUser] = useState<number>(loggedInUser?.firebase_user_id || -1);
  const [notes, setNotes] = useState<string>('');
  const [modulus, setModulus] = useState<number>(1);

  const [insertTaggingJob] = useMutation(CREATE_TAGGING_JOB, {
    variables: {
      chatter_location_id: chatterLocation.chatter_location_id,
      created_by_user: loggedInUser?.firebase_user_id,
      tagger_user_id: selectedUser,
      job_status: 'pending',
      notes: notes,
      modulus: modulus,
    },
    onCompleted: () => {
      refreshJobs();
    },
    onError: (error) => {
      return <div>{error.message}</div>;
    },
  });

  const onSaveClick = (e: SyntheticEvent) => {
    e.preventDefault();
    if (modulus > 0) insertTaggingJob();
  };

  const onCancelClick = () => {
    refreshJobs();
  };

  return (
    <>
      <TableHead>
        <TableRow>
          <StyledHeaderCell>Source ID</StyledHeaderCell>
          <StyledHeaderCell padding="none">Images</StyledHeaderCell>
          <StyledHeaderCell>Creator</StyledHeaderCell>
          <StyledHeaderCell colSpan={2} padding="none">
            Notes
          </StyledHeaderCell>
          <StyledHeaderCell>Select Tagger</StyledHeaderCell>
          <StyledHeaderCell>Job Images</StyledHeaderCell>
          <StyledHeaderCell>Modulus</StyledHeaderCell>
          <StyledHeaderCell>Tools</StyledHeaderCell>
        </TableRow>
      </TableHead>
      <TableRow>
        <TableCell>
          {
            <a href={chatterLocation.source_link} target="blank">
              {chatterLocation.source_location_identifier}
            </a>
          }
        </TableCell>
        <TableCell padding="none">
          {chatterLocation.chatter_images_aggregate.aggregate.count}
        </TableCell>
        <TableCell>{loggedInUser?.display_name}</TableCell>
        <TableCell colSpan={2} padding="none">
          <TextField
            value={notes}
            fullWidth={true}
            error={notes.length > 50}
            onChange={(e) => {
              const val = e.target.value as string;
              setNotes(val);
            }}
          />
          {notes.length > 50 && (
            <FormHelperText id="notes-error-text">
              Warning - input too Long. Please reduce input
            </FormHelperText>
          )}
        </TableCell>
        <TableCell>
          <Select
            required
            MenuProps={{
              disableScrollLock: true,
            }}
            value={selectedUser}
            onChange={(e) => {
              const val = e.target.value as string;
              setSelectedUser(parseInt(val));
            }}
          >
            {users.map((user, index) => (
              <MenuItem key={index} value={user.firebase_user_id}>
                {user.display_name}
              </MenuItem>
            ))}
          </Select>
        </TableCell>

        <TableCell>
          {modulus >= 1
            ? Math.ceil(chatterLocation.chatter_images_aggregate.aggregate.count / modulus)
            : 'Please enter a positive modulus value'}
        </TableCell>
        <TableCell>
          <StyledTextField
            type="number"
            required
            value={modulus}
            size="small"
            margin="none"
            onChange={(e) => {
              const val = e.target.value ? (e.target.value as string) : '1';
              setModulus(parseInt(val));
            }}
          />
        </TableCell>
        <TableCell>
          <Box display="flex" justifyContent="flex-end">
            <StyledButton
              type="submit"
              variant="form"
              color="primary"
              disabled={modulus <= 0}
              onClick={onSaveClick}
            >
              Add New Job
            </StyledButton>
            <Button variant="form" color="secondary" onClick={onCancelClick}>
              Cancel
            </Button>
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
};

export default OrderTaggingNewJob;
