import styled from 'styled-components';

export const TableHeading = styled.h1`
  font-size: 20px;
  padding: 1rem;
`;

export const InstructionsBox = styled.div`
  display: flex;
  justify-content: center;
  width: 520px;
  padding: 10px 10px 10px 20px;
  font-size: 12px;
  border-radius: 20px;
  border: 1px solid #ddd;
`;
