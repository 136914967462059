import React, { FC, SyntheticEvent, forwardRef } from 'react';
import { useHistory } from 'react-router-dom';
import { StyledButton } from './Button.styles';
import { ButtonProps } from './Button.types';

type Ref = HTMLButtonElement;

const Button: FC<any> = forwardRef<Ref, ButtonProps>((props: ButtonProps, ref: any) => {
  const history = useHistory();
  const { onClick, to, disabled, ...rest } = props as any;

  return (
    <StyledButton
      {...rest}
      ref={ref}
      disabled={disabled}
      onClick={(event: SyntheticEvent) => {
        if (onClick) onClick(event);
        if (to) history.push(to);
      }}
    />
  );
});

Button.displayName = 'ComposedButton';

export default Button;
