import styled from 'styled-components';
import Button from 'Components/Button';

export const StyledFormButton = styled(Button)`
  flex-direction: row;
  padding: 4px 8px;
  border-radius: 12px;
  align-items: center;
  justify-content: center;
  &:disabled {
    background-color: lightgrey;
  }
`;
