import React, { FC } from 'react';
import { Checkbox } from '@material-ui/core';

import { LocationSelection, LatLonProps } from 'Pages/ExportKml/ExportKml.types';

// Styling
import { FixedSidebar, TableWrapper, TableHeader, TableRow, TableCell } from './LatLonList.styles';

type LatLonListProps = Pick<LatLonProps, 'locationsList' | 'setLocationsList' | 'disabled'>;

const LatLonList: FC<LatLonListProps> = ({ locationsList, setLocationsList, disabled }) => {
  return (
    <>
      <FixedSidebar>
        <TableWrapper>
          <TableHeader>
            <TableCell></TableCell>
            <TableCell>Location Name</TableCell>
            <TableCell>Latitude</TableCell>
            <TableCell>Longitude</TableCell>
          </TableHeader>
          {locationsList.map(
            ({ locationName, latitude, longitude }: LocationSelection, index: number) => {
              const input = {
                locationName,
                latitude,
                longitude,
              };
              return (
                <TableRow key={index} disabled={disabled}>
                  <Checkbox
                    name="download"
                    color="primary"
                    value={'all'}
                    checked={true}
                    disabled={true} //disabled} Currently disabled until handling built for this to work (ran out of time)
                    onChange={() => {
                      setLocationsList([...locationsList, input]);
                    }}
                  />
                  <TableCell>{locationName}</TableCell>
                  <TableCell>{latitude}</TableCell>
                  <TableCell>{longitude}</TableCell>
                </TableRow>
              );
            }
          )}
        </TableWrapper>
      </FixedSidebar>
    </>
  );
};

export default LatLonList;
