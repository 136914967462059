import { FC, useContext, useState, MouseEvent } from 'react';

import { Grid, Box, CircularProgress, Checkbox, Popover } from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

// Providers
import { useAuth } from 'Providers';
import { BartThemeContext } from 'Providers/BartThemeProvider';
import { BackgroundStyles, BartThemeContextType } from 'Providers/BartThemeProvider.types';

// Types
import { TaggingJobImage } from './TaggingInterface.types';

// Styles
import {
  MainContainer,
  StyledImage,
  StyledButton,
  StyledCheckbox,
  InstructionsContainer,
  NavInstruction,
  StyledSpan,
  SettingsContainer,
  SettingsItem,
  ContentContainer,
  ImageDetails,
  ContentColumn,
  TimeTitle,
  CaptionContainer,
} from './TaggingInterfaceImage.styles';
import Spacer from 'Components/Spacer';

import { createTaggingImageUrl } from 'helpers';

interface TaggingImageProps {
  image: TaggingJobImage;
  disableForm: boolean;
  disableSubmit: boolean;
  reviewStatus: boolean;
  onSubmitClick: () => void;
  ignoreSelected: boolean;
  onIgnoreClick: () => void;
  onReviewClick: () => void;
}

const displayCaption = (details: { caption?: string | null | undefined }) => {
  const rawCaption = details === null || details.caption === null ? undefined : details.caption;
  switch (rawCaption) {
    case undefined:
      return <i>No caption available</i>;
    case rawCaption:
      return rawCaption;
    default:
      return <i>No caption available</i>;
  }
};

const NavInstructions = (backgroundStyle: BackgroundStyles) => {
  return (
    <InstructionsContainer backgroundcolor={backgroundStyle}>
      <NavInstruction>
        <StyledSpan>
          <ArrowUpwardIcon fontSize="small" />
        </StyledSpan>
        {'Select previous image'}
      </NavInstruction>
      <NavInstruction>
        <StyledSpan>
          <ArrowDownwardIcon fontSize="small" />
        </StyledSpan>
        {'Select next image'}
      </NavInstruction>
      <Spacer />
      <NavInstruction>
        <StyledSpan>
          <b>Shift</b>
        </StyledSpan>
        Save tag and select next image
      </NavInstruction>
      <NavInstruction>
        <StyledSpan>
          <b>Enter</b>
        </StyledSpan>
        Select next actionable image
      </NavInstruction>
    </InstructionsContainer>
  );
};

const Settings = (
  themeContext: BartThemeContextType,
  anchorEl: Element | null,
  enableScrollChange: () => void,
  onBgStyleChange: () => void,
  handleClick: (event: MouseEvent<HTMLDivElement>) => void,
  handleClose: () => void
) => {
  return (
    <SettingsContainer backgroundcolor={themeContext.backgroundStyle}>
      <SettingsItem>
        <StyledButton variant="form" color="neighbourlytics-secondary-blue" onClick={handleClick}>
          Navigation Shortcuts?
        </StyledButton>
        <Popover
          id={anchorEl ? 'simple-popover' : undefined}
          open={anchorEl !== null}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          {NavInstructions(themeContext.backgroundStyle)}
        </Popover>
      </SettingsItem>
      <SettingsItem>
        <div>Enable Image List auto scroll?</div>
        <Checkbox
          disabled={false}
          color="default"
          id="enable-image-list-scroll"
          checked={themeContext.imageListScoll}
          onChange={enableScrollChange}
        />
      </SettingsItem>
      <SettingsItem>
        <div>Darken Window?</div>
        <Checkbox
          disabled={false}
          color="default"
          id="enable-dark-background"
          checked={themeContext.backgroundStyle === 'dark'}
          onChange={onBgStyleChange}
        />
      </SettingsItem>
    </SettingsContainer>
  );
};

const TaggingImage: FC<TaggingImageProps> = ({
  image,
  disableForm,
  disableSubmit,
  reviewStatus,
  onSubmitClick,
  ignoreSelected,
  onIgnoreClick,
  onReviewClick,
}) => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const { storageSignature, signSignature } = useAuth();

  const themeContext = useContext(BartThemeContext);

  const enableScrollChange = () => {
    themeContext.setImageListScoll(!themeContext.imageListScoll);
  };

  const onBgStyleChange = () => {
    themeContext.setBackgroundStyle(themeContext.backgroundStyle === 'dark' ? 'light' : 'dark');
  };

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <MainContainer container item xs={12}>
      {Settings(
        themeContext,
        anchorEl,
        enableScrollChange,
        onBgStyleChange,
        handleClick,
        handleClose
      )}

      <Grid item xs={12}>
        <ContentContainer backgroundcolor={themeContext.backgroundStyle}>
          <Grid item xs={4}>
            <ContentColumn>
              <TimeTitle>{new Date(image.chatter_image.image_timestamp).toString()}</TimeTitle>
              <CaptionContainer>{displayCaption(image.chatter_image.details)}</CaptionContainer>
            </ContentColumn>
          </Grid>
          <Grid item xs={8}>
            <Box display="flex" justifyContent="flex-end">
              {storageSignature ? (
                <StyledImage
                  src={signSignature(
                    createTaggingImageUrl(
                      image.chatter_image.gcs_path,
                      image.chatter_image.filename
                    )
                  )}
                  alt="image"
                />
              ) : (
                <CircularProgress />
              )}
            </Box>
            <ImageDetails>{image.chatter_image.filename}</ImageDetails>
          </Grid>
        </ContentContainer>
      </Grid>

      <Grid item xs={12}>
        <Box display="flex" justifyContent="flex-end">
          <StyledButton
            variant="form"
            color={!disableSubmit ? 'primary' : 'grey'}
            disabled={disableForm || disableSubmit}
            onClick={onReviewClick}
          >
            Raise Tag for Review?
            <StyledCheckbox
              name="locations"
              color="secondary"
              value={'none'}
              checked={reviewStatus}
              disabled={true}
            />
          </StyledButton>
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <StyledButton
            variant="form"
            color={ignoreSelected ? 'secondary' : 'primary'}
            disabled={disableForm}
            onClick={onIgnoreClick}
          >
            Ignore
          </StyledButton>
          <StyledButton
            variant="form"
            color="primary"
            disabled={disableSubmit || disableForm}
            onClick={onSubmitClick}
          >
            Save Tag & Next
          </StyledButton>
        </Box>
      </Grid>
    </MainContainer>
  );
};

export default TaggingImage;
