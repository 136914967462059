import { ApolloError } from '@apollo/client';

export type STATUS =
  | 'FAILURE'
  | 'STARTED'
  | 'SUCCESS'
  | 'PENDING'
  | 'REVOKED'
  | 'RETRY'
  | 'UNKNOWN';

export enum STATUS_VALS {
  'FAILURE',
  'STARTED',
  'SUCCESS',
  'PENDING',
  'REVOKED',
  'RETRY',
}

export interface ConfirmModalProps {
  taskId: string;
  removeTask: (id: string) => void;
  query: 'REMOVE_TASK' | 'REVOKE_TASK';
}

export type ActionType = 'ERRORS' | 'RESULT' | 'CHECKED' | 'RESET';

export interface Action {
  type: ActionType;
  removeTaskResults?: { result: boolean; id: string };
  deleteError?: ApolloError;
  error?: ApolloError | null;
  confirm?: boolean;
  force?: boolean;
}

export interface ModalReducerState {
  checked: { confirm: boolean; force: boolean };
  removeTaskResults?: { result: boolean; id: string } | null;
  deleteResult: { result: boolean; id: string } | null;
  error?: ApolloError | null;
}

export type TaskNames =
  | 'run_neighbourlytics_places'
  | 'runScraper'
  | 'exportKml'
  | 'runPlaceQuery'
  | 'runReviewsQuery';
export interface CeleryJob {
  celery_job_id: number;
  date_of_creation: string;
  related_id: number;
  related_type: string;
  task_name: TaskNames;
  task_id: string;
  task_owner: string;
  description?: string;
  celery_job_to_order: {
    order_id: number[];
    order_no: string[];
    name: string[];
  };
}

export interface TasksProps {
  order_id?: string;
}
export interface Job {
  celery_job: CeleryJob[];
}
