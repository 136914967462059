import styled from 'styled-components';

import { Checkbox, Grid } from '@material-ui/core';
import Button from 'Components/Button';

export const MainContainer = styled(Grid)`
  margin: 5px;
  padding: 5px;
`;

export const StyledImage = styled.img`
  width: 100%;
  object-fit: contain;
`;

export const StyledButton = styled(Button)`
  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  padding: 0 0 0 0.5rem;
`;

export const ContentContainer = styled(Grid)<{ backgroundcolor: 'light' | 'dark' }>`
  // border-radius: 20px;
  padding: 10px;
  display: flex;
  align-items: space-between;
  ${(props) => {
    switch (props.backgroundcolor) {
      case 'light': {
        return 'background-color: #ddd;';
      }
      case 'dark': {
        return 'color: #fff; background-color: #333;';
      }
      default: {
        return 'background-color: #ddd;';
      }
    }
  }}
`;

export const ContentColumn = styled.div`
  padding-right: 10px;
`;

export const TimeTitle = styled.h4`
  padding-bottom: 10px;
`;

export const CaptionContainer = styled.div`
  overflow-y: auto;
  max-height: 60vh;
`;

export const InstructionsContainer = styled.div<{ backgroundcolor: 'light' | 'dark' }>`
  padding: 10px;
  width: 270px;
  font-size: 12px;
  ${(props) => {
    switch (props.backgroundcolor) {
      case 'light': {
        return 'background-color: #ddd;';
      }
      case 'dark': {
        return 'color: #fff; background-color: #333;';
      }
      default: {
        return 'background-color: #ddd;';
      }
    }
  }}
`;

export const SettingsContainer = styled.div<{ backgroundcolor: 'light' | 'dark' }>`
  display: flex;
  align-items: space-between;
  height: 40px;
  width: 100%;
  font-size: 12px;
  margin-bottom: 5px;
  ${(props) => {
    switch (props.backgroundcolor) {
      case 'light': {
        return 'background-color: #ddd;';
      }
      case 'dark': {
        return 'color: #fff; background-color: #333;';
      }
      default: {
        return 'background-color: #ddd;';
      }
    }
  }}
`;

export const SettingsItem = styled.div`
  padding-left: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const NavInstruction = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const StyledSpan = styled.span`
  width: 5rem;
  padding-right: 5px;
`;

export const ImageDetails = styled.div`
  display: flex;
  justify-content: flex-end;
  font-size: 12px;
  padding-top: 4px;
`;
