import styled from 'styled-components';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import { Colors } from 'Constants';

export const Wrapper = styled.div`
  min-height: 100%;
  display: flex;
  flex-basis: 20rem;
  align-items: center;
  flex-grow: 1;
  flex-direction: column;
  background-color: #fff;
  overflow-y: auto;
  nav {
    min-width: 100%;
    margin-top: 7rem;
  }
`;

export const StyledTableRow = styled(TableRow)<any>`
  cursor: pointer;
  &:hover {
    background-color: ${Colors['neighbourlytics-pink']};
    color: #fff;
    text-decoration: underline;
    > * {
      color: #fff;
    }
  }
`;

export const StyledTextField = styled(TextField)`
  padding: 0.5rem;
  margin-left: 1.5rem;
`;
