import React, { useCallback, FC, ReactElement } from 'react';
import { useLazyQuery } from '@apollo/client';
import { DOWNLOAD_FILE } from 'Queries';
import { saveAs } from 'file-saver';
import Button from 'Components/Button';
import { Typography } from '@material-ui/core';
import { s2ab } from 'helpers';

export type MimeType = 'zip' | 'xlsx';

export type DownloadFileTypeADT =
  | { state: 'pending' }
  | { state: 'started' }
  | { state: 'failure' }
  | { state: 'success'; mimeType: MimeType }
  | { state: 'none' };

interface DownloadProps {
  taskId?: string;
  downloadType: DownloadFileTypeADT;
}

const notReadyDownloadMessage: { [key: string]: string } = {
  pending: 'Waiting for another task...',
  started: 'Preparing Download...',
  failure: 'Task not Successful',
};

const cannotDownloadStatus: { [key: string]: string } = {
  pending: 'Pending',
  started: 'Not Ready',
  failure: 'Cannot Download',
};

const DownloadFile: FC<DownloadProps> = ({
  taskId,
  downloadType,
}): ReactElement<HTMLButtonElement, any> => {
  const [download, { data }] = useLazyQuery(DOWNLOAD_FILE, {
    variables: { taskId },
    onCompleted: () => {
      handleDownload(); // eslint-disable-line
    },
  });

  const handleDownload: any = useCallback(async () => {
    const {
      downloadFile: { content, filename },
    } = data;
    const decode = atob(content);
    const ab = s2ab(decode);
    let blobType = '';
    if (downloadType.state === 'success') {
      switch (downloadType.mimeType) {
        case 'zip':
          blobType = 'application/zip';
          break;
        case 'xlsx':
          blobType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;';
          break;
      }
    }
    const blob = new Blob([ab], {
      type: blobType,
    });
    saveAs(blob, filename);
  }, [data]);

  switch (downloadType.state) {
    case 'pending':
    case 'started':
    case 'failure':
      return (
        <Typography>
          {notReadyDownloadMessage[downloadType.state]}
          <Button variant="form" disabled="disabled" color="disabled">
            {cannotDownloadStatus[downloadType.state]}
          </Button>
        </Typography>
      );
    case 'success':
      return (
        <Button onClick={() => download()} variant="form" color="primary">
          Download .{downloadType.mimeType}
        </Button>
      );
    default:
      return <></>;
  }
};

export default DownloadFile;
