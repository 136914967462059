export enum FontWeights {
  LIGHT = 300,
  BOOK = 350,
  MEDIUM = 400,
  BOLD = 700,
}

export enum FontNames {
  IBM_PLEX_SANS = 'IBMPlexSans',
  NEXA = 'Nexa',
  SOFIA_PRO = 'SofiaPro',
}

export enum FontFormats {
  TTF = 'ttf',
  WOFF = 'woff',
}

export enum Colors {
  'primary' = '#83d1da',
  'secondary' = '#d55ca2',
  'disabled' = 'lightgrey',
  'default' = 'lightsteelblue',
  'background' = '#ececec',
  'font' = '#333',
  'neighbourlytics-pink' = '#d55ca2',
  'neighourlytics-green' = '#cae2b5',
  'neighbourlytics-secondary-blue' = '#6b9bbf',
  'neighbourlytics-blue' = '#83d1da',
  'white' = '#fff',
  'grey' = '#ccc',
  'neighbourlytics-secondary-green' = '#66c3b4',
}

export const COLORS = {
  neighbourlyticsPink: '#d55ca2',
  neighourlyticsGreen: '#cae2b5',
  neighbourlyticsSecondaryBlue: '#6b9bbf',
  neighbourlyticsBlue: '#83d1da',
  primary: '#85d2db',
  white: '#fff',
};

export const MenuWidth = '20rem';

export enum CeleryStatus {
  'RECEIVED' = 'Queued',
  'STARTED' = 'Running',
  'FAILURE' = 'Failure',
  'SUCCESS' = 'Success',
  'PENDING' = 'Pending',
  'REVOKED' = 'Revoked',
  'RETRY' = 'Retry',
  'NONE' = 'none',
  'UNKNOWN' = 'Refresh Status',
}

export enum CeleryStatusColor {
  'RECEIVED' = '#6b9bbf',
  'STARTED' = '#66c3b4',
  'FAILURE' = '#d55ca2',
  'SUCCESS' = '#cae2b5',
  'PENDING' = '#6b9bbf',
  'REVOKED' = '#ccc',
  'RETRY' = '#6b9bbf',
  'UNKNOWN' = '#6b9bbf',
}

export enum CeleryStatusNLColors {
  'RECEIVED' = 'neighbourlytics-secondary-blue',
  'STARTED' = 'neighbourlytics-secondary-green',
  'FAILURE' = 'neighbourlytics-pink',
  'SUCCESS' = 'neighourlytics-green',
  'PENDING' = 'neighbourlytics-secondary-blue',
  'REVOKED' = 'grey',
  'RETRY' = 'neighbourlytics-secondary-blue',
  'UNKNOWN' = 'neighbourlytics-secondary-blue',
}

export enum CeleryTaskNames {
  'run_neighbourlytics_places' = 'Run Neighbourlytics Places',
  'exportExcelGc' = 'Export Places Golden Categories',
  'exportProductXArchive' = 'Export ProductX Archive',
  'exportProductXArchiveGroup' = 'Re-Export All PX Orders',
  'exportKml' = 'Export KML',
  'runScraper' = 'Run Scraper',
  'runPlaceQuery' = 'Run Place Query',
  'runPlaceOrderQueryNlPlaces' = 'Run Order_Areas Places Query',
  'runPlaceQueryNlPlaces' = 'Run Place Query and manage to NL Places',
  'runNeighbourlyticsPlaces' = 'Run Manage to NL Places',
  'runReviewsQuery' = 'Run Reviews Query',
}

export const GUTTERS = {
  xs: '1rem',
};

export enum ERRORS {
  'LOGIN' = 'Sorry incorrect details contact admin',
  'VALIDATE_SCRAPER_CSV' = 'The uploaded CSV is not valid for the scraper.',
  'POPULATE_SCRAPER_CSV' = 'Empty values were found for required columns within the uploaded CSV. Please populate these cells, and try again.',
}

export const SCRAPER_REQUIRED_COLUMNS = [
  'FolderID',
  'Neighbourhood',
  'SubFolders',
  'TZ_Name',
  'CAPTURE_FROM',
  'CAPTURE_TO',
  'LocationID',
  'InstagramLink',
];

export const RETRY_TASKS = ['teen_wilma.tasks.tasks.upload_instagram_images'];

export type ACCESS_TYPE =
  | '/'
  | '/import-order'
  | '/all-orders'
  | '/tasks'
  | '/tools'
  | '/export-kml';

export const ROUTE_ACCESS = {
  '/': ['user', 'tagger'],
  '/orders': ['user'],
  '/import-order': ['user'],
  '/all-orders': ['user'],
  '/tasks': ['user'],
  '/tools': ['user'],
  '/export-kml': ['user'],
  '/tagging': ['user', 'tagger', 'tagadmin'],
  '/tagging-job': ['user', 'tagger', 'tagadmin'],
  '/tagging-locations': ['user', 'tagadmin'],
  '/order-image-library': ['user', 'tagger', 'tagadmin'],
};

export enum TaggingChipStatusColors {
  'IN_PROGRESS' = '#66c3b4',
  'COMPLETED' = '#DDBC70',
  'FOR_REVIEW' = '#d55ca2',
  'IGNORED' = '#999',
  'CLEAR' = '#ccc',
}

export enum TagThumbnailColors {
  'TAGGED' = '#458A0E',
  'IGNORED' = '#E3001E',
  'FOR_REVIEW' = '#DEC20B',
  'REVIEWED' = '#F585FC',
}
