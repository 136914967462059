import { FC, useState, useEffect } from 'react';

// Functions / Queries
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { GET_ORDER_PPA_ENTRIES } from 'Queries';
import { duplicateIntCheck, missingIntCheck } from 'helpers';

// Types
import { Params } from 'Pages/Order/Order.types';

// Styling
import {
  ErrorHeader,
  ErrorMessage,
  TableWrapper,
  TableHeader,
  TableRow,
  TableCell,
  StyledWarning,
  StyledSpan,
  FlagItem,
} from './PPATable.styles';
import { COLORS } from 'Constants';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

interface PpaRecordsType {
  date_from: string;
  date_to: string;
  presence_end_date_exclusive: string;
  ppa_to_ppaoa: [
    {
      order_area_id: number;
    }
  ];
  ppa_id: number;
  chatter_enabled: boolean;
  demographics_enabled: boolean;
  isochrones_enabled: boolean;
  keywords_enabled: boolean;
  presence_enabled: boolean;
  relevance_over_time_enabled: boolean;
}

interface PpaOrderAreaIdsType {
  ppa_id: number;
  ppaoa_order_area_ids: number[];
}

const PPATable: FC = () => {
  const [ppa, setPpa] = useState<PpaRecordsType[]>([]);
  const [orderAreaIds, setOrderAreaIds] = useState<number[]>([]);
  const [missingPPAWarning, setMissingPPAWarning] = useState<string | undefined>(undefined);
  const [dupOAWarning, setDupOAWarning] = useState<string[] | undefined>(undefined);

  const { order_id }: Params = useParams();

  const { error, loading, data } = useQuery(GET_ORDER_PPA_ENTRIES, {
    variables: { order_id: `${order_id}` },
  });

  const warning = 'Warning! Below problems were found in PPA / PPAOA records:';

  useEffect(() => {
    if (loading) return;
    if (!data.process_parameter_archive[0]) {
      setMissingPPAWarning(`No PPA rows populated`);
      return;
    }

    const order_area_ids: number[] = data?.order_area.map(
      (order_area: { order_area_id: number }) => {
        return order_area.order_area_id;
      }
    );

    const checkMultiplePpa: boolean = data.process_parameter_archive.length > 1;

    const oaIdsPerPpa: PpaOrderAreaIdsType[] = data.process_parameter_archive.map(
      ({ ppa_id, ppa_to_ppaoa }: PpaRecordsType) => {
        return {
          ppa_id: ppa_id,
          ppaoa_order_area_ids: ppa_to_ppaoa.map(
            (ppa_to_ppaoa: { order_area_id: number }) => ppa_to_ppaoa.order_area_id
          ),
        };
      }
    );

    const duplicateOAList: PpaOrderAreaIdsType[] = oaIdsPerPpa
      .map(({ ppa_id, ppaoa_order_area_ids }: PpaOrderAreaIdsType) => {
        return {
          ppa_id: ppa_id,
          ppaoa_order_area_ids: duplicateIntCheck(ppaoa_order_area_ids),
        };
      })
      .filter((ppa) => ppa.ppaoa_order_area_ids.length > 0);

    if (duplicateOAList.length > 0) {
      const duplicatesWarnings: string[] = duplicateOAList.map(
        ({ ppa_id, ppaoa_order_area_ids }: PpaOrderAreaIdsType) => {
          return `For PPA_ID ${ppa_id}, duplicate Order Areas: ${ppaoa_order_area_ids
            .filter((oa: number, index: number) => ppaoa_order_area_ids.indexOf(oa) === index)
            .join(', ')} were found`;
        }
      );
      setDupOAWarning(duplicatesWarnings);
    }

    const missingOAList = missingIntCheck(
      order_area_ids,
      oaIdsPerPpa.flatMap((ppa) => ppa.ppaoa_order_area_ids)
    );

    if (missingOAList.length > 0 && checkMultiplePpa && order_area_ids.length > 1) {
      setMissingPPAWarning(`Missing Order Areas: ${missingOAList.join(', ')}`);
    }

    setPpa(data.process_parameter_archive);
    setOrderAreaIds(order_area_ids);
  }, [data]);

  if (loading) return null;

  const completePpaRecord = (order_area_ids: string) => {
    return (
      <div>
        <StyledSpan>All</StyledSpan>
        <span>{' - ' + order_area_ids}</span>
      </div>
    );
  };

  return (
    <>
      <TableWrapper>
        <TableHeader>
          <TableCell>Date From</TableCell>
          <TableCell>Date To</TableCell>
          <TableCell>Order Area IDs</TableCell>
          <TableCell>Presence Export</TableCell>
          <TableCell>Flags</TableCell>
        </TableHeader>
        {!error &&
          ppa.map(
            ({
              ppa_id,
              date_from,
              date_to,
              ppa_to_ppaoa,
              presence_end_date_exclusive,
              chatter_enabled,
              demographics_enabled,
              isochrones_enabled,
              keywords_enabled,
              presence_enabled,
              relevance_over_time_enabled,
            }: PpaRecordsType) => {
              const ppaoa_oas_per_ppaid = ppa_to_ppaoa.map(
                (order_area_id: { order_area_id: number }) => order_area_id.order_area_id
              );

              const enabledFlags: {
                name: string;
                label: string;
                checked: boolean;
              }[] = [
                {
                  name: 'isochronesEnabled',
                  label: 'Isochrones',
                  checked: isochrones_enabled,
                },
                {
                  name: 'demographicsEnabled',
                  label: 'Demographics',
                  checked: demographics_enabled,
                },
                {
                  name: 'relevanceOverTimeEnabled',
                  label: 'Relevance Over Time',
                  checked: relevance_over_time_enabled,
                },

                {
                  name: 'chatterEnabled',
                  label: 'Chatter',
                  checked: chatter_enabled,
                },
                {
                  name: 'presenceEnabled',
                  label: 'Presence',
                  checked: presence_enabled,
                },
                {
                  name: 'keywordsEnabled',
                  label: 'Keywords',
                  checked: keywords_enabled,
                },
              ];

              return (
                <TableRow key={ppa_id}>
                  <TableCell>{date_from}</TableCell>
                  <TableCell>{date_to}</TableCell>
                  <TableCell>
                    {ppa_to_ppaoa.length > 0 ? (
                      orderAreaIds.every((val, index) => val === ppaoa_oas_per_ppaid[index]) ? (
                        completePpaRecord(`${ppaoa_oas_per_ppaid.join(', ')}`)
                      ) : (
                        ppaoa_oas_per_ppaid.join(', ')
                      )
                    ) : (
                      <>
                        <StyledWarning>PPA exists but no PPAOA records found!</StyledWarning>
                        <StyledWarning>All order_area_ids will be reexported</StyledWarning>
                      </>
                    )}
                  </TableCell>
                  <TableCell>{presence_end_date_exclusive}</TableCell>
                  <TableCell>
                    {enabledFlags.map((flag: { name: string; label: string; checked: boolean }) => {
                      return (
                        <FlagItem key={flag.name}>
                          {flag.checked ? (
                            <CheckCircleIcon
                              htmlColor={COLORS.neighourlyticsGreen}
                              fontSize="small"
                            />
                          ) : (
                            <RadioButtonUncheckedIcon color="disabled" fontSize="small" />
                          )}
                          <span>{flag.label}</span>
                        </FlagItem>
                      );
                    })}
                  </TableCell>
                </TableRow>
              );
            }
          )}
      </TableWrapper>
      <ErrorHeader>
        {(missingPPAWarning && warning) || (dupOAWarning && warning)}
        {missingPPAWarning && <ErrorMessage>{missingPPAWarning}</ErrorMessage>}
        {dupOAWarning && <ErrorMessage>{dupOAWarning}</ErrorMessage>}
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </ErrorHeader>
    </>
  );
};

export default PPATable;
