export interface LocationType {
  date_of_creation: string;
  geometry: {
    coordinates: number[];
    crs: {
      properties: {
        name: string;
      };
      type: string;
    };
    type: string;
  };
  location_id: number;
  name: string;
  polygon: string | null;
  postcode: string;
  radius: number;
}

export interface OrderLocation {
  location: LocationType;
}

export const apiStringValues = ['DummyPlaceApi', 'GooglePlaceApi'] as const;
export type ApiStringType = typeof apiStringValues[number];

export const granularityValues = ['Progressive', 'Fixed by location'] as const;
export type GranularityType = typeof granularityValues[number];

export interface OrderArea {
  name: string;
  order_area_id: number;
  wilson_area_id: number;
  geometry: any;
}

export interface ChatterCollection {
  chatter_collection_id: number;
  order: {
    order_id: number;
    name: string;
  };
  date_from: string;
  date_to: string;
  chatter_locations: ChatterLocation[];
}

export interface ChatterLocation {
  chatter_location_id: number;
  source_location_identifier: string;
  source_link: string;
  timezone: string;
  description: string;
  chatter_images_aggregate: {
    aggregate: {
      count: number;
    };
  };
  order_area_to_chatter_locations: [
    {
      order_area_id: number;
    }
  ];
  collection_timeframe: {
    date_from: string;
    date_to: string;
  };
}

export interface OrderLocationValues {
  order: {
    name: string;
    order_areas: OrderArea[];
    order_id: number;
    order_no: number;
    orderlocationmaps: {
      location: LocationType;
    };
    requested_by: string;
  };
}

export interface Params {
  order_id: string;
}

export interface OrderProps {
  name?: string;
  order_id: string | number;
  order_no: number;
  requested_by: string;
  date_of_creation: string;
  order_areas: [
    {
      order_area_id: number;
    }
  ];
  oa_agg?: OAAggregate;
  ppa_agg?: PPAAggregate;
  ppaCheck?: boolean;
}

export interface OAAggregate {
  aggregate: {
    count: number;
  };
  nodes: [
    {
      ppa_oa_agg: {
        aggregate: {
          count: number;
        };
      };
    }
  ];
}

export interface PPAAggregate {
  aggregate: {
    count: number;
  };
  nodes: PPANodes[];
}

export interface PPANodes {
  ppa_to_ppaoa: {
    order_area_id: number;
  };
  ppa_to_ppaoa_agg: {
    aggregate: {
      count: number;
    };
  };
}
