import styled from 'styled-components';
import Button from 'Components/Button';

export const StyledFormButton = styled(Button)`
  margin: 1rem 0;

  &:disabled {
    background-color: lightgrey;
  }
`;
