import React, { FC, ChangeEvent, useState } from 'react';
import { ApolloError, useMutation } from '@apollo/client';
import { saveAs } from 'file-saver';
import { EXPORT_KML } from 'Queries';
import { s2ab } from 'helpers';

import { Typography, TextField } from '@material-ui/core';

import CardBasic from 'Components/CardBasic';
import Error from 'Components/Error';
import Button from 'Components/Button';
import Loader from 'Components/Loader';
import LatLonSelector from 'Components/LatLonSelector';
import LatLonList from 'Components/LatLonSelector/LatLonList';
import LatLonCsvImport from 'Components/LatLonSelector/LatLonCsvImport';
import VerticalSpacer from 'Components/Spacer';

import { LocationSelection } from './ExportKml.types';

const ExportKml: FC = () => {
  const [error, setError] = useState<string | undefined>(undefined);
  const [queryError, setQueryError] = useState<string | undefined>(undefined);
  const [locationsList, setLocationsList] = useState<LocationSelection[]>([]);
  const [filenameInput, setFilename] = useState<string>('Selected Locations');

  const [downloadLocList, { loading }] = useMutation(EXPORT_KML, {
    variables: { locList: JSON.stringify(locationsList) },
    onCompleted: (data) => {
      const filename = `${filenameInput.replace(/ /g, '_')}.kml`;
      handleDownload(data, filename);
    },
    onError: (error: ApolloError) => {
      setQueryError(error.message);
    },
  });

  const handleFilenameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFilename(event.target.value);
  };

  const handleDownload = (data: any, filename: string) => {
    if (!data) return;
    const {
      exportKml: { content },
    } = data;
    const decode = atob(content);
    const ab = s2ab(decode);
    const blobType = 'application/vnd.google-earth.kml+xml';
    const blob = new Blob([ab], {
      type: blobType,
    });
    saveAs(blob, filename);
  };

  return (
    <>
      <CardBasic elevation={0}>
        <Typography variant="h5" component="h2" align="center" style={{ padding: 8 }}>
          Export Location Geometries as KML File
        </Typography>
      </CardBasic>
      <CardBasic elevation={0}>
        <LatLonCsvImport
          locationsList={locationsList}
          setLocationsList={setLocationsList}
          setError={setError}
          disabled={loading}
        />
        <LatLonList
          locationsList={locationsList}
          setLocationsList={setLocationsList}
          disabled={loading}
        />
        <LatLonSelector
          locationsList={locationsList}
          setLocationsList={setLocationsList}
          disabled={loading}
        />
        <TextField
          id="input-filename"
          type="text"
          label="filename"
          name="input-filename"
          value={filenameInput || ''}
          onChange={handleFilenameChange}
          required
        />
        <VerticalSpacer />
        {!queryError && (
          <Button
            onClick={locationsList.length > 0 ? downloadLocList : null}
            variant="form"
            color={locationsList.length > 0 ? 'primary' : 'disabled'}
          >
            {!loading && <p>Download {filenameInput.replace(/ /g, '_')}.kml</p>}
            {loading && <Loader color="white" />}
          </Button>
        )}
        {queryError && <Error error={{ message: queryError }} />}
        {error && <Error error={{ message: error }} />}
      </CardBasic>
    </>
  );
};

export default ExportKml;
