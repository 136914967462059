import { FC, useState, SetStateAction } from 'react';

import { GET_CHATTER_LOCATIONS_BY_COLLECTION } from 'Queries';
import { useQuery } from '@apollo/client';

// Components
import TaggingRows from './TaggingRows/TaggingRows';
import { Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import TaggingJobListHeader from './TaggingJobListHeader';
import Button from 'Components/Button';
import OrderTaggingNewJob from './OrderTaggingNewJob';

import { OrderTaggingRowsProps } from './TaggingJobList.types';
import { ChatterCollection, ChatterLocation } from 'Pages/Order/Order.types';

import {
  StyledTable,
  CollectionHeader,
  LocationHeader,
  StyledHeaderCell,
  StyledLocationHeaderCell,
} from './TaggingJobList.styles';

const locationIDsMatch = (
  arrA: { chatter_location_id: number },
  arrB: { chatter_location_id: number }
) => {
  return arrA.chatter_location_id === arrB.chatter_location_id;
};

const OrderTaggingRows: FC<OrderTaggingRowsProps> = ({
  order_id,
  data,
  doDataUpdate,
  canEditJob,
  editingJob,
  setEditingJob,
}) => {
  const [chatterCollections, setChatterCollections] = useState<ChatterCollection[]>([]);
  const [addJob, setAddJob] = useState<ChatterLocation | undefined>(undefined);

  const { loading } = useQuery(GET_CHATTER_LOCATIONS_BY_COLLECTION, {
    variables: { order_id: `${order_id}` },
    onCompleted: (d) => {
      setChatterCollections(d.chatter_collections);
    },
  });

  if (loading) return <div>Loading ...</div>;

  const handleNewJob = () => {
    setAddJob(undefined);
    doDataUpdate();
  };

  const collectionDetails = (collection: ChatterCollection) => {
    return (
      <Table size="small">
        <CollectionHeader>
          <TableRow>
            <StyledHeaderCell>Collection ID</StyledHeaderCell>
            <TableCell>{collection.chatter_collection_id}</TableCell>
            <StyledHeaderCell>Collection Timeframe</StyledHeaderCell>
            <TableCell>
              {collection.date_from.replace('T00:00:00', '')}
              {' - '}
              {collection.date_to.replace('T00:00:00', '')}
            </TableCell>
          </TableRow>
        </CollectionHeader>
      </Table>
    );
  };

  const locationHeaderDetails = (location: ChatterLocation) => {
    const chatterImagesCount = location.chatter_images_aggregate.aggregate.count;
    return (
      <LocationHeader>
        <TableRow>
          <StyledLocationHeaderCell>Source ID</StyledLocationHeaderCell>
          <StyledLocationHeaderCell colSpan={2} padding="none">
            Location Images
          </StyledLocationHeaderCell>
          <StyledLocationHeaderCell colSpan={5} padding="none">
            Location Description
          </StyledLocationHeaderCell>
          <StyledLocationHeaderCell>Tools</StyledLocationHeaderCell>
        </TableRow>
        <TableRow>
          <TableCell>
            {
              <a href={location.source_link} target="blank">
                {location.source_location_identifier}
              </a>
            }
          </TableCell>
          <TableCell colSpan={2} padding="none">
            {chatterImagesCount}
          </TableCell>
          <TableCell colSpan={5} padding="none">
            {location.description}
          </TableCell>
          <TableCell>
            <Button
              variant="form"
              color="primary"
              disabled={chatterImagesCount <= 0}
              onClick={() => {
                setAddJob(location);
              }}
            >
              Create Job
            </Button>
          </TableCell>
        </TableRow>
      </LocationHeader>
    );
  };

  const chatterLocationJobs = (chatterLocations: ChatterLocation[]) => {
    return chatterLocations.map((location: ChatterLocation) => {
      const locationsWithJobs = data.filter((job) =>
        locationIDsMatch(job.chatter_location, location)
      );
      return (
        <StyledTable size="small" key={location.chatter_location_id}>
          {locationHeaderDetails(location)}
          {addJob && locationIDsMatch(addJob, location) && (
            <OrderTaggingNewJob chatterLocation={addJob} refreshJobs={handleNewJob} />
          )}
          {locationsWithJobs.length > 0 && (
            <>
              <TaggingJobListHeader />
              <TableBody key={location.chatter_location_id}>
                <TaggingRows
                  data={locationsWithJobs}
                  doDataUpdate={doDataUpdate}
                  canEditJob={canEditJob}
                  editingJob={editingJob}
                  setEditingJob={setEditingJob}
                />
              </TableBody>
            </>
          )}
        </StyledTable>
      );
    });
  };

  return (
    <>
      {chatterCollections.map((collection: ChatterCollection) => {
        return (
          <div key={collection.chatter_collection_id}>
            {collectionDetails(collection)}
            {collection.chatter_locations.length > 0 ? (
              chatterLocationJobs(collection.chatter_locations)
            ) : (
              <div>No Locations for Collection</div>
            )}
          </div>
        );
      })}
    </>
  );
};

export default OrderTaggingRows;
