import { SetStateAction, useState, FC } from 'react';
import { useMutation } from '@apollo/client';
import { AD_HOC_EXECUTE_TASK } from 'Queries';
import { ExecuteButton } from 'Pages/Tasks/AdHocExecuteTask.styles';

const AdHocExecuteTask: FC<{ orderId?: string }> = ({ orderId }) => {
  const [taskName, setTaskName] = useState('');
  const [queue, setQueue] = useState('');
  const [args, setArgs] = useState('');
  const [executeTask, { data, loading, error }] = useMutation(AD_HOC_EXECUTE_TASK);

  const handleExecute = () => {
    executeTask({
      variables: {
        taskName: taskName,
        queue,
        arguments: JSON.stringify(args),
        orderId: orderId ?? 0,
      },
    });
  };

  return (
    <div>
      <div>
        <div>
          Ad hoc task exec
          <input
            placeholder="Task Name"
            value={taskName}
            onChange={(e: { target: { value: SetStateAction<string> } }) =>
              setTaskName(e.target.value)
            }
          />
          <input
            placeholder="Queue"
            value={queue}
            onChange={(e: { target: { value: SetStateAction<string> } }) =>
              setQueue(e.target.value)
            }
          />
          <input
            placeholder="Arguments (JSON)"
            value={args}
            onChange={(e: { target: { value: SetStateAction<string> } }) => setArgs(e.target.value)}
          />
          <ExecuteButton onClick={handleExecute} disabled={loading}>
            Execute
          </ExecuteButton>
        </div>

        {data?.adHocExecuteTask && (
          <div>
            <p>Task ID: {data.adHocExecuteTask.taskId}</p>
            <p>Response: {data.adHocExecuteTask.response}</p>
          </div>
        )}
        {error && <p>Error executing task: {error.message}</p>}
      </div>
    </div>
  );
};

export default AdHocExecuteTask;
