import { FC } from 'react';
import styled from 'styled-components';
import { Errors } from './Error.types';
import { useAuth } from 'Providers';

export const StyledError = styled.span`
  display: flex;
  align-items: center;
  color: red;
  min-width: 100%;
`;

const Error: FC<Errors> = ({ error }) => {
  const { signout } = useAuth();

  if (!error) return null;

  let message;
  if (Array.isArray(error)) message = { message } = error[0];
  else message = error.message;

  if (!message) message = 'There was an error with no message';

  return (
    <StyledError aria-live="polite">
      <span>{`${message}`}</span>
    </StyledError>
  );
};

export default Error;
