import { FC, useState, useEffect } from 'react';

// Functions
import { useLazyQuery } from '@apollo/client';

// Queries
import { GET_TAGGING_JOBS, GET_ORDER_TAGGING_JOBS, GET_TAGGING_JOBS_FOR_TAGGER } from 'Queries';

import { useUsers } from 'Providers/UserProvider';

// Components
import { Table, TableBody, TableContainer, Paper, Box, CircularProgress } from '@material-ui/core';
import TaggingJobListHeader from './TaggingJobListHeader';
import TaggingRows from './TaggingRows/TaggingRows';
import OrderTaggingRows from './OrderTaggingRows';

import { TaggingJobQueryData, TaggingJobQuery } from './TaggingJobList.types';

import { TableHeading } from './TaggingJobList.styles';

interface TaggingJobProps {
  order_id?: number;
}

const TaggingJobs: FC<TaggingJobProps> = ({ order_id }) => {
  const { loggedInUser } = useUsers();

  const loggedInRoles = loggedInUser?.roles || [];
  const isTagAdmin = loggedInRoles.some((r) => ['user', 'tagadmin'].includes(r));

  const [data, setData] = useState<TaggingJobQuery[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');

  const [editingJob, setEditingJob] = useState<number>(-1);

  const orderIdProvided: boolean = order_id !== undefined;

  const [getAllTaggingJobs] = useLazyQuery<TaggingJobQueryData>(
    orderIdProvided ? GET_ORDER_TAGGING_JOBS : GET_TAGGING_JOBS,
    {
      variables: { order_id: order_id },
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        setData(res.tagging_job);
        setIsLoading(false);
      },
      onError: (res) => {
        setIsLoading(false);
        setError(res.message);
      },
    }
  );

  const [getTaggingJobsByUser] = useLazyQuery<TaggingJobQueryData>(GET_TAGGING_JOBS_FOR_TAGGER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setData(res.tagging_job);
      setIsLoading(false);
    },
    onError: (res) => {
      setIsLoading(false);
      setError(res.message);
    },
  });

  const doDataUpdate = () => {
    setIsLoading(true);

    if (!loggedInUser) {
      setIsLoading(false);
      return;
    } else if (isTagAdmin) getAllTaggingJobs();
    else
      getTaggingJobsByUser({
        variables: {
          user_id: loggedInUser.firebase_user_id,
        },
      });
  };

  useEffect(() => {
    if (loggedInUser) doDataUpdate();
  }, [loggedInUser]);

  return (
    <TableContainer component={Paper}>
      <TableHeading>List of Tagging Jobs</TableHeading>
      <>
        {orderIdProvided ? (
          <OrderTaggingRows
            order_id={order_id!}
            data={data}
            doDataUpdate={doDataUpdate}
            canEditJob={isTagAdmin}
            editingJob={editingJob}
            setEditingJob={setEditingJob}
          />
        ) : (
          <Table aria-label="List of Tagging Jobs">
            <TaggingJobListHeader displayOrderDetails={!orderIdProvided} />
            <TableBody>
              <TaggingRows
                data={data}
                doDataUpdate={doDataUpdate}
                canEditJob={isTagAdmin}
                diplayOrderDetails={!orderIdProvided}
                editingJob={editingJob}
                setEditingJob={setEditingJob}
              />
            </TableBody>
          </Table>
        )}
      </>
      {
        <Box display="flex" justifyContent="center" margin="2rem">
          {isLoading ? <CircularProgress /> : data.length <= 0 && 'There are no jobs to display.'}
          {error && <p>error</p>}
        </Box>
      }
    </TableContainer>
  );
};

export default TaggingJobs;
