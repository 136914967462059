import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const NAV_HEIGHT = 7.2;
export const ITEM_HEIGHT = 5.6;

export const Nav = styled.nav`
  display: block;
  height: ${NAV_HEIGHT}rem;
`;

export const NavHeader = styled.div`
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  background-color: #fff;
  width: 100%;
  border-bottom: 1px solid #ccc;
`;
export const Container = styled.div`
  display: flex;
  align-items: center;
  height: ${NAV_HEIGHT}rem;
`;
export const LinkLogo = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${ITEM_HEIGHT}rem;
  padding-left: 2.4rem;
  padding-right: 2.4rem;
  text-decoration: none;
  margin-left: 4rem;
`;
/* export const Logo = styled(NeighbourlyticsLogo)<HTMLOrSVGElement>`
  width: 14.4rem;
  height: 2.4rem;
  color: #333;
`; */
export const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: 2.4rem;
  padding-right: 2.4rem;
  flex-grow: 1;
  > * {
    margin: 0.5rem;
  }
`;
