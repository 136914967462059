import styled from 'styled-components';
import { GUTTERS } from 'Constants';

/** Set Spacing as a constant or theme */
const Spacer = styled.div`
  border-radius: 0;
  padding-top: ${GUTTERS.xs};
  padding-bottom: ${GUTTERS.xs};
  padding-left: ${GUTTERS.xs};
  padding-right: ${GUTTERS.xs};
  > * + * {
    margin-bottom: ${GUTTERS.xs};
  }
`;

export default Spacer;
