import { createTheme } from '@material-ui/core/styles';
import { Colors } from 'Constants';

export const defaultMuiTheme = createTheme({
  typography: {
    htmlFontSize: 10,
    fontSize: 14,
    fontFamily: ['"Sofia Pro"', 'Nexa', 'Roboto', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(
      ','
    ),
  },
  spacing: 1,
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  palette: {
    primary: {
      main: Colors.primary,
      contrastText: '#fff',
    },
    secondary: {
      main: Colors.secondary,
      contrastText: '#fff',
    },
  },
});
