import styled from 'styled-components';
import { FormControl } from '@material-ui/core';

export const TableHeading = styled.h1`
  font-size: 20px;
  padding: 1rem;
`;

export const FormContainer = styled.div`
  margin: 0 1rem 1rem 1rem;
`;

export const FormColumn = styled.div`
  margin: 1rem; 0;
`;

export const StyledFormControl = styled(FormControl)`
  margin-bottom: 2rem;
`;

export const StyledStaticFormContent = styled.div`
  margin-top: 1.4rem;
`;
