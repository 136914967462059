import { FC } from 'react';
import { TableHead, TableRow, TableCell } from '@material-ui/core';

interface TableHeaderProps {
  displayOrderDetails?: boolean;
}

const TableHeader: FC<TableHeaderProps> = ({ displayOrderDetails }) => {
  return (
    <TableHead>
      <TableRow>
        {displayOrderDetails && (
          <>
            <TableCell>
              <h4>IDs</h4>
            </TableCell>
            <TableCell padding="none">
              <h4>Order Name</h4>
            </TableCell>
          </>
        )}
        <TableCell>
          <h4>Source ID</h4>
        </TableCell>
        <TableCell padding="none">
          <h4>Images</h4>
        </TableCell>
        <TableCell>
          <h4>Creator</h4>
        </TableCell>
        <TableCell padding="none">
          <h4>Notes</h4>
        </TableCell>
        <TableCell>
          <h4>Date of Creation</h4>
        </TableCell>
        <TableCell>
          <h4>Tagger Name</h4>
        </TableCell>
        <TableCell>
          <h4>Job Images</h4>
        </TableCell>
        <TableCell>
          <h4>Status</h4>
        </TableCell>
        <TableCell>
          <h4>Tools</h4>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
