import { FC } from 'react';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { ApolloProvider } from 'libs';

// Providers
import { OrderWrapper } from 'Providers';
import { AuthProvider, UserProvider, Toasty, ThemeProvider, BartThemeProvider } from 'Providers';

import Login from 'Pages/Login';
import Main from 'Pages/Main';
import Menu from 'Components/Menu';
import Header from 'Components/Header';
import PrivateRoute from 'Components/PrivateRoute';

import GlobalStyle from 'Components/GlobalStyles';

const App: FC = () => {
  return (
    <AuthProvider>
      <ApolloProvider>
        <UserProvider>
          <CookiesProvider>
            <ThemeProvider>
              <BartThemeProvider>
                <GlobalStyle />
                <Toasty />
                <Router>
                  <Header />
                  <Switch>
                    <Route path="/login">
                      <Login />
                    </Route>
                    <OrderWrapper>
                      <PrivateRoute path="/">
                        <>
                          <Menu />
                          <Main />
                        </>
                      </PrivateRoute>
                    </OrderWrapper>
                  </Switch>
                </Router>
              </BartThemeProvider>
            </ThemeProvider>
          </CookiesProvider>
        </UserProvider>
      </ApolloProvider>
    </AuthProvider>
  );
};

export default App;
