import { FC, FormEvent, useContext } from 'react';

import Error from 'Components/Error';
import { Errors } from 'Components/Error/Error.types';
import { ThemeContext } from 'Providers/ThemeProvider';

// Styles
import { StyledFormButton } from './SubmitJob.styles';
import { Grid, Typography, CircularProgress } from '@material-ui/core';

interface SubmitJobProps {
  taskClickAction: (event: FormEvent<HTMLFormElement>) => void;
  taskId: string | undefined;
  taskName: string;
  errors: Errors | string[] | undefined;
  loading: boolean;
  disabled: boolean;
}

const SubmitJob: FC<SubmitJobProps> = ({
  taskClickAction,
  taskId,
  taskName,
  errors,
  loading,
  disabled,
}: SubmitJobProps) => {
  const colorContext = useContext(ThemeContext).color;
  return (
    <>
      <StyledFormButton
        onClick={taskClickAction}
        type="submit"
        variant="form"
        color={loading || disabled ? 'grey' : colorContext}
        disabled={loading || disabled}
      >
        {loading && <CircularProgress size={14} color="inherit" />}
        {!loading && <span>{`Run ${taskName}`}</span>}
      </StyledFormButton>
      {errors && <Error error={errors as { message?: string | undefined }} />}
      {taskId && (
        <Grid item xs={12} style={{ marginTop: 16 }}>
          <Typography component="h5" gutterBottom>
            {`Your ${taskName} is now running! Please check the Tasks List to see when
            it's complete.`}
          </Typography>
          <Typography component="h6" variant="caption" gutterBottom>
            Task ID: {taskId}
          </Typography>
        </Grid>
      )}
    </>
  );
};

export default SubmitJob;
