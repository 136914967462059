import { FC } from 'react';
import { Grid } from '@material-ui/core';
import CardBasic from 'Components/CardBasic';
import VerticalSpacer from 'Components/Spacer';

export interface LocationsProps {
  location_id: number;
  name: string;
  geometry: {
    coordinates: number[];
  };
}
/**
 *
 * @param param0
 * Consider this to be a list rather than cards
 */
const Locations: FC<LocationsProps> = ({ location_id, name, geometry }) => {
  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid item key={location_id} xs={12}>
        <VerticalSpacer>
          <CardBasic elevation={0}>
            <h3>{`${name}`}</h3>
            <p>
              <b>Location ID: </b>
              {location_id}
            </p>
            <p>
              <b>Latitude: </b>
              {geometry.coordinates[1]}
            </p>
            <p>
              <b>Longitude: </b>
              {geometry.coordinates[0]}
            </p>
          </CardBasic>
        </VerticalSpacer>
      </Grid>
    </Grid>
  );
};

export default Locations;
