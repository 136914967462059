import { InMemoryCache, makeVar, ReactiveVar } from '@apollo/client';
import { TaskResult } from './Cache.types';
import { CeleryStatus } from 'Constants';

export const taskResultVar: ReactiveVar<TaskResult> = makeVar<TaskResult>({
  taskId: '',
  result: '',
  status: CeleryStatus.NONE,
  step: 0,
  date_done: '',
  id: '',
});

export const modalStatus: ReactiveVar<{ open: boolean }> = makeVar<{ open: boolean }>({
  open: false,
});

export const myVar = makeVar({});

export const cache: InMemoryCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        taskResult: {
          read() {
            return taskResultVar();
          },
        },
      },
    },
  },
});
