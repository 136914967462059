import { FC, useState, SetStateAction, ChangeEvent, SyntheticEvent } from 'react';
import { Checkbox, FormControl, FormLabel, TextField, Box, TableRow } from '@material-ui/core';

// Providers
import { useScraperContext } from 'Providers/ScraperProvider/ScraperProvider';

// Components
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import Button from 'Components/Button';
import VerticalSpacer from 'Components/Spacer';
import DateSelector from 'Components/DateSelector';

// Types
import { ChatterCollection, ChatterLocation, OrderArea } from 'Pages/Order/Order.types';
import { NewChatterCollection, NewChatterLocation } from '../ChatterScraper.types';

// Styling
import {
  CollectionHeader,
  CollectionRow,
  StyledTableWrapper,
  StyledTableHeader,
  StyledTableRow,
  StyledTableCell,
  OrderAreaDropdown,
  NewItemRow,
  CreateNewRow,
  OrderAreaPill,
} from './ScrapeChatterForm.styles';

// Helpers
import { parseMUIDate } from 'helpers';

interface FormProps {
  orderAreas: OrderArea[];
  submitNewCollection: (value: NewChatterCollection) => void;
  submitNewLocation: (value: NewChatterLocation) => void;
  disabled: boolean;
}

const maxHeight = {
  maxHeight: '40px',
};

const collectionLocationIds = (chatterCollection: ChatterCollection) => {
  // Return an array of all chatter_location_ids found for the chatter_collection_id
  return chatterCollection.chatter_locations.flatMap((location) => location.chatter_location_id);
};

const confirmAllLocsSelected = (
  collection: ChatterCollection,
  selectedLocations: ChatterLocation[]
): boolean => {
  // Check if every chatter_location_id of the chatter_collection_id exists in selectedLocations
  if (
    collectionLocationIds(collection).length > 0 &&
    collectionLocationIds(collection).every((chatter_location_id: number) =>
      selectedLocations
        .map((location) => {
          return location.chatter_location_id;
        })
        .includes(chatter_location_id)
    )
  ) {
    return true;
  }
  return false;
};

const onCheckboxChange = (
  chatterCollections: ChatterCollection[],
  selectedLocations: ChatterLocation[],
  setSelectedLocations: (value: SetStateAction<ChatterLocation[]>) => void,
  change?: 'all' | 'none' | number | number[]
) => {
  const chatterLocations = chatterCollections.flatMap(
    ({ chatter_locations }: ChatterCollection) => {
      return chatter_locations;
    }
  );

  let newScrapeLocs: ChatterLocation[] = [];
  if (change === 'all') newScrapeLocs = chatterLocations;
  if (typeof change === 'number' || typeof change === 'object') {
    newScrapeLocs = [...selectedLocations];
    if (typeof change === 'number') {
      change = [change];
    }
    const filteredIds = change;

    const locsAlreadySelected = newScrapeLocs.filter((location) =>
      filteredIds.includes(location.chatter_location_id)
    );

    const locsUnselected = chatterLocations.filter((location) =>
      filteredIds.includes(location.chatter_location_id)
    );

    if (locsAlreadySelected.length < 1) {
      newScrapeLocs = [selectedLocations, locsUnselected].flatMap(
        (location): ChatterLocation[] => location
      );
    }
    if (locsAlreadySelected.length > 0) {
      newScrapeLocs = newScrapeLocs.filter((location) => !locsAlreadySelected.includes(location));
    }
  }
  setSelectedLocations(newScrapeLocs);
};

const ScrapeChatterForm: FC<FormProps> = ({
  orderAreas,
  submitNewCollection,
  submitNewLocation,
  disabled,
}) => {
  //New Chatter Collection Form Variables
  const [enableNewCollection, setEnableNewCollection] = useState<boolean>(false);
  const [selectedFromDate, setSelectedFromDate] = useState<MaterialUiPickersDate | null>(null);
  const [selectedToDate, setSelectedToDate] = useState<MaterialUiPickersDate | null>(null);

  //New Chatter Location Form Variables
  const [enableJobForId, setEnableJobForId] = useState<number>(0);
  const [locationName, setLocationName] = useState<string>('');
  const [sourceId, setSourceId] = useState<number>(0);
  const [selectedOAIds, setSelectedOAIds] = useState<number[]>([]);
  const [timezone, setTimezone] = useState<string>('Australia/Melbourne');

  const { chatterCollections, selectedLocations, setSelectedLocations } = useScraperContext();

  const isLocationFormComplete = () => {
    // Validate Chatter Collection ID
    if (!enableJobForId || enableJobForId < 1) {
      return false;
    }
    // Validate Location Name
    if (!locationName || locationName.length < 1) {
      return false;
    }
    // Validate Source ID
    if (!sourceId || sourceId < 1) {
      return false;
    }
    // Validate Timezone
    if (!timezone || timezone.length < 1 || !timezone.includes('/')) {
      return false;
    }
    // Validate Order Area Ids
    if (!selectedOAIds || selectedOAIds.length < 1) {
      return false;
    }
    return true;
  };

  const isCollectionFormComplete = () => {
    // Validate Dates
    if (
      !selectedFromDate ||
      !selectedToDate ||
      !selectedFromDate === null ||
      !selectedToDate === null
    ) {
      return false;
    }
    return true;
  };

  const handleLocationNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setLocationName(event.target.value);
  };

  const handleSourceIdChange = (event: ChangeEvent<HTMLInputElement>) => {
    const sourceId: number = parseInt(event.target.value);
    setSourceId(sourceId);
  };

  const handleIdSelectionChange = (event: ChangeEvent<HTMLInputElement>) => {
    const changedOaId: number = parseInt(event.target.value);
    const index = selectedOAIds.indexOf(changedOaId);
    if (index < 0) {
      setSelectedOAIds(selectedOAIds.concat([changedOaId]));
    } else {
      setSelectedOAIds(selectedOAIds.filter((oa) => oa !== changedOaId));
    }
  };

  const handleTimezoneChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTimezone(event.target.value);
  };

  const onNewCollectionClose = () => {
    setEnableNewCollection(false);
    setSelectedFromDate(null);
    setSelectedToDate(null);
  };

  const onNewLocationClose = () => {
    setEnableJobForId(0);
    setLocationName('');
    setSourceId(0);
    setSelectedOAIds([]);
    setTimezone('Australia/Melbourne');
  };

  const onCollectionSaveClick = (e: SyntheticEvent) => {
    e.preventDefault();

    if (!isCollectionFormComplete()) {
    } else {
      const newCollectionParams: NewChatterCollection = {
        date_from: parseMUIDate(selectedFromDate),
        date_to: parseMUIDate(selectedToDate),
      };

      submitNewCollection(newCollectionParams);
      onNewCollectionClose();
    }
  };

  const onLocationSaveClick = (e: SyntheticEvent) => {
    e.preventDefault();

    if (!isLocationFormComplete()) {
    } else {
      const newLocation: NewChatterLocation = {
        chatter_collection_id: enableJobForId,
        timezone: timezone,
        source_location_identifier: `${sourceId}`,
        source_link: `/${sourceId}`,
        description: locationName,
        orderAreaIds: selectedOAIds,
      };

      submitNewLocation(newLocation);
      onNewLocationClose();
    }
  };

  const chatterCollectionsTable = (collection: ChatterCollection, locationsCheck: boolean) => {
    return (
      <CollectionHeader>
        <StyledTableHeader>
          <StyledTableCell>Select All Locations</StyledTableCell>
          <StyledTableCell>Collection ID</StyledTableCell>
          <StyledTableCell>Date From</StyledTableCell>
          <StyledTableCell>Date To</StyledTableCell>
        </StyledTableHeader>
        <CollectionRow
          disabled={!locationsCheck}
          onClick={() => {
            !disabled &&
              locationsCheck &&
              onCheckboxChange(
                chatterCollections,
                selectedLocations,
                setSelectedLocations,
                collectionLocationIds(collection)
              );
          }}
        >
          <StyledTableCell>
            <FormLabel key={collection.chatter_collection_id}>
              <Checkbox
                name="areas"
                color="secondary"
                value={collection.chatter_collection_id}
                style={maxHeight}
                disabled={disabled}
                checked={confirmAllLocsSelected(collection, selectedLocations)}
                onChange={() => {
                  onCheckboxChange(
                    chatterCollections,
                    selectedLocations,
                    setSelectedLocations,
                    collectionLocationIds(collection)
                  );
                }}
              />
            </FormLabel>
          </StyledTableCell>
          <StyledTableCell>{collection.chatter_collection_id}</StyledTableCell>
          <StyledTableCell>{parseMUIDate(new Date(collection.date_from))}</StyledTableCell>
          <StyledTableCell>{parseMUIDate(new Date(collection.date_to))}</StyledTableCell>
        </CollectionRow>
      </CollectionHeader>
    );
  };

  const chatterLocationsTable = (chatter_locations: ChatterLocation[]) => {
    return (
      <StyledTableWrapper>
        <StyledTableHeader>
          <StyledTableCell>Scrape</StyledTableCell>
          <StyledTableCell>Location ID</StyledTableCell>
          <StyledTableCell>Location</StyledTableCell>
          <StyledTableCell>Source ID</StyledTableCell>
          <StyledTableCell>Order Areas</StyledTableCell>
          <StyledTableCell>Collected Images</StyledTableCell>
          <StyledTableCell>Timezone</StyledTableCell>
        </StyledTableHeader>

        {chatter_locations.map(
          ({
            chatter_location_id,
            source_location_identifier,
            order_area_to_chatter_locations,
            timezone,
            description,
            chatter_images_aggregate,
          }) => {
            return (
              <StyledTableRow
                key={chatter_location_id}
                onClick={() => {
                  !disabled &&
                    onCheckboxChange(
                      chatterCollections,
                      selectedLocations,
                      setSelectedLocations,
                      chatter_location_id
                    );
                }}
              >
                <StyledTableCell>
                  <FormLabel key={chatter_location_id}>
                    <Checkbox
                      name="areas"
                      color="primary"
                      value={chatter_location_id}
                      style={maxHeight}
                      disabled={disabled}
                      checked={
                        selectedLocations.filter(
                          (location) => location.chatter_location_id === chatter_location_id
                        ).length > 0
                      }
                      onChange={() => {
                        onCheckboxChange(
                          chatterCollections,
                          selectedLocations,
                          setSelectedLocations,
                          chatter_location_id
                        );
                      }}
                    />
                  </FormLabel>
                </StyledTableCell>
                <StyledTableCell>{chatter_location_id}</StyledTableCell>
                <StyledTableCell>{description}</StyledTableCell>
                <StyledTableCell>{source_location_identifier}</StyledTableCell>
                <StyledTableCell>
                  {order_area_to_chatter_locations.map((oa: { order_area_id: number }) => {
                    return <OrderAreaPill key={oa.order_area_id}>{oa.order_area_id}</OrderAreaPill>;
                  })}
                </StyledTableCell>
                <StyledTableCell>{chatter_images_aggregate.aggregate.count}</StyledTableCell>
                <StyledTableCell>{timezone}</StyledTableCell>
              </StyledTableRow>
            );
          }
        )}
      </StyledTableWrapper>
    );
  };

  const newCollectionRow = () => {
    return (
      <CreateNewRow color="secondary">
        <div>
          <DateSelector
            type="ChatterFrom"
            color={'secondary'}
            selectedDate={selectedFromDate}
            setSelectedDate={setSelectedFromDate}
          />
          <DateSelector
            type="ChatterTo"
            color={'secondary'}
            selectedDate={selectedToDate}
            setSelectedDate={setSelectedToDate}
          />
        </div>
        <Box display="flex" justifyContent="flex-end">
          <Button
            type="submit"
            variant="form"
            color={isCollectionFormComplete() ? 'primary' : 'disabled'}
            disabled={!isCollectionFormComplete()}
            onClick={onCollectionSaveClick}
          >
            Save New Collection
          </Button>
          <Button variant="form" color="secondary" onClick={onNewCollectionClose}>
            Cancel
          </Button>
        </Box>
      </CreateNewRow>
    );
  };

  const newLocationRow = () => {
    return (
      <CreateNewRow color="primary">
        <StyledTableCell minWidth={20}>
          <TextField
            value={locationName}
            fullWidth={true}
            label="New Location Name"
            onChange={handleLocationNameChange}
          />
        </StyledTableCell>
        <StyledTableCell>
          <TextField
            value={sourceId}
            fullWidth={true}
            type="number"
            label="New Source ID"
            error={false}
            onChange={handleSourceIdChange}
          />
        </StyledTableCell>
        <StyledTableCell>
          <OrderAreaDropdown>
            {orderAreas.map((orderArea) => {
              return (
                <div key={orderArea.order_area_id}>
                  <Checkbox
                    disabled={false}
                    color="default"
                    key={orderArea.order_area_id}
                    value={orderArea.order_area_id}
                    id={`${orderArea.order_area_id}-checkbox`}
                    checked={selectedOAIds.indexOf(orderArea.order_area_id) > -1}
                    onChange={handleIdSelectionChange}
                  />
                  {`${orderArea.order_area_id} - ${orderArea.name}`}
                </div>
              );
            })}
          </OrderAreaDropdown>
        </StyledTableCell>
        <StyledTableCell>
          <TextField
            value={timezone}
            fullWidth={true}
            label="Set Timezone"
            error={!timezone || timezone.length < 1 || !timezone.includes('/')}
            helperText={
              (!timezone || timezone.length < 1 || !timezone.includes('/')) &&
              'Timezone format: Australia/Melbourne'
            }
            onChange={handleTimezoneChange}
          />
        </StyledTableCell>
        <StyledTableCell>
          <Box display="flex" justifyContent="flex-end">
            <Button
              type="submit"
              variant="form"
              color={isLocationFormComplete() ? 'primary' : 'disabled'}
              disabled={!isLocationFormComplete()}
              onClick={onLocationSaveClick}
            >
              Save
            </Button>
            <Button variant="form" color="primary" onClick={onNewLocationClose}>
              Cancel
            </Button>
          </Box>
        </StyledTableCell>
      </CreateNewRow>
    );
  };

  return (
    <FormControl>
      {chatterCollections.map((collection: ChatterCollection) => {
        const locationsCheck: boolean = collection.chatter_locations.length > 0; // Disable if collection has no locations

        return (
          <StyledTableWrapper key={collection.chatter_collection_id}>
            {chatterCollectionsTable(collection, locationsCheck)}

            {chatterLocationsTable(collection.chatter_locations)}

            {collection.chatter_collection_id === enableJobForId && newLocationRow()}

            <NewItemRow>
              <Button
                variant="form"
                color="primary"
                disabled={enableJobForId > 0 || enableNewCollection}
                onClick={() => {
                  setEnableJobForId(collection.chatter_collection_id);
                }}
              >
                Add Location
              </Button>
            </NewItemRow>
          </StyledTableWrapper>
        );
      })}

      <div>
        {enableNewCollection && newCollectionRow()}
        <NewItemRow>
          <Button
            variant="form"
            color="secondary"
            disabled={enableNewCollection || enableJobForId > 0}
            onClick={() => {
              setEnableNewCollection(true);
            }}
          >
            Add Collection
          </Button>
        </NewItemRow>
      </div>
      <VerticalSpacer />
    </FormControl>
  );
};

export default ScrapeChatterForm;
