import { FontWeights, ERRORS } from 'Constants';
import { Role } from 'libs/Apollo/Cache.types';
import { DownloadFileTypeADT } from 'Components/DownloadFile/DownloadFile';
import { OrderProps, PPANodes, OrderArea } from 'Pages/Order/Order.types';
import {
  ChatterCategory,
  SortedCategoryIds,
  SortedTags,
} from 'Pages/TaggingInterface/TaggingInterface.types';

/**
 * @param {string} name The cookie name.
 * @return {?string} The corresponding cookie value to lookup.
 */

export const getCookie: any = (name: string) => {
  const v = document.cookie.match(`(^|;) ?${name}=([^;]*)(;|$)`);
  return v ? v[2] : null;
};

/**
 * converts font weight requrements to match font naming for imports.
 */

export const convertWeight: { [k in FontWeights]: string } = {
  [FontWeights.BOLD]: 'Bold',
  [FontWeights.MEDIUM]: 'Medium',
  [FontWeights.LIGHT]: 'Light',
  [FontWeights.BOOK]: 'Book',
};

/**
 *
 * @param date: string
 * Takes the data_of_creation value and formats it
 */
export const formatDate = (date: string): string => {
  const d = new Date(date);
  return d.toLocaleString();
};

export const convertToLocalDate = (date: string): string => {
  // #TODO: fix weird type values. tempDate is required for reshuffling 'date' from string to Date, for d to then shuffle from Date to number, and back to Date?
  const tempDate = new Date(date);
  const d: Date = new Date(
    Date.UTC(
      tempDate.getFullYear(),
      tempDate.getMonth(),
      tempDate.getDate(),
      tempDate.getHours(),
      tempDate.getMinutes(),
      tempDate.getSeconds()
    )
  );

  const result = `${d.toLocaleString()} (${Intl.DateTimeFormat().resolvedOptions().timeZone})`;
  return result;
};

/**
 *
 * @param string
 * formats a string to allow for search
 */
const formatString = (value: any): string => value.toString().replace(/\s/g, '').toLowerCase();

const getObjectValues = (values: Record<string, unknown>): any => {
  return Object.values(values)
    .filter((val) => val !== null && val !== undefined)
    .reduce((string, val): string => {
      if (typeof val === 'object') val = formatString(getObjectValues(val as any));
      return string + formatString(val);
    }, '');
};

/**
 * Filters an array of objects by search term or returns an empty array.
 */
export const filterArray = (array: any[], searchTerm: string): any[] =>
  array.filter((element: Record<string, unknown>) =>
    getObjectValues(element).includes(formatString(searchTerm))
  );

/**
 *
 * @param array
 * Takes a CSV Array and converts it to expected format eg:
 * From: [{"category": "A", "NL_Category_0": "x"}, {"category": "B", "NL_Category_0": "y"}]
 * To: {"category": ["A", "B"], "NL_Category_0": ["x", "y"]}
 */

export const convertConvertedCSV = (array: any) =>
  /* eslint-disable array-callback-return */
  array.reduce((acc: any, obj: any) => {
    Object.entries(obj).map(([key, value]) => {
      if (key in acc) {
        acc[key].push(value);
      } else {
        acc[key] = [value];
      }
    });
    return acc;
  }, {});
/* eslint-disable array-callback-return */

/** Sorting start - cant yet handle objects  */
export const orderArray = (array: any) =>
  array.slice().sort((a: any, b: any) => {
    return b - a;
  });

export type ERROR_TYPE = 'LOGIN' | 'VALIDATE_SCRAPER_CSV' | 'POPULATE_SCRAPER_CSV';

export const errorBuilder = (type: ERROR_TYPE) => {
  return { message: ERRORS[type as ERROR_TYPE] };
};

export interface RolesType {
  roles: Role;
}

/**
 *  @param {string[]} access
 *  @param {Role} role
 * It takes an array of roles allowed access and returns true or false if any of those roles are the user’s default role.
 * role is obtained from useAuth's getInfo
 */
export const accessCheck = (access: string[], roles: Role): boolean =>
  access.includes(roles['x-hasura-default-role']);

export const downloadFileTypeForTaskADT = (
  status: string,
  taskName: string
): DownloadFileTypeADT => {
  const canDownload = ['exportExcelGc', 'exportProductXArchive'];

  if (!canDownload.includes(taskName)) return { state: 'none' };

  switch (status) {
    case 'PENDING':
      return { state: 'pending' };
    case 'STARTED':
      return { state: 'started' };
    case 'FAILURE':
      return { state: 'failure' };
    case 'SUCCESS':
      switch (taskName) {
        case 'exportExcelGc':
          return { state: 'success', mimeType: 'xlsx' };
        case 'exportProductXArchive':
          return { state: 'success', mimeType: 'zip' };
      }
      return { state: 'none' };
    default:
      return { state: 'pending' };
  }
};

/**
 * @param date
 * @returns isoString date
 * Takes a Date object from MUI Date picker and converts to isodate.
 */
export const parseMUIDate = (date: Date | null): string | null => {
  if (date && date instanceof Date && !isNaN(date as any)) {
    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date
      .getDate()
      .toString()
      .padStart(2, '0')}`;
  }
  return null;
};

export const getURLParam = (urlParam: string): string | null => {
  const findURLParam = new URLSearchParams(window.location.search);
  return findURLParam.get(urlParam);
};

/**
 * @param order
 * @returns boolean
 * Takes one order in, returns true/false if the PPA entries are adequate for this order
 */
export const ppaCheck = (order: OrderProps) => {
  if (order.ppa_agg) {
    // Check if there are no PPA entries
    const ppaPopulated = order.ppa_agg.aggregate.count > 0;
    if (!ppaPopulated) return false;

    const ppaoaOAIds = order.ppa_agg.nodes.flatMap((nodes: PPANodes) => {
      const toMap: any = nodes.ppa_to_ppaoa; // needs any type to allow map
      return toMap.map((ppa_to_ppaoa: { order_area_id: number }) => ppa_to_ppaoa.order_area_id);
    });

    const ppaOAAgg = order.ppa_agg.nodes
      .map((nodes: PPANodes) => {
        return nodes.ppa_to_ppaoa_agg.aggregate.count;
      })
      .reduce((sum: number, current: number) => sum + current, 0);

    // Return true if there's only one PPA entry, and there are no PPAOA entries (as we assume the PPA entry handles export for all order areas for the order)
    const singlePpaEntry = order.ppa_agg.aggregate.count === 1;
    if (singlePpaEntry && !(ppaOAAgg > 0)) return true;

    const oaIds = order.order_areas.map(
      (order_areas: { order_area_id: number }) => order_areas.order_area_id
    );
    // Check if there are any order areas from OA list missing from the PPAOA entries
    if (missingIntCheck(oaIds, ppaoaOAIds).length > 0) return false;

    if (oaIds.every((val) => ppaoaOAIds.includes(val))) return true;
  } else return false;
};

export const duplicateIntCheck = (arr: number[]) => {
  const duplicateList = arr.filter((i: number, index: number) => arr.indexOf(i) !== index);
  if (duplicateList.length > 0) return duplicateList;
  else return [];
};

export const missingIntCheck = (correctArr: number[], arr: number[]) => {
  const missingList = correctArr.filter((oa: number) => !arr.includes(oa));
  if (missingList.length > 0) return missingList;
  else return [];
};

export const sortChatterCategories = (
  taggingCategories: ChatterCategory[],
  categoryIdsSorted: SortedCategoryIds[]
): SortedTags[] => {
  const temp = new Map();
  taggingCategories.forEach((item) => temp.set(item.chatter_category_id, item));
  categoryIdsSorted.forEach((item) =>
    temp.set(item.chatter_category_id, { ...temp.get(item.chatter_category_id), ...item })
  );
  const unsortedArray = Array.from(temp.values());
  const sortedArray = unsortedArray.sort((a, b) => (a.sorted_id < b.sorted_id ? -1 : 1));
  return sortedArray;
};

export const createTaggingImageUrl = (gcs_path: string, filename: string) => {
  return `https://urkel.neighbourlytics.com/${gcs_path}/${filename}`;
};

export const s2ab = (s: any) => {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
  return buf;
};

export const sortOrderAreas = (orderAreas: OrderArea[], sortFilter: 'name' | 'area_id') => {
  if (orderAreas.length > 0) {
    switch (sortFilter) {
      case 'name':
        return orderAreas.slice().sort((a, b) => (a.name < b.name ? -1 : 1));
      case 'area_id':
        return orderAreas.slice().sort((a, b) => (a.order_area_id < b.order_area_id ? -1 : 1));
      default:
        return orderAreas;
    }
  }
  return orderAreas;
};
