import { FC, useState } from 'react';
import { useMutation } from '@apollo/client';

import { Grid, Typography, Paper, FormControl, FormLabel, Input } from '@material-ui/core';

import TasksList from 'Pages/Tasks';
import PXRecreateForm from 'Components/PXRecreateForm/PXRecreateForm';
import CardBasic from 'Components/CardBasic';
import Error from 'Components/Error';
import CachedIcon from '@material-ui/icons/Cached';

import { EXPORT_PRODUCT_X_ARCHIVE_GROUP } from 'Queries';

import { StyledFormButton, StyledRexportContainer } from './AllOrders.styles';

import { Errors } from 'Components/Error/Error.types';
import { EnvironmentType } from 'Components/ProductXExport/ProductXExport.types';

interface ExportProductXArchiveGroupReturn {
  exportProductXArchiveGroup: {
    taskId?: string;
    errors?: { message: string };
  };
}

const AllOrders: FC = () => {
  const [loadButton, setLoadButton] = useState<boolean>(true);
  const [tasksList, setTasksList] = useState<boolean>(false);

  const [taskId, setTaskId] = useState<string | undefined>(undefined);
  const [taskSent, setTaskSent] = useState<boolean>(false);
  const [errors, setErrors] = useState<Errors | undefined>(undefined);

  // Selected Order ID and Environment
  const [orderId, setOrderId] = useState<number | undefined>(undefined);
  const [selectedEnvironment, setSelectedEnvironment] = useState<EnvironmentType>('prod');

  // Selected Recreate Flags
  const [recreateIsochrones, setRecreateIsochrones] = useState<boolean>(false);
  const [recreateDemographics, setRecreateDemographics] = useState<boolean>(false);
  const [recreatePlaces, setRecreatePlaces] = useState<boolean>(true);
  const [recreateRelevanceOverTime, setRecreateRelevanceOverTime] = useState<boolean>(false);
  const [recreateChatterMetric, setRecreateChatterMetric] = useState<boolean>(false);
  const [recreatePresenceDwell, setRecreatePresenceDwell] = useState<boolean>(false);
  const [recreatePresenceVisitation, setRecreatePresenceVisitation] = useState<boolean>(false);
  const [recreateKeywords, setRecreateKeywords] = useState<boolean>(false);

  const enableTasksList = () => {
    setLoadButton(false);
    setTasksList(true);
  };

  const handleUnlockOnClick = () => {
    setTaskSent(false);
    setTaskId(undefined);
    setErrors(undefined);
  };

  const doExport = () => {
    setTaskSent(true);
    exportProductXArchiveGroup();
  };

  const [exportProductXArchiveGroup] = useMutation<ExportProductXArchiveGroupReturn>(
    EXPORT_PRODUCT_X_ARCHIVE_GROUP,
    {
      variables: {
        ...(orderId && { order_id: orderId }),
        environment: selectedEnvironment,
        isochrones_recreate: recreateIsochrones,
        demographics_recreate: recreateDemographics,
        places_recreate: recreatePlaces,
        relevance_over_time_recreate: recreateRelevanceOverTime,
        chatter_metric_recreate: recreateChatterMetric,
        presence_dwell_recreate: recreatePresenceDwell,
        presence_visitation_recreate: recreatePresenceVisitation,
        keywords_recreate: recreateKeywords,
      },
      onCompleted: ({
        exportProductXArchiveGroup: { taskId },
      }: ExportProductXArchiveGroupReturn) => {
        if (taskId === null) {
          setErrors({
            message: `Final export list is empty for these parameters. No tasks have been scheduled.`,
          } as Errors);
          return;
        }

        if (!tasksList) {
          enableTasksList();
        }
        setTaskId(taskId);
      },
      onError: (err) => {
        setErrors({ message: `${err.message}` } as Errors);
      },
    }
  );

  return (
    <>
      <CardBasic elevation={0}>
        <Typography variant="h5" component="h2" align="left" style={{ padding: 20 }}>
          All Orders
        </Typography>

        <StyledRexportContainer>
          <Grid container component={Paper} spacing={10} elevation={0}>
            <Grid item xs={12}>
              <Typography variant="h6">Re-Export PX Orders</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography style={{ padding: '0px 0px 10px 0px' }}>
                Click the Button below to Re-Export all PX Orders listed in Process Parameter
                Archive.
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <FormControl fullWidth={false} margin="dense">
                <FormLabel>Order ID</FormLabel>
                <Input
                  type="number"
                  disabled={taskSent}
                  value={orderId}
                  onChange={(e: any) => {
                    setOrderId(e.target.value);
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={11}>
              <PXRecreateForm
                disabled={taskSent}
                selectedEnvironment={selectedEnvironment}
                setSelectedEnvironment={setSelectedEnvironment}
                recreateChatterMetric={recreateChatterMetric}
                recreateDemographics={recreateDemographics}
                recreateIsochrones={recreateIsochrones}
                recreatePlaces={recreatePlaces}
                recreatePresenceDwell={recreatePresenceDwell}
                recreatePresenceVisitation={recreatePresenceVisitation}
                recreateRelevanceOverTime={recreateRelevanceOverTime}
                recreateKeywords={recreateKeywords}
                setRecreateChatterMetric={setRecreateChatterMetric}
                setRecreateDemographics={setRecreateDemographics}
                setRecreateIsochrones={setRecreateIsochrones}
                setRecreatePlaces={setRecreatePlaces}
                setRecreatePresenceDwell={setRecreatePresenceDwell}
                setRecreatePresenceVisitation={setRecreatePresenceVisitation}
                setRecreateRelevanceOverTime={setRecreateRelevanceOverTime}
                setRecreateKeywords={setRecreateKeywords}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledFormButton
                onClick={doExport}
                disabled={taskSent}
                variant="form"
                color="primary"
              >
                Re-Export {orderId ? `Order ${orderId}` : 'All PX Orders'}
              </StyledFormButton>
            </Grid>

            {taskSent && (
              <Grid container item xs={12} alignItems="center">
                {errors ? (
                  <Grid item xs={6}>
                    <Error error={errors as { message?: string | undefined }} />
                  </Grid>
                ) : (
                  <Grid item xs={6}>
                    <Typography variant="body1">All PX Orders are now re-exporting!</Typography>
                    <Typography component="h6" variant="caption">
                      {taskId ? `Task ID: ${taskId}` : 'Submitting taskId'}
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={2}>
                  <StyledFormButton
                    value={true}
                    onClick={handleUnlockOnClick}
                    variant="form"
                    color="primary"
                  >
                    Refresh
                  </StyledFormButton>
                </Grid>
              </Grid>
            )}
          </Grid>
        </StyledRexportContainer>
      </CardBasic>

      <CardBasic elevation={0}>
        {loadButton && (
          <StyledRexportContainer>
            <StyledFormButton value={true} onClick={enableTasksList} variant="form" color="primary">
              <Grid
                container
                direction="row"
                spacing={5}
                alignItems="center"
                justifyContent="center"
              >
                <Grid item xs={2}>
                  <CachedIcon fontSize="small" />
                </Grid>
                <Grid item xs={10}>
                  <Typography>Load Tasks List</Typography>
                </Grid>
              </Grid>
            </StyledFormButton>
          </StyledRexportContainer>
        )}
        {tasksList && <TasksList />}
      </CardBasic>
    </>
  );
};

export default AllOrders;
