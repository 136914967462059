import React, { FC, ReactNode } from 'react';
import { Box, Paper } from '@material-ui/core';
import { StyledBox } from './CardBasic.style';

interface CardBasicProps {
  elevation: number;
  children: ReactNode;
}

const Card: FC<CardBasicProps> = ({ children, elevation }) => (
  <StyledBox mx="auto" p={5}>
    <Paper square elevation={elevation}>
      <Box mx="auto" p={4}>
        {children}
      </Box>
    </Paper>
  </StyledBox>
);

export default Card;
