import styled from 'styled-components';

export const TableWrapper = styled.div`
  display: table;
  overflow: hidden;
  white-space: wrap;
`;

export const TableHeader = styled.div`
  display: table-header-group;
  > div {
    cursor: default;
    color: #777;
    &:nth-child(3) {
      padding-left: 0.3rem;
      padding-right: 0.3rem;
    }
    &:nth-child(4) {
      padding-left: 0.3rem;
      padding-right: 0.3rem;
    }
  }
`;

export const TableRow = styled.div<{ disabled: boolean | undefined }>`
  display: table-row;
  ${(props) => `
    cursor: ${props.disabled ? 'default' : 'pointer'};
  `};
  > div {
    &:first-child {
      width: 1px;
      min-width: 1px;
    }
    &:nth-child(2) {
      width: 300px;
      min-width: 130px;
    }
    &:nth-child(3) {
      min-width: 90px;
    }
    &:nth-child(4) {
      min-width: 90px;
    }
  }
`;

export const TableCell = styled.div`
  display: table-cell;
  padding-left: 1rem;
  outline-offset: -2px;
  > div {
    &:first-child {
      padding-left: 0rem;
      padding-right: 0rem;
    }
  }
`;
