import styled from 'styled-components';
import Button from 'Components/Button';

export const StyledFormButton = styled(Button)`
  margin: 1rem 0;

  &:disabled {
    background-color: lightgrey;
  }
`;

export const StyledList = styled.ul`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 1.5rem;
`;
