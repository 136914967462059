import { FC, useState, SetStateAction } from 'react';
import { useQuery } from '@apollo/client';

import { Grid, Box, CircularProgress } from '@material-ui/core';

import { GET_CHATTER_CATEGORIES } from 'Queries';

// Styling
import { TagsSidebar, TagList, TagListItem } from './TaggingInterface.styles';

// Types
import { SortedTags, ChatterCategoryQueryData } from './TaggingInterface.types';

// Constants
import { categoryIdsSorted, tagColors } from './TaggingConstants';

// Helpers
import { sortChatterCategories } from 'helpers';

type TaggingInterfaceTagListProps = {
  selectedTag: number | null;
  onTagClick: (value: SetStateAction<number | null>) => void;
  disableForm: boolean;
};

const TaggingInterfaceTagList: FC<TaggingInterfaceTagListProps> = ({
  selectedTag,
  onTagClick,
  disableForm,
}) => {
  const [sortedCategories, setSortedCategories] = useState<SortedTags[]>([]);

  const { loading } = useQuery<ChatterCategoryQueryData>(GET_CHATTER_CATEGORIES, {
    onCompleted: (d) => {
      setSortedCategories(sortChatterCategories(d.chatter_category, categoryIdsSorted));
    },
  });

  const taggingCategoriesLeft = sortedCategories.filter(
    (category) => category.sorted_id > 0 && category.sorted_id < 31
  );
  const taggingCategoriesRight = sortedCategories.filter((category) => category.sorted_id > 30);

  return (
    <>
      {loading ? (
        <Grid item xs={4}>
          <Box display="flex" justifyContent="center" alignItems="center" paddingTop="2rem">
            <CircularProgress />
          </Box>
        </Grid>
      ) : (
        <>
          <Grid item xs={2}>
            <TagsSidebar>
              <TagList dense>
                {taggingCategoriesLeft.map((chatter_category, index) => {
                  const tagColor = tagColors.find((c) =>
                    c.ids.includes(chatter_category.chatter_category_id)
                  )?.hex;

                  return (
                    <TagListItem
                      key={index}
                      disabled={disableForm}
                      selected={selectedTag === chatter_category.chatter_category_id}
                      onClick={() => {
                        !disableForm && onTagClick(chatter_category.chatter_category_id);
                      }}
                      color={tagColor}
                    >
                      {chatter_category.chatter_label}
                    </TagListItem>
                  );
                })}
              </TagList>
            </TagsSidebar>
          </Grid>
          <Grid item xs={2}>
            <TagsSidebar>
              <TagList dense>
                {taggingCategoriesRight.map((chatter_category, index) => {
                  const tagColor = tagColors.find((c) =>
                    c.ids.includes(chatter_category.chatter_category_id)
                  )?.hex;

                  return (
                    <TagListItem
                      key={index}
                      disabled={disableForm}
                      selected={selectedTag === chatter_category.chatter_category_id}
                      onClick={() => {
                        !disableForm && onTagClick(chatter_category.chatter_category_id);
                      }}
                      color={tagColor}
                    >
                      {chatter_category.chatter_label}
                    </TagListItem>
                  );
                })}
              </TagList>
            </TagsSidebar>
          </Grid>
        </>
      )}
    </>
  );
};

export default TaggingInterfaceTagList;
