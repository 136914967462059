import { FC, useState } from 'react';

import { GET_TAGGED_ORDERS } from 'Queries';
import { useQuery } from '@apollo/client';

import ChatterImageSelector from 'Components/ChatterImageSelector';
import CardBasic from 'Components/CardBasic';
import Error from 'Components/Error';
import VerticalSpacer from 'Components/Spacer';

import {
  Select,
  TableContainer,
  Paper,
  Grid,
  MenuItem,
  FormLabel,
  FormControl,
} from '@material-ui/core';

import { TableHeading, InstructionsBox } from './OrderImageLibrary.styles';

import { OrderArea } from 'Pages/Order/Order.types';

interface OrderData {
  name: string;
  order_id: number;
  date_of_creation: string;
  order_areas: OrderArea[];
}

const OrderImageLibrary: FC = () => {
  const [data, setData] = useState<OrderData[]>([]);
  const [error, setError] = useState<string>('');
  const [selectedOrder, setSelectedOrder] = useState<OrderData>();

  const { loading } = useQuery<any>(GET_TAGGED_ORDERS, {
    onCompleted: (data) => {
      setData(data.order);
    },
    onError: (res) => {
      setError(res.message);
    },
  });

  if (loading || error)
    return (
      <TableContainer component={Paper}>
        <TableHeading>Order Image Library</TableHeading>
        <CardBasic elevation={0}>
          {loading && <p>Loading...</p>}
          {error && <Error error={{ message: error }} />}
        </CardBasic>
      </TableContainer>
    );

  return (
    <>
      <TableContainer component={Paper}>
        <TableHeading>Order Image Library</TableHeading>
        <CardBasic elevation={0}>
          <Grid container>
            <Grid item xs={6}>
              <p>Use this tool to view all of the tagged images for an Order by Order Area</p>
              <VerticalSpacer />
              <FormControl>
                <FormLabel>Select Report Order</FormLabel>
                <Select
                  name="orders"
                  id="orders"
                  label="Select Order"
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  value={selectedOrder?.order_id ?? ''}
                  required
                  onChange={(e) => {
                    const selectedOrderId = e.target.value as number;
                    setSelectedOrder(data.find((order) => order.order_id === selectedOrderId));
                  }}
                >
                  {data.map((order) => (
                    <MenuItem key={order.order_id} value={order.order_id}>
                      {`${order.order_id} - ${order.name}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <InstructionsBox>
                <ul>
                  <li>Use the 'Select Report Order' filter to select a report order</li>
                  <li>
                    Use the 'Select Order Area' filter to select an order area from that report
                    order
                  </li>
                  <li>Use the 'Select NLC' filter to select the granularity of NLC</li>
                  <li>
                    Use the 'Select Category' filter to select all images tagged within that
                    category
                  </li>
                  <li>
                    'Selected Categories' lists all of the tag categories assigned to the selected
                    category
                  </li>
                </ul>
              </InstructionsBox>
            </Grid>
          </Grid>
        </CardBasic>
        <CardBasic elevation={0}>
          <ChatterImageSelector
            orderAreas={
              selectedOrder ? selectedOrder.order_areas.map((order_area) => order_area) : []
            }
            disabled={selectedOrder === undefined}
          />
        </CardBasic>
      </TableContainer>
    </>
  );
};

export default OrderImageLibrary;
