import { FC, SetStateAction } from 'react';

import { Grid } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import CachedIcon from '@material-ui/icons/Cached';
import CircularProgress from '@material-ui/core/CircularProgress';

import { CeleryStatusNLColors, CeleryStatus } from 'Constants';
import { STATUS } from 'Pages/Tasks/Tasks.types';

import { StyledFormButton } from './StatusChip.styles';
import { StyledChip } from 'Pages/Tasks/TasksList.styles';

interface StatusChipProps {
  loadingResults: boolean;
  setLoadingResults: (value: SetStateAction<boolean>) => void;
  handleChildRefreshButtonClick: () => void;
  taskChildren: string[];
  statusCheck: string;
  status: string;
}

const StatusChip: FC<StatusChipProps> = ({
  loadingResults,
  setLoadingResults,
  handleChildRefreshButtonClick,
  taskChildren,
  statusCheck,
  status,
}) => {
  const handleRefreshButtonClick = () => {
    // #TODO: Tidy Up this function where it disables the Child Task Details to Expand/Minimise
    setLoadingResults(true);
    handleChildRefreshButtonClick();
  };

  const ChipLabel = taskChildren
    ? CeleryStatus[`${statusCheck}` as STATUS]
    : CeleryStatus[`${status}` as STATUS];
  const ChipStyle = taskChildren ? statusCheck : status;

  return (
    <StyledChip
      status={ChipStyle as STATUS}
      label={
        <Grid container alignItems="center" alignContent="center" style={{ width: 85 }}>
          <Grid item xs={7}>{`${ChipLabel}`}</Grid>
          {ChipLabel !== CeleryStatus.SUCCESS ? (
            <Grid item xs={5}>
              <StyledFormButton
                variant="form"
                onClick={handleRefreshButtonClick}
                color={
                  CeleryStatusNLColors[
                    taskChildren ? (`${statusCheck}` as STATUS) : (`${status}` as STATUS)
                  ]
                }
              >
                {loadingResults ? (
                  <CircularProgress size="1.6rem" color="secondary" />
                ) : (
                  <CachedIcon fontSize="small" />
                )}
              </StyledFormButton>
            </Grid>
          ) : (
            <Grid item xs={5} style={{ padding: 14 }}>
              <DoneIcon />
            </Grid>
          )}
        </Grid>
      }
    />
  );
};

export default StatusChip;
