import { FC, useState, useEffect, FormEvent } from 'react';
import { useLazyQuery } from '@apollo/client';
import AnimateHeight from 'react-animate-height';
import {
  Grid,
  Typography,
  FormControl,
  FormControlLabel,
  FormLabel,
  Checkbox,
} from '@material-ui/core';

import Spacer from 'Components/Spacer';
import DateSelector from 'Components/DateSelector';
import Error from 'Components/Error';
import { Errors } from 'Components/Error/Error.types';

import { EXPORT_AS_EXCEL_GC } from 'Queries';

import { StyledFormButton, StyledList } from './GCExport.styles';

import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

interface Location {
  location_id: number;
  name: string;
}

interface GCProps {
  locations: Location[];
  order_id: number;
}

const maxHeight = {
  maxHeight: '40px',
};

const GCExport: FC<GCProps> = ({ locations, order_id }) => {
  const [height, setHeight] = useState<number | string>(0);
  const [errors, setErrors] = useState<Errors | undefined>(undefined);
  const [taskId, setTaskId] = useState<string>('');

  const locationIds = locations.map(({ location_id }) => location_id);
  // Form States
  const [selectedLocations, setSelectedLocations] = useState<number[]>(locationIds);
  const [selectedFromDate, setSelectedFromDate] = useState<MaterialUiPickersDate | null>(null);
  const [selectedToDate, setSelectedToDate] = useState<MaterialUiPickersDate | null>(null);

  const [exportGC] = useLazyQuery(EXPORT_AS_EXCEL_GC, {
    variables: {
      order_id,
      location_ids: selectedLocations,
      selected_to_date: selectedToDate ?? null,
      selected_from_date: selectedFromDate ?? null,
    },
    onCompleted: ({ exportAsExcelGc: { taskId } }) => {
      if (taskId) setTaskId(taskId);
    },
  });

  const exportGConClick = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (selectedLocations.length <= 0) {
      setErrors({
        message: `Please select at least one location`,
      } as any);
    } else {
      setErrors(undefined);
      exportGC();
    }
  };

  const onCheckboxChange = (location_id: number) => {
    const newLocations = [...selectedLocations];
    const locationIndex = newLocations.indexOf(location_id);

    if (locationIndex === -1) newLocations.push(location_id);
    else newLocations.splice(locationIndex, 1);

    setSelectedLocations(newLocations);
  };

  useEffect(() => {
    setHeight(() => 'auto');
    setSelectedLocations(locationIds);
  }, [locations]);

  return (
    <AnimateHeight duration={500} height={height}>
      <Spacer>
        <Grid container spacing={3}>
          <Grid container item xs={8} alignContent="flex-start">
            <Grid item xs={12}>
              <Error error={errors as any} />
            </Grid>
            <Grid item xs={12}>
              <FormLabel>Select dates</FormLabel>
            </Grid>
            <Grid item xs={6}>
              <DateSelector
                type="From"
                disabled={taskId ? true : false}
                color={'primary'}
                selectedDate={selectedFromDate}
                setSelectedDate={setSelectedFromDate}
              />
            </Grid>
            <Grid item xs={6}>
              <DateSelector
                type="To"
                disabled={taskId ? true : false}
                color={'primary'}
                selectedDate={selectedToDate}
                setSelectedDate={setSelectedToDate}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography component="h6" variant="caption" gutterBottom>
                <StyledList>
                  <li>
                    Choose dates allowing 2-3 days buffer before and after data was converted to
                    NL_Places to account for processing time, timezone variations, etc.
                  </li>
                  <li>
                    If exporting immediately following NL_Place creation, leave 'Date To...' blank,
                    to gather latest data.
                  </li>
                  <li>
                    To gather all data for the locations in our database, leave 'Date From...' and
                    'Date To...' blank.
                  </li>
                </StyledList>
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={4}>
            <FormControl component="fieldset">
              <FormLabel>Select locations</FormLabel>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="locations"
                      color="primary"
                      value={'all'}
                      checked={selectedLocations.length === locationIds.length}
                      disabled={taskId ? true : false}
                      onChange={() => {
                        setSelectedLocations([...locationIds]);
                      }}
                    />
                  }
                  label="Select All"
                  style={maxHeight}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="locations"
                      color="primary"
                      value={'none'}
                      checked={selectedLocations.length <= 0}
                      disabled={taskId ? true : false}
                      onChange={() => {
                        setSelectedLocations([]);
                      }}
                    />
                  }
                  label="Select None"
                  style={maxHeight}
                />
              </Grid>
              {locations.map(({ location_id, name }: Location) => (
                <FormControlLabel
                  key={location_id}
                  control={
                    <Checkbox
                      name="locations"
                      color="primary"
                      value={location_id}
                      checked={selectedLocations.indexOf(location_id) > -1}
                      disabled={taskId ? true : false}
                      onChange={() => {
                        onCheckboxChange(location_id);
                      }}
                    />
                  }
                  label={name}
                  style={maxHeight}
                />
              ))}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <StyledFormButton
              onClick={exportGConClick}
              disabled={taskId ? true : false}
              variant="form"
              color="primary"
            >
              Export Golden Categories
            </StyledFormButton>
          </Grid>
          <Grid item xs={12}>
            {taskId && (
              <>
                <Typography component="h5" gutterBottom>
                  Your Golden Categories are now exporting! Your download will be available via the
                  task once it's complete.
                </Typography>
                <Typography component="h6" variant="caption" gutterBottom>
                  Task ID: {taskId}
                </Typography>
              </>
            )}
          </Grid>
        </Grid>
      </Spacer>
    </AnimateHeight>
  );
};

export default GCExport;
