import { FC } from 'react';
import styled from 'styled-components';

import CancelIcon from '@material-ui/icons/Cancel';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';

export const PeanutButter = styled.div<{ visible: boolean }>`
  padding: 1rem;
  transition: opacity 0.3s;
  opacity: ${(props) => (props.visible ? 1 : 0)};

  margin: 1rem 0;
  color: white;
  width: 35rem;
  word-wrap: wrap;
  cursor: pointer;
`;

export const IconWrapper = styled.div`
  color: #fff;
  width: 3rem;
  height: 3rem;
  margin-left: 1rem;
`;

export const Bread = styled.div<{ type?: 'warning' }>`
  display: inline-flex;
  width: 45rem;
  background-color: ${(props) => (props.type === 'warning' ? 'orange' : '#ff7373')};
  align-items: center;
  box-shadow: 2px 2px 5px -1px rgba(0, 0, 0, 0.63);
  transition: box-shadow 0.3s ease-in-out;
  &:hover {
    box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.63);
  }
`;

const Close = styled.div<{ type?: 'warning' }>`
  height: 100%;
  width: 10rem;
  background: white;
  color: ${(props) => (props.type === 'warning' ? 'orange' : '#ff7373')};
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  transition: opacity 0.2s ease-in-out;
  opacity: 1;
  cursor: pointer;
  > svg {
    width: 2rem;
    height: 2rem;
  }
  &:hover {
    opacity: 0.8;
  }
`;

interface CustomToastProps {
  type?: 'warning';
  remove: (id: string) => void;
  visible: boolean;
  message?: any;
  id: string;
}

const CustomToast: FC<CustomToastProps> = ({
  type,
  remove,
  visible,
  message,
  id,
}: CustomToastProps) => {
  return (
    <Bread onClick={() => remove(id)} type={type}>
      <IconWrapper>{type === 'warning' ? <WarningIcon /> : <ErrorIcon />}</IconWrapper>
      <PeanutButter visible={visible}>{message}</PeanutButter>
      <Close type={type}>
        Close <CancelIcon />
      </Close>
    </Bread>
  );
};

export default CustomToast;
