import { createGlobalStyle, css } from 'styled-components';
import { normalize } from 'styled-normalize';
import { convertWeight } from 'helpers';
import { FontNames, FontWeights, FontFormats, Colors } from 'Constants';

const fontFace = (name: FontNames, weight: FontWeights, format: FontFormats) => css`
  @font-face {
    font-family: '${name}';
    font-display: fallback;
    font-weight: ${weight};
    src: url('/fonts/${name}-${convertWeight[weight]}.${format}') format(${format});
  }
`;
export default createGlobalStyle`
  ${normalize}
  ${fontFace(FontNames.IBM_PLEX_SANS, FontWeights.BOLD, FontFormats.TTF)}
  ${fontFace(FontNames.IBM_PLEX_SANS, FontWeights.LIGHT, FontFormats.WOFF)}
  ${fontFace(FontNames.NEXA, FontWeights.BOLD, FontFormats.WOFF)}
  ${fontFace(FontNames.NEXA, FontWeights.LIGHT, FontFormats.WOFF)}
  ${fontFace(FontNames.SOFIA_PRO, FontWeights.BOLD, FontFormats.WOFF)}
  ${fontFace(FontNames.SOFIA_PRO, FontWeights.LIGHT, FontFormats.WOFF)}

  *,
  *:after,
  *:before {
    box-sizing: border-box;
  }
  body, dl, dt, dd,
 ul, ol, li,
 h1, h2, h3, h4, h5, h6,
 pre,
 form, fieldset,
 p, blockquote,
 figure, figcaption,
 table, th, td {
   margin: 0; 
   padding: 0;
 }
 html {
   font-size: 62.5%;
   min-height:100%;
 }
 h1, h2, h3, h4, h4, p, li, a, {
  font-family: "Sofia Pro", "Nexa", sans-serif ;
 }
 body {
   font-family: "Sofia Pro", "Nexa", sans-serif;
    font-weight: 400;
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1.40;
    background-color: ${Colors.background};
  }
`;
