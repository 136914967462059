import React, { FC } from 'react';
import DownloadFile from 'Components/DownloadFile';
import Results, { ResultDetails } from './Results';
import { StyledListItemAlignRight } from './TaskDetails.styles';
import { downloadFileTypeForTaskADT } from 'helpers';

interface Props {
  taskName: string;
  taskId: string;
  taskResults: Record<string, any>;
  status: string;
}

const TaskNoChildren: FC<Props> = ({ taskName, taskId, taskResults, status }) => {
  const downloadType = downloadFileTypeForTaskADT(status, taskName);

  return (
    <>
      <Results {...(taskResults as ResultDetails)} />
      <StyledListItemAlignRight component="div">
        <DownloadFile downloadType={downloadType} taskId={taskId} />
      </StyledListItemAlignRight>
    </>
  );
};

export default TaskNoChildren;
