import { FC, ChangeEvent, useState } from 'react';

import { useMutation } from '@apollo/client';
import { UPDATE_TAGGING_JOB } from 'Queries';

import { useUsers } from 'Providers/UserProvider';
import { convertToLocalDate } from 'helpers';

import { TableCell, TableRow, Select, MenuItem } from '@material-ui/core';
import Button from 'Components/Button';

import { TaggingEditRowProps } from '../TaggingJobList.types';
import { JobStatus } from 'Pages/TaggingInterface/TaggingInterface.types';
import TaggingRowOrderDetails from './TaggingRowOrderDetails';
import TaggingRowStatistics from './TaggingRowStatistics';

const TaggingRowEditJob: FC<TaggingEditRowProps> = ({
  data: job,
  diplayOrderDetails,
  onComplete,
}) => {
  const [selectedUser, setSelectedUser] = useState<number>(job.tagger_user_id);
  const [selectedStatus, setSelectedStatus] = useState<JobStatus>(job.job_status);

  const { users } = useUsers();

  const [updateTaggingJob, { loading }] = useMutation(UPDATE_TAGGING_JOB, {
    variables: {
      tagging_job_id: job.tagging_job_id,
      new_user: selectedUser,
      new_status: selectedStatus,
    },
    onCompleted: () => {
      onComplete();
    },
    onError: () => {
      onComplete(); //Todo: Proper error handling
    },
  });

  const urkelOrderId = job.chatter_location.chatter_collection.order.order_id;
  const orderNo = job.chatter_location.chatter_collection.order.order_no;
  const orderName = job.chatter_location.chatter_collection.order.name;

  const jobImages = job.tagging_job_images_aggregate.aggregate.count;
  const taggedJobImages = job.tagging_job_images_tagged_aggregate.aggregate.count;
  const tagsForReview = job.tags_for_review_aggregate.aggregate.count;
  const ignoredImages = job.ignored_images_aggregate.aggregate.count;
  const percentTagged: number = Math.round((taggedJobImages / jobImages) * 100);

  const onSaveClick = () => {
    updateTaggingJob();
  };

  const onCancelClick = () => {
    onComplete();
  };

  // TODO: confirm status options

  return (
    <TableRow>
      <TaggingRowOrderDetails
        urkelOrderId={urkelOrderId}
        orderNo={orderNo}
        orderName={orderName}
        diplayOrderDetails={diplayOrderDetails}
      />
      <TableCell>
        <a href={job.chatter_location.source_link} target="blank">
          {job.chatter_location.source_location_identifier}
        </a>
      </TableCell>
      <TableCell padding="none">
        {job.chatter_location.chatter_images_aggregate.aggregate.count}
      </TableCell>
      <TableCell>{job.tagging_job_creator_to_firebase_user.display_name}</TableCell>
      <TableCell variant="footer" padding="none">
        {job.notes}
      </TableCell>
      <TableCell>{convertToLocalDate(job.date_of_creation)}</TableCell>
      <TableCell /* Tagger */>
        <Select
          value={selectedUser}
          variant="outlined"
          MenuProps={{
            disableScrollLock: true,
          }}
          onChange={(e) => {
            const val = e.target.value as string;
            setSelectedUser(parseInt(val));
          }}
        >
          {users.map((user, index) => (
            <MenuItem key={index} value={user.firebase_user_id}>
              {user.display_name}
            </MenuItem>
          ))}
        </Select>
      </TableCell>
      <TaggingRowStatistics
        disabled={job.job_status === 'closed'}
        jobImages={jobImages}
        taggedJobImages={taggedJobImages}
        percentTagged={percentTagged}
        tagsForReview={tagsForReview}
        ignoredImages={ignoredImages}
      />
      <TableCell /* Job Status */>
        <Select
          value={selectedStatus}
          variant="outlined"
          MenuProps={{
            disableScrollLock: true,
          }}
          onChange={(e: ChangeEvent<{ value: unknown }>) => {
            // You must cast a provided value from MUI this way.
            const val = e.target.value as JobStatus;
            setSelectedStatus(val);
          }}
        >
          <MenuItem value="pending">Pending</MenuItem>
          <MenuItem value="open">Open</MenuItem>
          <MenuItem value="submitted">Submitted</MenuItem>
          {/* Closed was an option here. It was removed, as there is specific functionality that happens when the user closes a job via the tagging interface, which does not execute if the job is closed via this form */}
        </Select>
      </TableCell>
      <TableCell>
        <Button variant="form" color="primary" onClick={onSaveClick}>
          Save
        </Button>
        <Button variant="form" color="secondary" onClick={onCancelClick}>
          Cancel
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default TaggingRowEditJob;
