import { useState, useEffect, FormEvent, FC, useContext } from 'react';

import { ApolloError, useMutation } from '@apollo/client';
import { ApolloErrorReport } from 'Components/Error/Error.types';
import { RUN_REVIEWS_QUERY } from 'Queries';

import AnimateHeight from 'react-animate-height';
import {
  Grid,
  Typography,
  FormLabel,
  FormControl,
  TextField,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { StyledList } from './RunReviewsQuery.styles';

import { ThemeContext } from 'Providers/ThemeProvider';
import { parseMUIDate } from 'helpers';

import { OrderArea } from 'Pages/Order/Order.types';
import Spacer from 'Components/Spacer';
import DateSelector from 'Components/DateSelector';
import OrderAreaForm from 'Components/OrderAreaForm';
import SubmitJob from 'Components/SubmitJob';

interface AreaIdInfo {
  orderAreas: OrderArea[];
  name: string;
}

const RunReviewsQuery: FC<AreaIdInfo> = ({ order_id, orderAreas }: any) => {
  const [height, setHeight] = useState<number | string>(0);
  const [taskId, setTaskId] = useState<string>('');
  const [errors, setErrors] = useState<ApolloErrorReport | undefined>(undefined);

  const areaIds = orderAreas.map(({ order_area_id }: OrderArea) => order_area_id);

  const [selectedAreas, setSelectedAreas] = useState<number[]>(areaIds);
  const [lastSelectedArea, setLastSelectedArea] = useState<number>(-1);
  const [selectedFromDate, setSelectedFromDate] = useState<MaterialUiPickersDate | null>(null);
  const [selectedToDate, setSelectedToDate] = useState<MaterialUiPickersDate | null>(null);
  const [oldestTimestamp, setOldestTimestamp] = useState<MaterialUiPickersDate | null>(null);
  const [initialOffset, setInitialOffset] = useState<number | undefined>(undefined);
  const [forceOldest, setForceOldest] = useState<boolean>(false);

  const colorContext = useContext(ThemeContext);

  const [runReviewsQuery, { loading }] = useMutation(RUN_REVIEWS_QUERY, {
    variables: {
      order_id,
      area_ids: selectedAreas,
      oldest_timestamp: parseMUIDate(oldestTimestamp),
      selected_to_date: parseMUIDate(selectedToDate),
      selected_from_date: parseMUIDate(selectedFromDate),
      initial_offset: initialOffset ? initialOffset : null,
      force_collection_to_oldest_timestamp: forceOldest,
    },
    onCompleted: ({ runReviewsQuery: { taskId } }) => {
      if (taskId) setTaskId(taskId);
    },
    onError: (error: ApolloError) => {
      const errorReport: ApolloErrorReport = {
        message: error.message,
        errorMessages: error.graphQLErrors.map((gqle) => gqle.message),
      };
      setErrors(errorReport);
    },
  });

  const runReviewsQueryClick = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setErrors(undefined);
    runReviewsQuery();
  };

  useEffect(() => {
    colorContext.color != 'secondary' && colorContext.setColor('secondary');
  }, []);

  useEffect(() => {
    setHeight(() => 'auto');
    setSelectedAreas(areaIds);
  }, [orderAreas]);

  const handleInitialOffsetChange = (event: any) => {
    setInitialOffset(event.target.value);
  };

  const handleForceOldestChange = (event: any) => {
    setForceOldest(event.target.checked);
  };

  return (
    <AnimateHeight duration={500} height={height}>
      <Spacer>
        <Grid container spacing={3}>
          <Grid container item xs={8} md={5} lg={7} xl={8} alignContent="flex-start" spacing={10}>
            <Grid item xs={12} md={12} lg={6} xl={5}>
              <FormControl fullWidth margin="dense">
                <FormLabel>Select dates</FormLabel>
                <DateSelector
                  type="From"
                  disabled={loading}
                  color={'primary'}
                  selectedDate={selectedFromDate}
                  setSelectedDate={setSelectedFromDate}
                />
                <DateSelector
                  type="To"
                  disabled={loading}
                  color={'primary'}
                  selectedDate={selectedToDate}
                  setSelectedDate={setSelectedToDate}
                />
                <DateSelector
                  type="Oldest Timestamp"
                  disabled={loading}
                  color={'primary'}
                  selectedDate={oldestTimestamp}
                  setSelectedDate={setOldestTimestamp}
                />
              </FormControl>
              <FormControl fullWidth margin="dense">
                <FormControlLabel
                  label="Force collection to the oldest timestamp"
                  control={
                    <Checkbox
                      disabled={loading}
                      id="force-oldest"
                      checked={forceOldest}
                      onChange={handleForceOldestChange}
                    />
                  }
                ></FormControlLabel>
              </FormControl>
            </Grid>
            <Grid item xs={6} lg={3} xl={2}>
              <FormControl fullWidth margin="dense">
                <FormLabel>Initial Offset</FormLabel>
                <TextField
                  disabled={loading}
                  id="outlined-number"
                  style={{ paddingTop: '17px' }}
                  label="(by default is null)"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={initialOffset ? initialOffset : ''}
                  onChange={handleInitialOffsetChange}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Typography component="h6" variant="caption" gutterBottom>
                <StyledList>
                  <li>
                    Choose dates allowing 2-3 days buffer before and after data was converted to
                    NL_Places to account for processing time, timezone variations, etc.
                  </li>
                  <li>
                    If exporting immediately following NL_Place creation, leave 'Date To...' blank,
                    to gather latest data.
                  </li>
                  <li>
                    To gather all data for the areas in our database, leave 'Date From...' and 'Date
                    To...' blank.
                  </li>
                </StyledList>
                <StyledList>
                  <li>Select a date to be assigned as the oldest timestamp</li>
                  <li>
                    To collect reviews for full 13 months before the from date (default), leave
                    'Oldest Timestamp for Export' blank.
                  </li>
                </StyledList>
              </Typography>
            </Grid>
            <SubmitJob
              taskClickAction={runReviewsQueryClick}
              taskId={taskId}
              taskName={'Reviews Query'}
              errors={errors && [...errors.errorMessages, errors.message]}
              loading={loading}
              disabled={selectedAreas.length === 0}
            />
          </Grid>
          <Grid container item xs={4} md={7} lg={5} xl={4} alignContent="flex-start">
            <OrderAreaForm
              selectedAreas={selectedAreas}
              setLastSelectedArea={setLastSelectedArea}
              setSelectedAreas={setSelectedAreas}
              orderAreas={orderAreas}
              disabled={loading}
            />
          </Grid>
        </Grid>
      </Spacer>
    </AnimateHeight>
  );
};

export default RunReviewsQuery;
