import { FC } from 'react';
import { TableCell, TableHead } from '@material-ui/core';
import { StyledTableRow } from './TableHeader.styles';

const TableHeader: FC = () => {
  return (
    <TableHead>
      <StyledTableRow>
        <TableCell>
          <h4>Name</h4>
        </TableCell>
        <TableCell align="center">
          <h4>Wilson Order ID</h4>
        </TableCell>
        <TableCell align="center">
          <h4>Urkel Order ID</h4>
        </TableCell>
        <TableCell align="center">
          <h4>PPA</h4>
        </TableCell>
        <TableCell>
          <h4>First Imported Date</h4>
        </TableCell>
        <TableCell align="right">
          <h4>Requested by</h4>
        </TableCell>
      </StyledTableRow>
    </TableHead>
  );
};

export default TableHeader;
