import { FC, useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';

import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import { StyledTableCell } from './CollectionDatesTable.styles';

import { GET_ORDER_GOOGLE_PLACES_MAP_COLLECTIONS } from 'Queries';

interface CollectionDatesTableProps {
  order_id: number;
}

const CollectionDatesTable: FC<CollectionDatesTableProps> = ({
  order_id,
}: CollectionDatesTableProps) => {
  const [collections, setCollections] = useState<string[]>([]);
  const { loading, data } = useQuery(GET_ORDER_GOOGLE_PLACES_MAP_COLLECTIONS, {
    variables: { order_id: `${order_id}` },
  });

  useEffect(() => {
    if (loading || !data) return;
    setCollections(
      data.ordergoogleplacemap.map(
        (ordergoogleplacemap: { date_of_collection: string }) =>
          ordergoogleplacemap.date_of_collection
      )
    );
  }, [loading, data]);

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell>Previous Collection Dates:</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {collections.length > 0 ? (
            <>
              {collections.slice(0, 5).map((collection: string) => {
                // Only want to display the 5 latest dates
                return (
                  <TableRow key={collections.indexOf(collection)}>
                    <TableCell>{collection}</TableCell>
                  </TableRow>
                );
              })}
              {collections.length > 5 && (
                <TableRow>
                  <TableCell>{`There were ${collections.length} total previous collection dates`}</TableCell>
                </TableRow>
              )}
            </>
          ) : (
            <TableRow>
              <TableCell>{loading ? 'Loading...' : 'No Previous Dates'}</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CollectionDatesTable;
