import { FC } from 'react';

// Components
import { Grid, CircularProgress } from '@material-ui/core';

// Providers
import { useAuth } from 'Providers';

// Styling
import {
  ImageTableWrapper,
  ImageListThumbnail,
  ImageOverlay,
  ImageOverlayText,
} from './ChatterImageSelector.styles';

// Types / Arrays
import { ChatterImages } from './TaggedImages.types';

import { createTaggingImageUrl } from 'helpers';

interface TaggingInterfaceImageListProps {
  images: ChatterImages[];
}

const TaggedImagesList: FC<TaggingInterfaceImageListProps> = ({
  images,
}: TaggingInterfaceImageListProps) => {
  const { storageSignature, signSignature } = useAuth();

  return (
    <ImageTableWrapper>
      <Grid container>
        {images.map((image, index) => {
          return (
            <Grid
              item
              xs={3}
              key={index}
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <ImageListThumbnail>
                {storageSignature ? (
                  <>
                    <img
                      src={signSignature(createTaggingImageUrl(image.gcs_path, image.filename))}
                      alt="image"
                    />
                    <ImageOverlay>
                      <ImageOverlayText>
                        <div>{image.chatter_image_categories[0].chatter_category.nlc_2}</div>
                      </ImageOverlayText>
                    </ImageOverlay>
                  </>
                ) : (
                  <CircularProgress />
                )}
              </ImageListThumbnail>
            </Grid>
          );
        })}
      </Grid>
    </ImageTableWrapper>
  );
};

export default TaggedImagesList;
