import { useState, useEffect, FormEvent, FC, useContext, ChangeEvent } from 'react';
import { useMutation } from '@apollo/client';
import { ThemeContext } from 'Providers/ThemeProvider';

// Components
import {
  Grid,
  Typography,
  FormControl,
  FormLabel,
  FormControlLabel,
  Checkbox,
  TextField,
} from '@material-ui/core';
import { OrderArea } from 'Pages/Order/Order.types';
import DateSelector from 'Components/DateSelector';
import OrderAreaForm from 'Components/OrderAreaForm';
import PXRecreateForm from 'Components/PXRecreateForm/PXRecreateForm';
import PlacesMap from 'Components/PlacesMap';
import AnimateHeight from 'react-animate-height';
import Spacer from 'Components/Spacer';
import { Errors } from 'Components/Error/Error.types';
import SubmitJob from 'Components/SubmitJob';

// Types
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import {
  EnvironmentType,
  RecreateFlag,
  CategorisationEngineType,
  RelevanceVersionType,
} from './ProductXExport.types';

// Styles
import { StyledList, FormFieldLabel } from './ProductXExport.styles';

// Queries
import { EXPORT_PRODUCT_X_ARCHIVE } from 'Queries';

// Helpers
import { parseMUIDate, getURLParam } from 'helpers';

interface AreaIdInfo {
  order_id: number;
  orderAreas: OrderArea[];
}

const ProductXExport: FC<AreaIdInfo> = ({ order_id, orderAreas }: any) => {
  const [height, setHeight] = useState<number | string>(0);
  const [errors, setErrors] = useState<Errors | undefined>(undefined);
  const [taskId, setTaskId] = useState<string>('');

  const areaIds = orderAreas.map(({ order_area_id }: OrderArea) => order_area_id);

  // Selected OrderAreaIds, Dates and Environment
  const [selectedAreas, setSelectedAreas] = useState<number[]>(areaIds);
  const [lastSelectedArea, setLastSelectedArea] = useState<number>(-1);
  const [selectedFromDate, setSelectedFromDate] = useState<MaterialUiPickersDate | null>(null);
  const [selectedToDate, setSelectedToDate] = useState<MaterialUiPickersDate | null>(null);
  const [selectedEnvironment, setSelectedEnvironment] = useState<EnvironmentType>('develop');

  // Selected Recreate Flags
  const [recreateIsochrones, setRecreateIsochrones] = useState<boolean>(false);
  const [recreateDemographics, setRecreateDemographics] = useState<boolean>(false);
  const [recreatePlaces, setRecreatePlaces] = useState<boolean>(true);
  const [recreateRelevanceOverTime, setRecreateRelevanceOverTime] = useState<boolean>(false);
  const [recreateChatterMetric, setRecreateChatterMetric] = useState<boolean>(false);
  const [recreatePresenceDwell, setRecreatePresenceDwell] = useState<boolean>(false);
  const [recreatePresenceVisitation, setRecreatePresenceVisitation] = useState<boolean>(false);
  const [recreateKeywords, setRecreateKeywords] = useState<boolean>(false);

  // Selected Enabled Flags
  const [isochronesEnabled, setIsochronesEnabled] = useState<boolean>(true);
  const [demographicsEnabled, setDemographicsEnabled] = useState<boolean>(true);
  const [relevanceOverTimeEnabled, setRelevanceOverTimeEnabled] = useState<boolean>(true);
  const [chatterMetricEnabled, setChatterMetricEnabled] = useState<boolean>(true);
  const [presenceEnabled, setPresenceEnabled] = useState<boolean>(true);
  const [keywordsEnabled, setKeywordsEnabled] = useState<boolean>(true);
  const [categorisationEngine, setCategorisationEngine] =
    useState<CategorisationEngineType>('matrix_engine-2.0');
  const [relevanceVersion, setRelevanceVersion] = useState<RelevanceVersionType>('community-v3');
  const [presenceDateToExclusive, setPresenceDateToExclusive] =
    useState<MaterialUiPickersDate | null>(null);

  const colorContext = useContext(ThemeContext);

  // If in URL ?disableplacesmap=1, disables PlacesMap
  const disablePlacesMap: boolean = getURLParam('disableplacesmap') === '1';

  const [exportProductXArchive, { loading }] = useMutation(EXPORT_PRODUCT_X_ARCHIVE, {
    variables: {
      order_id,
      area_ids: selectedAreas, // areaIds (only order_area_ids, ...area_ids)
      selected_to_date: parseMUIDate(selectedToDate),
      selected_from_date: parseMUIDate(selectedFromDate),
      environment: selectedEnvironment,
      isochrones_recreate: recreateIsochrones,
      demographics_recreate: recreateDemographics,
      places_recreate: recreatePlaces,
      relevance_over_time_recreate: recreateRelevanceOverTime,
      chatter_metric_recreate: recreateChatterMetric,
      presence_dwell_recreate: recreatePresenceDwell,
      presence_visitation_recreate: recreatePresenceVisitation,
      keywords_recreate: recreateKeywords,
      isochrones_enabled: isochronesEnabled,
      demographics_enabled: demographicsEnabled,
      relevance_over_time_enabled: relevanceOverTimeEnabled,
      chatter_metric_enabled: chatterMetricEnabled,
      presence_enabled: presenceEnabled,
      keywords_enabled: keywordsEnabled,
      categorisation_engine: categorisationEngine,
      relevance_version: relevanceVersion,
      presence_date_to_exclusive: parseMUIDate(presenceDateToExclusive),
    },
    onCompleted: ({ exportProductXArchive: { taskId } }) => {
      if (taskId) setTaskId(taskId);
    },
    onError: (err) => {
      setErrors({ message: `${err.message}` } as Errors);
    },
  });

  const enabledFlags: RecreateFlag[] = [
    {
      name: 'isochronesEnabled',
      label: 'Isochrones',
      checked: isochronesEnabled,
      onChange: () => {
        setIsochronesEnabled(!isochronesEnabled);
      },
    },
    {
      name: 'demographicsEnabled',
      label: 'Demographics',
      checked: demographicsEnabled,
      onChange: () => {
        setDemographicsEnabled(!demographicsEnabled);
      },
    },
    {
      name: 'relevanceOverTimeEnabled',
      label: 'Relevance Over Time',
      checked: relevanceOverTimeEnabled,
      onChange: () => {
        setRelevanceOverTimeEnabled(!relevanceOverTimeEnabled);
      },
    },

    {
      name: 'chatterMetricEnabled',
      label: 'Chatter',
      checked: chatterMetricEnabled,
      onChange: () => {
        setChatterMetricEnabled(!chatterMetricEnabled);
      },
    },
    {
      name: 'presenceEnabled',
      label: 'Presence',
      checked: presenceEnabled,
      onChange: () => {
        setPresenceEnabled(!presenceEnabled);
      },
    },
    {
      name: 'keywordsEnabled',
      label: 'Keywords',
      checked: keywordsEnabled,
      onChange: () => {
        setKeywordsEnabled(!keywordsEnabled);
      },
    },
  ];

  const exportProductXClick = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (selectedAreas.length <= 0) {
      setErrors({
        message: `Please select at least one area`,
      } as any);
    } else {
      setErrors(undefined);
      exportProductXArchive();
    }
  };

  useEffect(() => {
    colorContext.color != 'primary' && colorContext.setColor('primary');
  }, []);

  useEffect(() => {
    setHeight(() => 'auto');
    setSelectedAreas(areaIds);
  }, [orderAreas]);

  return (
    <AnimateHeight duration={500} height={height}>
      <Spacer>
        <Grid container spacing={3}>
          <Grid container item xs={8} md={5} lg={7} xl={7} alignContent="flex-start" spacing={10}>
            <Grid item xs={12} lg={5}>
              <FormControl fullWidth>
                <FormLabel>Select dates</FormLabel>

                <DateSelector
                  type="From"
                  disabled={loading}
                  color={'primary'}
                  selectedDate={selectedFromDate}
                  setSelectedDate={setSelectedFromDate}
                />
                <DateSelector
                  type="To"
                  disabled={loading}
                  color={'primary'}
                  selectedDate={selectedToDate}
                  setSelectedDate={setSelectedToDate}
                />
              </FormControl>
              <FormControl fullWidth>
                <DateSelector
                  type="Presence"
                  disabled={loading}
                  color={'primary'}
                  selectedDate={presenceDateToExclusive}
                  setSelectedDate={setPresenceDateToExclusive}
                />

                <FormFieldLabel>Categorisation Engine</FormFieldLabel>
                <TextField
                  id="engine"
                  type="filter"
                  name="engine"
                  placeholder="leave empty for default"
                  value={categorisationEngine}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    const { value } = event.target;
                    setCategorisationEngine(value);
                  }}
                  required
                />
                <FormFieldLabel>Relevance Version</FormFieldLabel>
                <TextField
                  id="relevanceVersion"
                  type="filter"
                  name="relevanceVersion"
                  placeholder="leave empty for default"
                  value={relevanceVersion}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    const { value } = event.target;
                    setRelevanceVersion(value);
                  }}
                  required
                />
              </FormControl>

              <Spacer />

              <Grid item>
                <FormLabel>Enabled flags</FormLabel>
                {enabledFlags.map((flag: RecreateFlag) => {
                  return (
                    <Grid item xs={12} key={flag.name}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name={flag.name}
                            color="primary"
                            checked={flag.checked}
                            onChange={flag.onChange}
                            disabled={loading}
                          />
                        }
                        label={flag.label}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <PXRecreateForm
                disabled={loading}
                selectedEnvironment={selectedEnvironment}
                setSelectedEnvironment={setSelectedEnvironment}
                recreateChatterMetric={recreateChatterMetric}
                recreateDemographics={recreateDemographics}
                recreateIsochrones={recreateIsochrones}
                recreatePlaces={recreatePlaces}
                recreatePresenceDwell={recreatePresenceDwell}
                recreatePresenceVisitation={recreatePresenceVisitation}
                recreateKeywords={recreateKeywords}
                recreateRelevanceOverTime={recreateRelevanceOverTime}
                setRecreateChatterMetric={setRecreateChatterMetric}
                setRecreateDemographics={setRecreateDemographics}
                setRecreateIsochrones={setRecreateIsochrones}
                setRecreatePlaces={setRecreatePlaces}
                setRecreatePresenceDwell={setRecreatePresenceDwell}
                setRecreatePresenceVisitation={setRecreatePresenceVisitation}
                setRecreateRelevanceOverTime={setRecreateRelevanceOverTime}
                setRecreateKeywords={setRecreateKeywords}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography component="h6" variant="caption" gutterBottom>
                <StyledList>
                  <li>
                    Choose dates allowing 2-3 days buffer before and after data was converted to
                    NL_Places to account for processing time, timezone variations, etc.
                  </li>
                  <li>
                    If exporting immediately following NL_Place creation, leave 'Date To...' blank,
                    to gather latest data.
                  </li>
                  <li>
                    To gather all data for the locations in our database, leave 'Date From...' and
                    'Date To...' blank.
                  </li>
                </StyledList>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <SubmitJob
                taskClickAction={exportProductXClick}
                taskId={taskId}
                taskName={'Export ProductX Archive'}
                errors={errors}
                loading={loading}
                disabled={loading}
              />
              {!disablePlacesMap && (
                <PlacesMap
                  areas={orderAreas}
                  selectedAreas={selectedAreas}
                  lastSelectedArea={lastSelectedArea}
                  color={'primary'}
                />
              )}
            </Grid>
          </Grid>
          <Grid container item xs={4} md={7} lg={5} xl={5} alignContent="flex-start">
            <OrderAreaForm
              selectedAreas={selectedAreas}
              setLastSelectedArea={setLastSelectedArea}
              setSelectedAreas={setSelectedAreas}
              orderAreas={orderAreas}
              disabled={loading}
            />
          </Grid>
        </Grid>
      </Spacer>
    </AnimateHeight>
  );
};

export default ProductXExport;
