import React, { FC, useState, createContext } from 'react';

// Types
import { ScraperContextType } from './ScraperProvider.types';
import { ChatterCollection, ChatterLocation } from 'Pages/Order/Order.types';

export const ScraperContext = createContext<ScraperContextType>({
  chatterCollections: [],
  setChatterCollections: () => null,
  selectedLocations: [],
  setSelectedLocations: () => null,
});

export const useScraperContext = (): ScraperContextType => {
  const scraperContext = React.useContext(ScraperContext);

  if (!scraperContext) {
    throw new Error('useScraperContext must be used within a ScraperContext.Provider');
  }

  return scraperContext;
};

const ScraperProvider: FC = ({ children }) => {
  const [chatterCollections, setChatterCollections] = useState<ChatterCollection[]>([]);
  const [selectedLocations, setSelectedLocations] = useState<ChatterLocation[]>([]);

  return (
    <ScraperContext.Provider
      value={{
        chatterCollections: chatterCollections,
        setChatterCollections: setChatterCollections,
        selectedLocations: selectedLocations,
        setSelectedLocations: setSelectedLocations,
      }}
    >
      {children}
    </ScraperContext.Provider>
  );
};

export default ScraperProvider;
