import { FC, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { TableCell, TableBody, Checkbox } from '@material-ui/core';
import { StyledTableRow } from './OrderList.styles';
import { OrderProps } from 'Pages/Order/Order.types';
import { formatDate } from 'helpers';

interface RowProps {
  order: OrderProps[];
}

const Rows: FC<RowProps> = ({ order }) => {
  const history = useHistory();
  const handleOnClick = useCallback((order_link: string) => history.push(order_link), [history]);

  if (order === undefined) return null;
  return (
    <TableBody>
      {order.length > 0 &&
        order.map(
          ({ name, order_id, order_no, requested_by, date_of_creation, ppaCheck }: OrderProps) => (
            <StyledTableRow key={order_id} onClick={() => handleOnClick(`/order/${order_id}`)}>
              <TableCell component="th" scope="row">
                {name ? name : 'No name'}
              </TableCell>
              <TableCell align="center">{order_no}</TableCell>
              <TableCell align="center">{order_id}</TableCell>
              <TableCell align="center">
                <Checkbox checked={ppaCheck} color={'default'} />
              </TableCell>
              <TableCell>{formatDate(date_of_creation)}</TableCell>
              <TableCell align="right">{requested_by}</TableCell>
            </StyledTableRow>
          )
        )}
    </TableBody>
  );
};

export default Rows;
