import { FC, useState } from 'react';
import { Typography } from '@material-ui/core';
import { StyledListItem } from 'Components/Menu/Menu.styles';
import { StyledListContentBlock } from './TaskDetails.styles';

export interface ResultDetails {
  name: string;
  children: string[];
  status: string;
  waiting: boolean;
  results: any;
  taskId?: string;
  taskError?: string;
  completedCount: number;
  taskName?: string;
  ready: boolean;
  args: string;
  kwargs: string;
  queue: string;
  traceback: string;
}

const Results: FC<ResultDetails> = ({
  taskId,
  results,
  ready,
  args,
  kwargs,
  name,
  status,
  queue,
  traceback,
}) => {
  const [height, setHeight] = useState<number | string>(0);
  return (
    <StyledListItem
      component="div"
      alignItems="flex-start"
      onClick={() => setHeight(height === 0 ? 'auto' : 0)}
    >
      <StyledListContentBlock>
        <>
          <Typography variant="body2" component="p">
            <b>Name:</b> {name}
          </Typography>
          <Typography variant="body2" component="p">
            <b>ID:</b> {taskId}
          </Typography>
          <Typography variant="body2" component="p">
            <b>Status:</b> {status}
          </Typography>
          <Typography variant="body2" component="p">
            <b>Queue:</b> {queue}
          </Typography>
        </>
        <Typography variant="body2" component="p">
          <b>Results:</b> {results}
        </Typography>
        <Typography variant="body2" component="p">
          <b>Ready:</b> {`${ready}`}
        </Typography>
        <Typography variant="body2" component="p">
          <b>Task arguments:</b> {args}
        </Typography>

        <Typography variant="body2" component="p">
          <b>Task kwarguments:</b> {kwargs}
        </Typography>
        <Typography variant="body2" component="p">
          <b>Error logs:</b> {traceback ? traceback : 'No Errors'}
        </Typography>
      </StyledListContentBlock>
    </StyledListItem>
  );
};

export default Results;
