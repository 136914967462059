import React, { FC, useState, ChangeEvent, FormEvent } from 'react';
import { Grid, TextField } from '@material-ui/core';

import Button from 'Components/Button';

import { LatLonProps } from 'Pages/ExportKml/ExportKml.types';

type LatLonSelectorProps = Pick<LatLonProps, 'locationsList' | 'setLocationsList' | 'disabled'>;

const LatLonSelector: FC<LatLonSelectorProps> = ({ locationsList, setLocationsList, disabled }) => {
  const [inputlocationName, setInputLocationName] = useState<string | undefined>(undefined);
  const [inputLatitude, setInputLatitude] = useState<string | undefined>(undefined);
  const [inputLongitude, setInputLongitude] = useState<string | undefined>(undefined);

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputLocationName(event.target.value);
  };
  const handleLatChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputLatitude(event.target.value);
  };
  const handleLonChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputLongitude(event.target.value);
  };

  const handleAppendLocation = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (
      inputlocationName === undefined ||
      inputLatitude === undefined ||
      inputLongitude === undefined
    )
      return;
    const input = {
      locationName: inputlocationName,
      latitude: inputLatitude,
      longitude: inputLongitude,
    };
    setLocationsList([...locationsList, input]);
    setInputLocationName(undefined);
    setInputLatitude(undefined);
    setInputLongitude(undefined);
  };

  return (
    <>
      <Grid container alignContent="flex-start" spacing={3}>
        <Grid item xs={2}>
          <form onSubmit={handleAppendLocation}>
            <TextField
              id="input-location-name"
              label="Location Name"
              name="inputLocationName"
              value={inputlocationName || ''}
              type="string"
              onChange={handleNameChange}
              disabled={disabled}
              required
            />
            <TextField
              id="input-latitude"
              type="text"
              label="Latitude"
              name="inputLatitude"
              value={inputLatitude || ''}
              onChange={handleLatChange}
              disabled={disabled}
              required
            />
            <TextField
              id="input-longitude"
              type="text"
              label="Longitude"
              name="input-Longitude"
              value={inputLongitude || ''}
              onChange={handleLonChange}
              disabled={disabled}
              required
            />
            <Button type="submit" variant="form" color="primary" disabled={disabled}>
              Select Location
            </Button>
          </form>
        </Grid>
      </Grid>
    </>
  );
};

export default LatLonSelector;
