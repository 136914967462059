import styled from 'styled-components';

export const TableWrapper = styled.div`
  display: table;
  overflow: hidden;
  white-space: wrap;
`;

export const FixedSidebar = styled.div`
  position: absolute;
  right: 10px;
  border: #999 1px solid;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  overflow-y: auto;
`;

export const TableHeader = styled.div`
  display: table-header-group;
  > div {
    cursor: default;
    color: #777;
  }
`;

export const TableRow = styled.div<{ disabled: boolean | undefined }>`
  display: table-row;
  ${(props) => `
    cursor: ${props.disabled ? 'default' : 'pointer'};
  `};
  > div {
    &:nth-child(2) {
      width: 250px;
      min-width: 250px;
    }
  }
`;

export const TableCell = styled.div`
  display: table-cell;
  padding: 1rem;
  }
`;
