import { useState, useEffect, createContext, useContext } from 'react';
import { useLazyQuery } from '@apollo/client';
import toast from 'react-hot-toast';

import { useAuth } from 'Providers/AuthProvider';

import { GET_FIREBASE_USERS } from 'Queries';

type FirebaseUsersQueryData = {
  firebase_user: FirebaseUserDetails[];
};

type FirebaseUserDetails = {
  display_name: string;
  firebase_user_id: number;
  roles: string[];
  firebase_identifier: string;
};

type UserContextType = {
  users: FirebaseUserDetails[];
  loggedInUser: FirebaseUserDetails | undefined;
};

const UserContext = createContext<UserContextType>({
  users: [],
  loggedInUser: undefined,
} as UserContextType);
export const useUsers = () => useContext(UserContext);

export const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const [users, setUsers] = useState<FirebaseUserDetails[]>([]);
  const [loggedInUser, setLoggedInUser] = useState<FirebaseUserDetails | undefined>(undefined);

  const { getInfo } = useAuth();
  const { userId } = getInfo();

  const [getFirebaseUsers] = useLazyQuery<FirebaseUsersQueryData>(GET_FIREBASE_USERS, {
    onCompleted: (data) => {
      const loggedIn = data.firebase_user.find((usr) => usr.firebase_identifier === userId);

      setUsers(data.firebase_user);
      setLoggedInUser(loggedIn);
    },
    // onError: () => {
    //   toast.error(
    //     'Something went wrong when trying to retrieve user details. Please refresh the page. If this problem persists, please contact administrator.'
    //   );
    // },
  });

  useEffect(() => {
    if (userId !== 'none') getFirebaseUsers();
  }, [userId]);

  return (
    <UserContext.Provider
      value={{
        users,
        loggedInUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
