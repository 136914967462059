import { FC } from 'react';

import { TableCell, TableRow, LinearProgress, Box } from '@material-ui/core';

import TaggingRowEditJob from './TaggingRowEditJob';
import TaggingRowStatistics from './TaggingRowStatistics';
import TaggingRowOrderDetails from './TaggingRowOrderDetails';

import { TaggingRowsProps } from '../TaggingJobList.types';

import { StyledTableCell, StyledButton, LongDiv } from '../TaggingJobList.styles';

import { convertToLocalDate } from 'helpers';

const Rows: FC<TaggingRowsProps> = ({
  data,
  doDataUpdate,
  canEditJob,
  diplayOrderDetails,
  editingJob,
  setEditingJob,
}) => {
  const onEditJobClick = (job_id: number) => {
    setEditingJob(job_id);
  };

  return (
    <>
      {data.map((job, index) => {
        const disabled = job.job_status === 'closed';
        const locationImages = job.chatter_location.chatter_images_aggregate.aggregate.count;
        const jobImages = job.tagging_job_images_aggregate.aggregate.count;

        const taggedJobImages = job.tagging_job_images_tagged_aggregate.aggregate.count;
        const tagsForReview = job.tags_for_review_aggregate.aggregate.count;
        const ignoredImages = job.ignored_images_aggregate.aggregate.count;

        const percentTagged: number = Math.round((taggedJobImages / jobImages) * 100);
        const percentForReview: number = Math.round((tagsForReview / jobImages) * 100);

        if (job.tagging_job_id === editingJob)
          return (
            <TaggingRowEditJob
              key={index}
              data={job}
              diplayOrderDetails={diplayOrderDetails}
              onComplete={() => {
                doDataUpdate();
                setEditingJob(-1);
              }}
            />
          );

        return (
          <TableRow key={index}>
            {
              /* Urkel ID, Wilson ID, Order Name */
              <TaggingRowOrderDetails
                urkelOrderId={job.chatter_location.chatter_collection.order.order_id}
                orderNo={job.chatter_location.chatter_collection.order.order_no}
                orderName={job.chatter_location.chatter_collection.order.name}
                diplayOrderDetails={diplayOrderDetails}
              />
            }
            <TableCell>
              {/* Location Identifier */}
              <a href={job.chatter_location.source_link} target="blank">
                {job.chatter_location.source_location_identifier}
              </a>
            </TableCell>
            <TableCell padding="none">{locationImages}</TableCell>
            <TableCell>
              {/* Job Creator */}
              {job.tagging_job_creator_to_firebase_user.display_name}
            </TableCell>
            <TableCell variant="footer" padding="none">
              <LongDiv>
                {job.notes}
                {job.notes.includes('modulus')
                  ? ''
                  : `(estimated modulus ${(locationImages / jobImages).toFixed(1)})`}
              </LongDiv>
            </TableCell>
            <TableCell>{convertToLocalDate(job.date_of_creation)}</TableCell>
            <TableCell>
              {/* Job Tagger */}
              {job.tagging_job_tagger_to_firebase_user.display_name}
            </TableCell>
            <TaggingRowStatistics
              disabled={disabled}
              jobImages={jobImages}
              taggedJobImages={taggedJobImages}
              percentTagged={percentTagged}
              tagsForReview={tagsForReview}
              ignoredImages={ignoredImages}
            />
            <StyledTableCell>
              {job.job_status}
              <LinearProgress variant="determinate" value={percentTagged - percentForReview} />
            </StyledTableCell>
            <TableCell>
              {/* Job Tools */}
              <LongDiv>
                <Box
                  component="span"
                  display="flex"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                >
                  {canEditJob && editingJob < 0 && (
                    <StyledButton
                      variant="form"
                      color="primary"
                      onClick={() => {
                        onEditJobClick(job.tagging_job_id);
                      }}
                    >
                      Edit Job
                    </StyledButton>
                  )}
                  {(job.job_status === 'open' ||
                    job.job_status === 'submitted' ||
                    job.job_status === 'closed') && (
                    <StyledButton
                      to={`/tagging-job/${job.tagging_job_id}`}
                      variant="form"
                      color="neighbourlytics-secondary-blue"
                    >
                      Open Tagging
                    </StyledButton>
                  )}
                </Box>
              </LongDiv>
            </TableCell>
          </TableRow>
        );
      })}
    </>
  );
};

export default Rows;
