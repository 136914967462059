import { FC, ChangeEvent, useState, useEffect, FormEvent, useContext } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { ThemeContext } from 'Providers/ThemeProvider';

import {
  FormControl,
  FormControlLabel,
  Checkbox,
  Grid,
  Typography,
  FormLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import AnimateHeight from 'react-animate-height';

// Styles
import { StyledList, VerticalSpacer } from './RunOrderAreaPlacesQuery.styles';

import { Errors } from 'Components/Error/Error.types';
import OrderAreaForm from 'Components/OrderAreaForm';
import PlacesMap from 'Components/PlacesMap';
import Spacer from 'Components/Spacer';
import DateSelector from 'Components/DateSelector';
import SubmitJob from 'Components/SubmitJob';
import CollectionDatesTable from './CollectionDatesTable';

import { GET_DATAPLOR_DROPS, RUN_ORDER_AREA_PLACES_QUERY_AND_NL_PLACES } from 'Queries';
import { OrderArea } from 'Pages/Order/Order.types';

// Helpers
import { getURLParam, parseMUIDate } from 'helpers';

interface RunOrderAreaPlacesQuery {
  order_id: number;
  orderAreas: OrderArea[];
}

interface RunOrderAreaPlacesQueryReturn {
  runPlacesOrderQueryNlPlaces: {
    taskId?: string;
    error?: { message: string };
  };
}

type EnabledFlag = {
  name: string;
  label: string;
  checked: boolean;
  onChange: () => void;
};

type GoogleApi = {
  api: 'google';
};

type DataplorApi = {
  api: 'dataplor';
  dropId: number;
};

type DataplorDrop = {
  dropId: number;
  date: Date;
};

type PlacesApi = GoogleApi | DataplorApi;

const RunOrderAreaPlacesQuery: FC<RunOrderAreaPlacesQuery> = ({
  order_id,
  orderAreas,
}: RunOrderAreaPlacesQuery) => {
  const [taskId, setTaskId] = useState<string | undefined>(undefined);
  const [errors, setErrors] = useState<Errors | undefined>(undefined);
  const [height, setHeight] = useState<number | string>(0);

  const orderAreaIds: number[] = orderAreas.map(({ order_area_id }) => order_area_id);

  // Form States
  const [selectedAreas, setSelectedAreas] = useState<number[]>(orderAreaIds);
  const [lastSelectedArea, setLastSelectedArea] = useState<number>(-1);
  const [populatePpa, setPopulatePpa] = useState<boolean>(false);
  const [resetProgress, setResetProgress] = useState<boolean>(false);
  const [dateOfCollection, setDateOfCollection] = useState<MaterialUiPickersDate | null>(null);
  const [dataplorDrops, setDataplorDrops] = useState<DataplorDrop[]>([]);
  const [dataplorDropId, setDataplorDropId] = useState<PlacesApi>({ api: 'google' });

  // Selected Enabled Flags
  const [demographicsEnabled, setDemographicsEnabled] = useState<boolean>(true);
  const [isochronesEnabled, setIsochronesEnabled] = useState<boolean>(true);
  const [relevanceOverTimeEnabled, setRelevanceOverTimeEnabled] = useState<boolean>(true);
  const [presenceEnabled, setPresenceEnabled] = useState<boolean>(true);
  const [keywordsEnabled, setKeywordsEnabled] = useState<boolean>(true);
  const [chatterEnabled, setChatterEnabled] = useState<boolean>(true);

  const colorContext = useContext(ThemeContext);

  // If in URL ?disableplacesmap=1, disables PlacesMap
  const disablePlacesMap: boolean = getURLParam('disableplacesmap') === '1';

  const [runPlacesOrderQueryNlPlaces, { loading }] = useMutation<RunOrderAreaPlacesQueryReturn>(
    RUN_ORDER_AREA_PLACES_QUERY_AND_NL_PLACES,
    {
      variables: {
        orderId: order_id,
        orderAreaIds: selectedAreas,
        dateOfCollection: parseMUIDate(dateOfCollection),
        dataplorDropId: dataplorDropId.api === 'google' ? null : dataplorDropId.dropId,
        populatePpa: populatePpa,
        resetProgress: resetProgress,
        demographicsEnabled: populatePpa && demographicsEnabled,
        isochronesEnabled: populatePpa && isochronesEnabled,
        relevanceOverTimeEnabled: populatePpa && relevanceOverTimeEnabled,
        presenceEnabled: populatePpa && presenceEnabled,
        keywordsEnabled: populatePpa && keywordsEnabled,
        chatterEnabled: populatePpa && chatterEnabled,
      },
      onCompleted: ({
        runPlacesOrderQueryNlPlaces: { taskId, error },
      }: RunOrderAreaPlacesQueryReturn) => {
        if (taskId) setTaskId(taskId);
        if (error) setErrors({ message: `${error.message}` } as Errors);
      },
      onError: (err) => {
        setErrors({ message: `${err.message}` } as Errors);
      },
    }
  );

  const { loading: dataPlorDropsLoading } = useQuery(GET_DATAPLOR_DROPS, {
    onCompleted: ({ places_dataplor_drop }) => {
      const drops = places_dataplor_drop.map((d: { id: number; date: string }) => ({
        dropId: d.id,
        date: new Date(d.date + 'Z'),
      }));
      setDataplorDrops(drops);
      const latest: PlacesApi =
        drops.length > 0 ? { api: 'dataplor', dropId: drops[0].dropId } : { api: 'google' };
      setDataplorDropId(latest);
    },
  });

  const enabledFlags: EnabledFlag[] = [
    {
      name: 'isochronesEnabled',
      label: 'Isochrones',
      checked: isochronesEnabled,
      onChange: () => {
        setIsochronesEnabled(!isochronesEnabled);
      },
    },
    {
      name: 'demographicsEnabled',
      label: 'Demographics',
      checked: demographicsEnabled,
      onChange: () => {
        setDemographicsEnabled(!demographicsEnabled);
      },
    },
    {
      name: 'relevanceOverTimeEnabled',
      label: 'Relevance Over Time',
      checked: relevanceOverTimeEnabled,
      onChange: () => {
        setRelevanceOverTimeEnabled(!relevanceOverTimeEnabled);
      },
    },

    {
      name: 'chatterEnabled',
      label: 'Chatter',
      checked: chatterEnabled,
      onChange: () => {
        setChatterEnabled(!chatterEnabled);
      },
    },
    {
      name: 'presenceEnabled',
      label: 'Presence',
      checked: presenceEnabled,
      onChange: () => {
        setPresenceEnabled(!presenceEnabled);
      },
    },
    {
      name: 'keywordsEnabled',
      label: 'Keywords',
      checked: keywordsEnabled,
      onChange: () => {
        setKeywordsEnabled(!keywordsEnabled);
      },
    },
  ];

  const runPlacesQueryClick = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setErrors(undefined);
    runPlacesOrderQueryNlPlaces();
  };

  useEffect(() => {
    colorContext.color != 'secondary' && colorContext.setColor('secondary');
  }, []);

  useEffect(() => {
    setHeight(() => 'auto');
    setSelectedAreas(orderAreaIds);
  }, [orderAreas]);

  useEffect(() => {
    if (populatePpa && selectedAreas.length !== orderAreaIds.length) {
      setPopulatePpa(false);
    }
    const places: boolean = selectedAreas.length > 0;
    places
      ? setErrors(undefined)
      : setErrors({ message: `Please select at least one location` } as Errors);
  }, [orderAreas, selectedAreas]);

  const handlePpaChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPopulatePpa(event.target.checked);
  };

  const handleProgressChange = (event: ChangeEvent<HTMLInputElement>) => {
    setResetProgress(event.target.checked);
  };

  const taskInstructions = (
    <Grid item xs={12}>
      <Typography component="h6" variant="caption" gutterBottom>
        <StyledList>
          <li>
            When collecting a new collection, select 'Populate PPA and PPAOA?' to save a new PPA
            record for later exports
          </li>
          <ul>
            <li>
              All flags will be selected to be set TRUE by default. Deselect a flag to store as
              FALSE
            </li>
          </ul>
          <li>
            Check if collection progress for these order_areas should be reset for this new
            collection
          </li>
          <li>Select a specific date to record for date of collection</li>
          <li>On the right, select all or partial order_areas to be queried for places</li>
        </StyledList>
      </Typography>
    </Grid>
  );

  const placesQueryForm = (
    <FormControl>
      <FormControlLabel
        label="Reset Collection Progress?"
        control={
          <Checkbox
            disabled={loading}
            id="reset-progress"
            checked={resetProgress}
            color="secondary"
            onChange={handleProgressChange}
          />
        }
      />
      <DateSelector
        type="Date of Collection"
        disabled={loading}
        color={'secondary'}
        selectedDate={dateOfCollection}
        setSelectedDate={setDateOfCollection}
      />
      <VerticalSpacer />
      <CollectionDatesTable order_id={order_id} />
    </FormControl>
  );

  return (
    <AnimateHeight duration={500} height={height}>
      <Spacer>
        <Grid container spacing={3} alignContent="flex-start">
          <Grid item xs={8} md={5} lg={7} xl={7}>
            <Grid container>
              <Grid item xs={6}>
                <FormControl fullWidth={false} margin="dense">
                  <FormLabel>Select places collection API</FormLabel>
                  <Select
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                    disabled={dataPlorDropsLoading}
                    value={
                      dataplorDropId.api === 'google' ? Number.MIN_VALUE : dataplorDropId.dropId
                    }
                    onChange={(e) => {
                      const val = e.target.value as number;
                      if (val === Number.MIN_VALUE) {
                        setDataplorDropId({ api: 'google' });
                      } else {
                        setDataplorDropId({ api: 'dataplor', dropId: val });
                      }
                    }}
                  >
                    <MenuItem value={Number.MIN_VALUE}>Google API</MenuItem>
                    {dataplorDrops.map((drop: DataplorDrop) => (
                      <MenuItem key={drop.dropId} value={drop.dropId}>
                        Dataplor Drop {drop.dropId} ({drop.date.toLocaleDateString()})
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControlLabel
                  label="Populate PPA and PPAOA?"
                  control={
                    <Checkbox
                      disabled={loading || !(selectedAreas.length > 0)}
                      id="populate-ppa"
                      checked={populatePpa}
                      onChange={handlePpaChange}
                    />
                  }
                />
                {enabledFlags.map((flag: EnabledFlag) => {
                  return (
                    <Grid item xs={12} key={flag.name}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name={flag.name}
                            color="secondary"
                            checked={flag.checked}
                            onChange={flag.onChange}
                            disabled={!populatePpa}
                          />
                        }
                        label={flag.label}
                      />
                    </Grid>
                  );
                })}
              </Grid>
              <Grid item xs={6}>
                {placesQueryForm}
              </Grid>
            </Grid>
            {taskInstructions}
            <SubmitJob
              taskClickAction={runPlacesQueryClick}
              taskId={taskId}
              taskName={'OA Places Query'}
              errors={errors}
              loading={loading}
              disabled={selectedAreas.length === 0}
            />
            {!disablePlacesMap && (
              <PlacesMap
                areas={orderAreas}
                selectedAreas={selectedAreas}
                lastSelectedArea={lastSelectedArea}
                color={'secondary'}
              />
            )}
          </Grid>
          <Grid item xs={4} md={7} lg={5} xl={5}>
            <OrderAreaForm
              selectedAreas={selectedAreas}
              setLastSelectedArea={setLastSelectedArea}
              setSelectedAreas={setSelectedAreas}
              orderAreas={orderAreas}
              disabled={loading}
            />
            <Spacer />
          </Grid>
        </Grid>
      </Spacer>
    </AnimateHeight>
  );
};

export default RunOrderAreaPlacesQuery;
