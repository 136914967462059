import { useState, createContext, SetStateAction } from 'react';
import { StylesProvider, ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { defaultMuiTheme } from 'Components/GlobalStyles/Theme';

type ColorTheme = 'primary' | 'secondary' | 'default';

type ThemeContextType = {
  color: ColorTheme;
  setColor: (value: SetStateAction<ColorTheme>) => void;
};

export const ThemeContext = createContext<ThemeContextType>({
  color: 'primary',
  setColor: () => null,
} as ThemeContextType);

export const ThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const [color, setColor] = useState<ColorTheme>('primary');

  return (
    <ThemeContext.Provider value={{ color: color, setColor: setColor }}>
      <StylesProvider injectFirst>
        <MuiThemeProvider theme={defaultMuiTheme}>{children}</MuiThemeProvider>
      </StylesProvider>
    </ThemeContext.Provider>
  );
};
