import { Dispatch, SetStateAction } from 'react';

import { Grid, FormControlLabel, FormLabel, Select, MenuItem, Checkbox } from '@material-ui/core';

import { StyledFormControl, StyledGridList } from './PXRecreateForm.styles';

import { EnvironmentType, RecreateFlag } from '../ProductXExport/ProductXExport.types';

interface RecreateFormProps {
  disabled: boolean;
  selectedEnvironment: EnvironmentType;
  setSelectedEnvironment: Dispatch<SetStateAction<EnvironmentType>>;
  recreateChatterMetric: boolean;
  recreateDemographics: boolean;
  recreateIsochrones: boolean;
  recreatePlaces: boolean;
  recreatePresenceVisitation: boolean;
  recreatePresenceDwell: boolean;
  recreateRelevanceOverTime: boolean;
  recreateKeywords: boolean;
  setRecreateChatterMetric: Dispatch<SetStateAction<boolean>>;
  setRecreateDemographics: Dispatch<SetStateAction<boolean>>;
  setRecreateIsochrones: Dispatch<SetStateAction<boolean>>;
  setRecreatePlaces: Dispatch<SetStateAction<boolean>>;
  setRecreatePresenceDwell: Dispatch<SetStateAction<boolean>>;
  setRecreatePresenceVisitation: Dispatch<SetStateAction<boolean>>;
  setRecreateRelevanceOverTime: Dispatch<SetStateAction<boolean>>;
  setRecreateKeywords: Dispatch<SetStateAction<boolean>>;
}

const PXRecreateForm = ({
  disabled,
  selectedEnvironment,
  setSelectedEnvironment,
  recreateChatterMetric,
  recreateDemographics,
  recreateIsochrones,
  recreatePlaces,
  recreatePresenceVisitation,
  recreatePresenceDwell,
  recreateRelevanceOverTime,
  recreateKeywords,
  setRecreateChatterMetric,
  setRecreateDemographics,
  setRecreateIsochrones,
  setRecreatePlaces,
  setRecreatePresenceDwell,
  setRecreatePresenceVisitation,
  setRecreateRelevanceOverTime,
  setRecreateKeywords,
}: RecreateFormProps) => {
  const recreateFlags: RecreateFlag[] = [
    // As these are hardcoded, please remember to organise this list by recreateFlags.name asc    (easier for the FE user to read)
    {
      name: 'chatterMetric',
      label: 'Chatter Metric',
      checked: recreateChatterMetric,
      onChange: () => {
        setRecreateChatterMetric(!recreateChatterMetric);
      },
    },
    {
      name: 'demographics',
      label: 'Demographics',
      checked: recreateDemographics,
      onChange: () => {
        setRecreateDemographics(!recreateDemographics);
      },
    },
    {
      name: 'isochrones',
      label: 'Isochrones',
      checked: recreateIsochrones,
      onChange: () => {
        setRecreateIsochrones(!recreateIsochrones);
      },
    },

    {
      name: 'places',
      label: 'Places',
      checked: recreatePlaces,
      onChange: () => {
        setRecreatePlaces(!recreatePlaces);
      },
    },
    {
      name: 'presenceDwell',
      label: 'Presence (Dwell)',
      checked: recreatePresenceDwell,
      onChange: () => {
        setRecreatePresenceDwell(!recreatePresenceDwell);
      },
    },
    {
      name: 'presenceVisitation',
      label: 'Presence (Visitation)',
      checked: recreatePresenceVisitation,
      onChange: () => {
        setRecreatePresenceVisitation(!recreatePresenceVisitation);
      },
    },
    {
      name: 'relevanceOverTime',
      label: 'Relevance Over Time',
      checked: recreateRelevanceOverTime,
      onChange: () => {
        setRecreateRelevanceOverTime(!recreateRelevanceOverTime);
      },
    },
    {
      name: 'keywords',
      label: 'Keywords (wordcloud)',
      checked: recreateKeywords,
      onChange: () => {
        setRecreateKeywords(!recreateKeywords);
      },
    },
  ];

  return (
    <Grid container>
      <Grid item>
        <StyledFormControl fullWidth={false} margin="dense">
          <FormLabel>Select environment to upload to</FormLabel>
          <Select
            MenuProps={{
              disableScrollLock: true,
            }}
            value={selectedEnvironment}
            onChange={(e) => {
              const val = e.target.value as EnvironmentType;
              setSelectedEnvironment(val);
            }}
            disabled={disabled}
          >
            <MenuItem value={'develop'}>Develop</MenuItem>
            <MenuItem value={'staging'}>Staging</MenuItem>
            <MenuItem value={'prod'}>Production</MenuItem>
          </Select>
        </StyledFormControl>
      </Grid>
      <StyledGridList item>
        <FormLabel>Select items to recreate</FormLabel>
        {recreateFlags.map((flag: RecreateFlag) => {
          return (
            <Grid item xs={12} key={flag.name}>
              <FormControlLabel
                control={
                  <Checkbox
                    name={flag.name}
                    color="primary"
                    checked={flag.checked}
                    onChange={flag.onChange}
                    disabled={disabled}
                  />
                }
                label={flag.label}
              />
            </Grid>
          );
        })}
      </StyledGridList>
    </Grid>
  );
};

export default PXRecreateForm;
