import { FC, ReactChild, useEffect, useState } from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { useAuth } from 'Providers';
import { accessCheck, RolesType } from 'helpers';
import { ROUTE_ACCESS } from 'Constants';
import { ACCESS_TYPE } from 'Constants/Constants';

type Authenticated = 'yes' | 'no' | 'loading';

const PrivateRoute: FC<{ path: string; children: ReactChild }> = ({
  children,
  path,
  ...rest
}: any) => {
  const { isAuthenticated, getInfo } = useAuth();
  const [authenticated, setAuthenticated] = useState<Authenticated>('loading');

  const { roles }: RolesType = getInfo();
  const { pathname } = useLocation();
  const ACCESS_ROLES = ROUTE_ACCESS[pathname as ACCESS_TYPE] || ['user', 'tagger'];
  const check = accessCheck(ACCESS_ROLES, roles);

  useEffect(() => {
    const checkIfAuthenticated = async () => {
      const auth = await isAuthenticated(1);
      if (auth) setAuthenticated('yes');
      else setAuthenticated('no');
    };

    checkIfAuthenticated();
  }, [pathname]);

  return (
    <Route
      path={path}
      {...rest}
      render={() =>
        authenticated !== 'no' && check ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: authenticated == 'yes' ? '/' : '/login',
            }}
          />
        )
      } // eslint-disable-line
    />
  );
};

export default PrivateRoute;
