import React, { useState, useEffect, FormEvent, ChangeEvent, FC } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Error from 'Components/Error';
import Button from 'Components/Button';
import { useAuth } from 'Providers';
import { FormWrapper } from './Login.styles';
import { errorBuilder } from 'helpers';

interface Login {
  email: string;
  password: string;
}

const Login: FC = () => {
  const { signin, isAuthenticated, error, loading } = useAuth();
  const [inputs, setInputs] = useState<Login>({ email: '', password: '' });
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const redirectToMainIfAuthenticated = async () => {
      const { from }: any = location.state || { from: { pathname: '/' } };
      if (await isAuthenticated()) {
        return history.replace(from);
      }
    };

    redirectToMainIfAuthenticated();
  }, [history, location.state]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  const handleLogin = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { email, password } = inputs;
    await signin(email, password);
    if (location?.pathname == '/login') {
      history.push('/');
    }
  };

  const errorMessage = errorBuilder('LOGIN');

  return (
    <Container maxWidth="sm">
      <Grid container direction="column" justifyContent="center" alignItems="center">
        <FormWrapper>
          <Error error={error?.err && errorMessage} />
          <form onSubmit={handleLogin}>
            <TextField
              id="email"
              type="email"
              name="email"
              placeholder="email"
              inputProps={{ 'data-testid': 'email-input' }}
              value={inputs.email}
              onChange={handleChange}
              label="Email address"
              required
            />
            <TextField
              id="password"
              type="password"
              name="password"
              placeholder="password"
              inputProps={{ 'data-testid': 'password-input' }}
              value={inputs.password}
              onChange={handleChange}
              label="Password"
              required
            />

            <Button type="submit" variant="form" color="primary">
              {loading ? 'Loading...' : 'Login'}
            </Button>
          </form>
        </FormWrapper>
      </Grid>
    </Container>
  );
};

export default Login;
