import { gql } from '@apollo/client';

export enum QueryGranularity {
  'Fixed by location' = 'FIXED_BY_LOCATION',
  'Progressive' = 'PROGRESSIVE',
}

export const GET_SINGLE_ORDER = gql`
  query GetSingleOrder($order_id: Int!) {
    order(where: { order_id: { _eq: $order_id } }) {
      date_of_creation
      name
      order_no
      requested_by
    }
  }
`;

export const GET_ORDERS = gql`
  {
    order(order_by: { date_of_creation: desc }) {
      ppa_agg: process_parameter_archives_aggregate(where: { environment: { _eq: "prod" } }) {
        aggregate {
          count
        }
        nodes {
          ppa_to_ppaoa_agg: process_parameter_archive_to_process_parameter_archive_order_area_aggregate {
            aggregate {
              count
            }
          }
          ppa_to_ppaoa: process_parameter_archive_to_process_parameter_archive_order_area {
            order_area_id
          }
        }
      }
      oa_agg: order_areas_aggregate {
        aggregate {
          count
        }
        nodes {
          ppa_oa_agg: process_parameter_archive_order_areas_aggregate {
            aggregate {
              count
            }
          }
        }
      }
      name
      date_of_creation
      order_id
      order_no
      requested_by
      order_areas {
        order_area_id
      }
    }
  }
`;

export const GET_SINGLE_ORDER_AND_LOCATIONS = gql`
  query GetSingleOrderAndLocations($order_id: Int) {
    order(where: { order_id: { _eq: $order_id } }) {
      orderlocationmaps(limit: 100) {
        location {
          date_of_creation
          geometry
          location_id
          name
          polygon
          postcode
          radius
        }
      }
      order_areas(order_by: { wilson_area_id: asc }, limit: 100) {
        name
        order_area_id
        wilson_area_id
        geometry
      }
      name
      order_id
      order_no
      requested_by
    }
  }
`;

export const GET_CHATTER_LOCATIONS_BY_COLLECTION = gql`
  query GetChatterLocationsByCollection($order_id: Int) {
    chatter_collections: chatter_collection(
      where: { order_id: { _eq: $order_id } }
      order_by: { chatter_collection_id: asc }
    ) {
      chatter_collection_id
      order {
        order_id
        name
      }
      date_from
      date_to
      chatter_locations(order_by: { chatter_location_id: asc }) {
        chatter_location_id
        source_link
        source_location_identifier
        description
        timezone
        chatter_images_aggregate {
          aggregate {
            count
          }
        }
        order_area_to_chatter_locations {
          order_area_id
        }
        collection_timeframe: chatter_collection {
          date_from
          date_to
        }
      }
    }
  }
`;

export const GET_CHATTER_LOCATIONS = gql`
  query GetChatterLocations {
    chatter_location {
      chatter_location_id
      chatter_images_aggregate {
        aggregate {
          count
        }
      }
      chatter_collection {
        order {
          name
          order_id
        }
      }
      source_location_identifier
      source_link
    }
  }
`;

export const INSERT_CHATTER_COLLECTION = gql`
  mutation createChatterCollection(
    $order_id: Int!
    $date_from: timestamp
    $date_to: timestamp
    $image_storage_location: String!
  ) {
    insert_chatter_collection(
      objects: {
        date_from: $date_from
        date_to: $date_to
        order_id: $order_id
        image_storage_location: $image_storage_location
      }
      on_conflict: { constraint: uq_chatter_collection_period_per_order }
    ) {
      returning {
        chatter_collection_id
      }
    }
  }
`;

export const INSERT_CHATTER_LOCATION = gql`
  mutation createChatterLocation(
    $chatter_collection_id: Int!
    $source_location_identifier: String!
    $timezone: String!
    $source_link: String!
    $order_area_ids: [order_area_to_chatter_location_insert_input!]!
    $description: String
  ) {
    insert_chatter_location(
      on_conflict: { constraint: uq_chatter_source_per_collection }
      objects: [
        {
          chatter_collection_id: $chatter_collection_id
          description: $description
          source_link: $source_link
          source_location_identifier: $source_location_identifier
          timezone: $timezone
          order_area_to_chatter_locations: { data: $order_area_ids }
        }
      ]
    ) {
      returning {
        chatter_location_id
      }
    }
  }
`;

// This is inserted into all GET_TAGGING_JOB queries as our common response to prevent desync issues
const getTaggingJobRespCommon = `
  {
    tagging_job_id
    notes
    job_status
    tagger_user_id
    date_of_creation
    chatter_location {
      chatter_location_id
      source_location_identifier
      source_link
      chatter_collection {
        order {
          name
          order_id
          order_no
        }
      }
      chatter_images_aggregate {
        aggregate {
          count
        }
      }
    }
    tagging_job_images_aggregate {
      aggregate {
        count
      }
    }
    tagging_job_images_tagged_aggregate: tagging_job_images_aggregate(
      where: { chatter_category_id: { _is_null: false } }
    ) {
      aggregate {
        count
      }
    }
    tags_for_review_aggregate: tagging_job_images_aggregate(
      where: { review_status: { _eq: "for_review" } }
    ) {
      aggregate {
        count
      }
    }
    ignored_images_aggregate: tagging_job_images_aggregate(
      where: { chatter_category_id: { _eq: 0 } }
    ) {
      aggregate {
        count
      }
    }
    tagging_job_creator_to_firebase_user {
      firebase_user_id
      firebase_identifier
      display_name
    }
    tagging_job_tagger_to_firebase_user {
      firebase_user_id
      firebase_identifier
      display_name
    }
  }
`;

export const GET_TAGGED_ORDERS = gql`
  {
    order(
      order_by: { date_of_creation: desc }
      where: { chatter_collections: { chatter_collection_id: { _is_null: false } } }
    ) {
      name
      order_id
      date_of_creation
      order_areas(
        where: {
          order_area_to_chatter_locations: {
            order_area_to_chatter_location_id: { _is_null: false }
          }
        }
      ) {
        order_area_id
        name
      }
    }
  }
`;

export const GET_TAGGING_JOBS = gql`
  query GetTaggingJobs {
    tagging_job(order_by: { date_of_creation: desc }) ${getTaggingJobRespCommon}
  }
`;

export const GET_ORDER_TAGGING_JOBS = gql`
  query GetTaggingJobs($order_id: Int!) {
    tagging_job(
      order_by: { chatter_location_id: asc, date_of_creation: desc }
      where: {
        chatter_location: { chatter_collection: { order: { order_id: { _eq: $order_id } } } }
      }
    ) 
    ${getTaggingJobRespCommon}
  }
`;

export const GET_TAGGING_JOBS_FOR_TAGGER = gql`
  query GetTaggingJobs($user_id: Int!) {
    tagging_job(
      where: { tagger_user_id: { _eq: $user_id }, job_status: { _in: ["open", "submitted"] } }
      order_by: { date_of_creation: desc }
    ) ${getTaggingJobRespCommon}
  }
`;

export const UPDATE_TAGGING_JOB = gql`
  mutation UpdateTaggingJob($tagging_job_id: Int!, $new_status: String!, $new_user: Int!) {
    update_tagging_job(
      where: { tagging_job_id: { _eq: $tagging_job_id } }
      _set: { job_status: $new_status, tagger_user_id: $new_user }
    ) {
      affected_rows
    }
  }
`;

export const UPDATE_TAGGING_JOB_STATUS = gql`
  mutation UpdateTaggingJob($tagging_job_id: Int!, $new_status: String!) {
    update_tagging_job(
      where: { tagging_job_id: { _eq: $tagging_job_id } }
      _set: { job_status: $new_status }
    ) {
      affected_rows
    }
  }
`;

export const CREATE_TAGGING_JOB = gql`
  mutation createTaggingJob(
    $chatter_location_id: Int!
    $created_by_user: Int!
    $tagger_user_id: Int!
    $job_status: String
    $notes: String
    $modulus: Int
  ) {
    createTaggingJob(
      chatterLocationId: $chatter_location_id
      createdByUser: $created_by_user
      taggerUserId: $tagger_user_id
      jobStatus: $job_status
      notes: $notes
      modulus: $modulus
    ) {
      jobId
    }
  }
`;

export const GET_TAGGING_JOB_DETAILS = gql`
  query GetTaggingJobTagger($tagging_job_id: Int!) {
    tagging_job: tagging_job_by_pk(tagging_job_id: $tagging_job_id) {
      tagger_user_id
      job_status
    }
  }
`;

export const GET_TAGGING_JOB_IMAGES = gql`
  query GetTaggingJobImages($tagging_job_id: Int!) {
    tagging_job_image(
      where: { tagging_job_id: { _eq: $tagging_job_id } }
      order_by: { chatter_image: { image_timestamp: asc }, tagging_job_image_id: asc }
    ) {
      tagging_job_image_id
      review_status
      chatter_category_id
      chatter_category {
        chatter_label
      }
      author_user_id
      chatter_image {
        chatter_image_id
        gcs_path
        filename
        image_timestamp
        details
      }
    }
  }
`;

export const UPDATE_TAGGING_IMAGE_CATEGORY = gql`
  mutation UpdateTaggingImageCategory(
    $chatter_category_id: Int!
    $tagging_job_image_id: Int!
    $author_user_id: Int!
    $review_status: String
  ) {
    update_tagging_job_image_by_pk(
      pk_columns: { tagging_job_image_id: $tagging_job_image_id }
      _set: {
        review_status: $review_status
        chatter_category_id: $chatter_category_id
        author_user_id: $author_user_id
        date_of_tagging: "now()"
      }
    ) {
      review_status
      chatter_category_id
      author_user_id
    }
  }
`;

export const CLOSE_TAGGING_JOB = gql`
  mutation UpsertChatterImageCategory(
    $tagging_job_id: Int!
    $image_categories: [chatter_image_category_insert_input!]!
  ) {
    insert_chatter_image_category(
      objects: $image_categories
      on_conflict: { constraint: uq_chatter_image, update_columns: [chatter_category_id] }
    ) {
      affected_rows
    }
    update_tagging_job_by_pk(
      pk_columns: { tagging_job_id: $tagging_job_id }
      _set: { job_status: "closed" }
    ) {
      job_status
    }
  }
`;

export const GET_FIREBASE_USERS = gql`
  query GetFirebaseUsers {
    firebase_user {
      display_name
      firebase_user_id
      roles
      firebase_identifier
    }
  }
`;

export const GET_CHATTER_CATEGORIES = gql`
  query GetChatterCategories {
    chatter_category(order_by: { nlc_0: asc, nlc_1: asc, nlc_2: asc }) {
      chatter_category_id
      chatter_label
      nlc_0
      nlc_1
      nlc_2
    }
  }
`;

export const CELERY_TASKS_ALL = gql`
  query CeleryTasks {
    celery_worker_celery_taskmeta {
      name
      queue
      status
      id
      task_id
      traceback
      args
      kwargs
      date_done
    }
  }
`;

export const SINGLE_TASK_RESULT = gql`
  query singleTaskResult($id: Int!) {
    celery_worker_celery_taskmeta(where: { id: { _eq: $id } }) {
      id
      task_id
      result
      date_done
    }
  }
`;

export const CELERY_TASKS_RESULT = gql`
  query celeryTasksResult($taskId: String!) {
    celery_worker_celery_taskmeta(where: { task_id: { _eq: $taskId } }) {
      name
      status
      id
      task_id
      date_done
      traceback
      result
    }
  }
`;

export const CELERY_TASK_RESULTS = gql`
  query getTaskResults($taskId: String!) {
    getTaskResults(id: $taskId) {
      children {
        taskResults {
          args
          failed
          kwargs
          name
          queue
          ready
          results
          status
          success
          traceback
        }
        childId
        parentId
      }
      waiting
      success
      status
      results
      ready
      failed
      completedCount
      args
      kwargs
      name
      queue
      traceback
    }
  }
`;

export const CELERY_WORKER_TASKMETA = gql`
  query celery_worker_taskmeta($taskId: String!) {
    celery_worker_celery_taskmeta(where: { task_id: { _eq: $taskId } }) {
      date_done
      id
      name
      queue
      retries
      status
      task_id
      traceback
      worker
    }
  }
`;

export const ALL_CELERY_JOB_RESULTS = gql`
  query getUserCeleryJobResults($offset: Int!, $pageSize: Int!) {
    celery_job(order_by: { date_of_creation: desc }, limit: $pageSize, offset: $offset) {
      celery_job_id
      date_of_creation
      description
      related_id
      related_type
      task_id
      task_name
      task_owner
      celery_job_to_order {
        order_id
        order_no
        name
      }
    }
  }
`;

export const CELERY_JOB_RESULTS_BY_ORDER = gql`
  query getUserCeleryJobResultsByOrderID($offset: Int!, $pageSize: Int!, $order_id: Int!) {
    celery_job(
      order_by: { date_of_creation: desc }
      limit: $pageSize
      offset: $offset
      where: { related_type: { _eq: "order" }, related_id: { _eq: $order_id } }
    ) {
      celery_job_id
      date_of_creation
      description
      related_id
      related_type
      task_id
      task_name
      task_owner
      celery_job_to_order {
        order_id
        order_no
        name
      }
    }
  }
`;

export const EXPORT_AS_EXCEL_GC = gql`
  query exportAsExcelGc(
    $order_id: Int!
    $location_ids: [Int]!
    $selected_from_date: String
    $selected_to_date: String
  ) {
    exportAsExcelGc(
      orderId: $order_id
      locationIds: $location_ids
      selectedFromDate: $selected_from_date
      selectedToDate: $selected_to_date
    ) {
      taskId
    }
  }
`;

export const EXPORT_PRODUCT_X_ARCHIVE = gql`
  mutation exportProductXArchive(
    $order_id: Int!
    $area_ids: [Int!]
    $selected_from_date: String
    $selected_to_date: String
    $environment: String!
    $isochrones_recreate: Boolean!
    $demographics_recreate: Boolean!
    $places_recreate: Boolean!
    $relevance_over_time_recreate: Boolean!
    $chatter_metric_recreate: Boolean!
    $presence_dwell_recreate: Boolean!
    $presence_visitation_recreate: Boolean!
    $keywords_recreate: Boolean!
    $isochrones_enabled: Boolean!
    $demographics_enabled: Boolean!
    $relevance_over_time_enabled: Boolean!
    $chatter_metric_enabled: Boolean!
    $presence_enabled: Boolean!
    $keywords_enabled: Boolean!
    $categorisation_engine: String
    $relevance_version: String
    $presence_date_to_exclusive: String
  ) {
    exportProductXArchive(
      orderId: $order_id
      areaIds: $area_ids
      selectedFromDate: $selected_from_date
      selectedToDate: $selected_to_date
      environment: $environment
      isochronesRecreate: $isochrones_recreate
      demographicsRecreate: $demographics_recreate
      placesRecreate: $places_recreate
      relevanceOverTimeRecreate: $relevance_over_time_recreate
      chatterMetricRecreate: $chatter_metric_recreate
      presenceDwellRecreate: $presence_dwell_recreate
      presenceVisitationRecreate: $presence_visitation_recreate
      keywordsRecreate: $keywords_recreate
      isochronesEnabled: $isochrones_enabled
      demographicsEnabled: $demographics_enabled
      relevanceOverTimeEnabled: $relevance_over_time_enabled
      chatterMetricEnabled: $chatter_metric_enabled
      presenceEnabled: $presence_enabled
      keywordsEnabled: $keywords_enabled
      categorisationEngine: $categorisation_engine
      relevanceVersion: $relevance_version
      presenceDateToExclusive: $presence_date_to_exclusive
    ) {
      taskId
    }
  }
`;

export const EXPORT_PRODUCT_X_ARCHIVE_GROUP = gql`
  mutation exportProductXArchiveGroup(
    $order_id: Int
    $environment: String
    $isochrones_recreate: Boolean
    $demographics_recreate: Boolean
    $places_recreate: Boolean
    $relevance_over_time_recreate: Boolean
    $chatter_metric_recreate: Boolean
    $presence_dwell_recreate: Boolean
    $presence_visitation_recreate: Boolean
    $keywords_recreate: Boolean
  ) {
    exportProductXArchiveGroup(
      orderId: $order_id
      environment: $environment
      isochronesRecreate: $isochrones_recreate
      demographicsRecreate: $demographics_recreate
      placesRecreate: $places_recreate
      relevanceOverTimeRecreate: $relevance_over_time_recreate
      chatterMetricRecreate: $chatter_metric_recreate
      presenceDwellRecreate: $presence_dwell_recreate
      presenceVisitationRecreate: $presence_visitation_recreate
      keywordsRecreate: $keywords_recreate
    ) {
      taskId
    }
  }
`;

export const RUN_REVIEWS_QUERY = gql`
  mutation runReviewsQuery(
    $order_id: Int!
    $area_ids: [Int]
    $oldest_timestamp: String
    $selected_from_date: String
    $selected_to_date: String
    $initial_offset: Int
    $force_collection_to_oldest_timestamp: Boolean
  ) {
    runReviewsQuery(
      orderId: $order_id
      areaIds: $area_ids
      oldestTimestamp: $oldest_timestamp
      selectedFromDate: $selected_from_date
      selectedToDate: $selected_to_date
      initialOffset: $initial_offset
      forceCollectionToOldestTimestamp: $force_collection_to_oldest_timestamp
    ) {
      taskId
    }
  }
`;

export const DOWNLOAD_FILE = gql`
  query downloadFile($taskId: String!) {
    downloadFile(taskId: $taskId) {
      content
      filename
    }
  }
`;

export const RUN_PLACES_QUERY = gql`
  query runPlaceQuery(
    $order_id: Int!
    $locationIds: [Int]!
    $apiStrings: [String]!
    $granularity: QueryGranularity!
  ) {
    runPlaceQuery(
      orderId: $order_id
      locationIds: $locationIds
      apiStrings: $apiStrings
      granularity: $granularity
    ) {
      taskId
    }
  }
`;

export const MANGE_NL_PLACES_COUNT_QUERY = gql`
  query placesToManageCount($order_id: Int!) {
    placesToManageCount(orderId: $order_id) {
      count
    }
  }
`;

export const RUN_MANAGE_NL_PLACES = gql`
  query runManageNeighbourlyticsPlaces($order_id: Int!) {
    runManageNeighbourlyticsPlaces(orderId: $order_id) {
      taskId
    }
  }
`;

export const RUN_PLACES_QUERY_AND_NL_PLACES = gql`
  mutation runPlacesQueryNlPlaces(
    $order_id: Int!
    $locationIds: [Int]!
    $apiStrings: [String]!
    $granularity: QueryGranularity!
    $populatePpa: Boolean!
    $demographicsEnabled: Boolean
    $isochronesEnabled: Boolean
    $relevanceOverTimeEnabled: Boolean
    $presenceEnabled: Boolean
    $keywordsEnabled: Boolean
    $chatterEnabled: Boolean
  ) {
    runPlacesQueryNlPlaces(
      orderId: $order_id
      locationIds: $locationIds
      apiStrings: $apiStrings
      granularity: $granularity
      populatePpa: $populatePpa
      demographicsEnabled: $demographicsEnabled
      isochronesEnabled: $isochronesEnabled
      relevanceOverTimeEnabled: $relevanceOverTimeEnabled
      presenceEnabled: $presenceEnabled
      keywordsEnabled: $keywordsEnabled
      chatterEnabled: $chatterEnabled
    ) {
      taskId
    }
  }
`;

export const RUN_ORDER_AREA_PLACES_QUERY_AND_NL_PLACES = gql`
  mutation runPlacesOrderQueryNlPlaces(
    $orderId: Int!
    $orderAreaIds: [Int]!
    $dateOfCollection: String
    $dataplorDropId: Int
    $populatePpa: Boolean!
    $resetProgress: Boolean!
    $demographicsEnabled: Boolean
    $isochronesEnabled: Boolean
    $relevanceOverTimeEnabled: Boolean
    $presenceEnabled: Boolean
    $keywordsEnabled: Boolean
    $chatterEnabled: Boolean
  ) {
    runPlacesOrderQueryNlPlaces(
      orderId: $orderId
      orderAreaIds: $orderAreaIds
      dateOfCollection: $dateOfCollection
      dataplorDropId: $dataplorDropId
      populatePpa: $populatePpa
      resetProgress: $resetProgress
      demographicsEnabled: $demographicsEnabled
      isochronesEnabled: $isochronesEnabled
      relevanceOverTimeEnabled: $relevanceOverTimeEnabled
      presenceEnabled: $presenceEnabled
      keywordsEnabled: $keywordsEnabled
      chatterEnabled: $chatterEnabled
    ) {
      taskId
    }
  }
`;

export const UPDATE_CELERY_TASK_DETAILS = gql`
  mutation upDateCeleryTaskDetails(
    $taskId: String!
    $taskOwner: String!
    $taskName: String!
    $relatedId: String!
    $relatedType: String!
    $description: String
  ) {
    upDateCeleryTaskDetails(
      task_id: $taskId
      task_owner: $taskOwner
      task_name: $taskName
      related_id: $relatedId
      related_type: $relatedType
      description: $description
    ) {
      celery_job_id
    }
  }
`;

export const PLACES_COLLECTION_LOCATION_PROGRESS = gql`
  query placesLocationProgress($urkelOrderId: Int!) {
    orderlocationmap(where: { order_id: { _eq: $urkelOrderId } }) {
      progress_aggregate {
        aggregate {
          count
          max {
            status
          }
        }
      }
      location_id
    }
  }
`;

export const RESET_PLACES_COLLECTION_LOCATION_PROGRESS = gql`
  mutation resetPlacesCollectionLocationProgress($urkelOrderId: Int!) {
    delete_location_progress_hex(where: { order: { order_id: { _eq: $urkelOrderId } } }) {
      affected_rows
    }
  }
`;

export const IMPORT_ORDER = gql`
  mutation importWilsonOrder($wilsonOrderId: Int!, $polygonOverride: Boolean) {
    importWilsonOrder(wilsonOrderId: $wilsonOrderId, polygonOverride: $polygonOverride) {
      orderId
      orderName
      locations
    }
  }
`;

export const RUN_SCRAPER = gql`
  mutation runScraper($order_id: Int!, $wilson_order_id: Int!, $scraperCsv: JSONString!) {
    runScraper(orderId: $order_id, wilsonOrderId: $wilson_order_id, scraperCsv: $scraperCsv) {
      taskId
    }
  }
`;

export const EXPORT_KML = gql`
  mutation exportKml($locList: JSONString!) {
    exportKml(locList: $locList) {
      content
    }
  }
`;

export const DELETE_TASK = gql`
  mutation removeTaskResults($taskId: String!, $force: Boolean) {
    removeTaskResults(id: $taskId, force: $force) {
      result
      id
    }
  }
`;

export const REVOKE_TASK = gql`
  mutation revokeTaskResults($taskId: String!, $force: Boolean) {
    revokeTaskResults(id: $taskId, force: $force) {
      id
    }
  }
`;

export const GET_ORDER_PPA_ENTRIES = gql`
  query getOrderPpaEntries($order_id: Int!) {
    process_parameter_archive(
      where: { environment: { _eq: "prod" }, order_id: { _eq: $order_id } }
      order_by: { date_from: asc }
    ) {
      date_from
      date_to
      presence_end_date_exclusive
      ppa_to_ppaoa: process_parameter_archive_to_process_parameter_archive_order_area(
        order_by: { order_area_id: asc }
      ) {
        order_area_id
      }
      ppa_id: process_parameter_archive_id
      chatter_enabled
      demographics_enabled
      isochrones_enabled
      keywords_enabled
      presence_enabled
      relevance_over_time_enabled
    }
    order_area(order_by: { order_area_id: asc }, where: { order_id: { _eq: $order_id } }) {
      order_area_id
    }
  }
`;

export const GET_ORDER_AREA_CHATTER_IMAGES = gql`
  query getOrderAreaChatterImages($order_area_id: Int!, $chatter_category_ids: [Int!]!) {
    order_area_to_chatter_location(
      where: {
        order_area_id: { _eq: $order_area_id }
        chatter_location: {
          chatter_images: {
            chatter_image_categories: {
              chatter_category: { chatter_category_id: { _is_null: false } }
            }
          }
        }
      }
    ) {
      chatter_location {
        chatter_images(
          distinct_on: filename
          where: {
            chatter_image_categories: {
              chatter_category: { chatter_category_id: { _in: $chatter_category_ids } }
            }
          }
        ) {
          chatter_image_id
          filename
          gcs_path
          chatter_image_categories {
            chatter_category {
              chatter_category_id
              nlc_0
              nlc_1
              nlc_2
            }
          }
        }
      }
    }
  }
`;

export const GET_ORDER_GOOGLE_PLACES_MAP_COLLECTIONS = gql`
  query getOrderGooglePlacesMapCollections($order_id: Int!) {
    ordergoogleplacemap(
      distinct_on: date_of_collection
      order_by: { date_of_collection: desc }
      where: { order_id: { _eq: $order_id } }
    ) {
      date_of_collection
    }
  }
`;

export const GET_DATAPLOR_DROPS = gql`
  query getDataplorDrops {
    places_dataplor_drop(where: { valid: { _eq: true } }, order_by: { id: desc }) {
      id
      date
    }
  }
`;

export const AD_HOC_EXECUTE_TASK = gql`
  mutation adHocExecuteTask(
    $taskName: String!
    $queue: String!
    $arguments: JSONString!
    $orderId: Int
  ) {
    adHocExecuteTask(taskName: $taskName, queue: $queue, arguments: $arguments, orderId: $orderId) {
      taskId
      response
    }
  }
`;
