import { FC, Fragment, useState, SyntheticEvent } from 'react';

import { Link, useLocation } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import ChevronRightOutlinedIcon from '@material-ui/icons/ChevronRightOutlined';
import ExpandMore from '@material-ui/icons/ExpandMore';

import SubMenu from './SubMenu';

import { MenuWrapper, StyledListItem } from './Menu.styles';

import { useAuth } from 'Providers';

import { menuData } from './menu.data';

import { accessCheck, RolesType } from 'helpers';

/**
 * TODO:
 * Menu can only go 1 level deep before it gets meh... Need to use id instead of name
 * but figure a nice way to turn active menus on and off.
 * Also allow for access levels based on firebase user roles.
 */

const Menu: FC = () => {
  const [activeMenus, setOpen] = useState<string[]>([]);

  const { getInfo } = useAuth();
  const { roles }: RolesType = getInfo();
  const location = useLocation();

  const handleClick = (event: SyntheticEvent) => {
    const { name } = event.currentTarget as HTMLButtonElement;
    const check = name.split('-').includes('parent') ? 0 : 1;

    const activeMenu = activeMenus;
    activeMenu[check] = activeMenu[check] === name ? '' : name;
    setOpen([...activeMenu]);
  };

  return (
    <MenuWrapper>
      <List component="nav" aria-label="order navigation">
        {menuData
          .filter(({ access }) => accessCheck(access, roles))
          .map(({ name, to, submenu, id }) => (
            <Fragment key={id}>
              <StyledListItem
                name={`parent-${name}`}
                id={id}
                button
                component={Link}
                to={to}
                onClick={(event: SyntheticEvent) => handleClick(event)}
                selected={location.pathname === to}
              >
                <ListItemText primary={`${name}`} />
                <Divider />
                {submenu && activeMenus.includes(`parent-${name}`) ? (
                  <ExpandMore />
                ) : (
                  <ChevronRightOutlinedIcon />
                )}
              </StyledListItem>
              {submenu && (
                <SubMenu
                  childMenu={submenu}
                  activeMenus={activeMenus}
                  handleClick={handleClick}
                  parentName={name}
                />
              )}
            </Fragment>
          ))}
      </List>
    </MenuWrapper>
  );
};

export default Menu;
