import React, { ChangeEvent, FC, Reducer, useReducer } from 'react';
import Modal from 'Components/Modal';
import { DELETE_TASK, REVOKE_TASK } from 'Queries';
import { Checkbox, FormControlLabel, FormControl, FormGroup } from '@material-ui/core';
import { useMutation } from '@apollo/client';
import ErrorComponent from 'Components/Error';
import { ChipWrapperMin, RightAlignedButton, StyledChip } from './TasksList.styles';
import { ConfirmModalProps, Action, ModalReducerState } from './Tasks.types';
import { modalReducer, initialState } from './ConfirmModal.reducer';

const ConfirmModal: FC<ConfirmModalProps> = ({ taskId, removeTask, query }: any) => {
  const [state, dispatch] = useReducer<Reducer<ModalReducerState, Action>>(
    modalReducer,
    initialState
  );
  const { error, deleteResult, checked } = state;

  let mutationType;
  if (query === 'REVOKE_TASK') mutationType = REVOKE_TASK;
  else mutationType = DELETE_TASK;

  const [deleteTask, { error: deleteError }] = useMutation(mutationType, {
    onCompleted: ({ removeTaskResults }: any) => {
      let confResult = false;
      const { result, id } = removeTaskResults;
      if (!result && id) confResult = true;

      // eslint-disable-next-line
      console.warn(deleteError);
      if (confResult) removeTask(id);
      dispatch({ type: 'RESULT', removeTaskResults });
    },
  });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target;
    dispatch({ type: 'CHECKED', [name]: event.target.checked });
  };

  const handleDelete = () => {
    deleteTask({ variables: { taskId, force: checked.force } }).catch((error: any) =>
      dispatch({ type: 'ERRORS', error })
    );
  };

  const reset = () => dispatch({ type: 'RESET' });

  return (
    <Modal title="You are deleting" description={`${taskId}`} reset={reset}>
      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <ChipWrapperMin>
            {deleteResult && (
              <StyledChip
                status={deleteResult.result ? 'SUCCESS' : 'FAILURE'}
                label={deleteResult.result ? 'SUCCESS' : 'FAILURE'}
              />
            )}
          </ChipWrapperMin>
          <ErrorComponent error={error as any} />
          <FormControlLabel
            value="end"
            control={
              <Checkbox
                name="confirm"
                onChange={(event) => handleChange(event)}
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            }
            label="Please confirm delete"
            labelPlacement="end"
          />
          <FormControlLabel
            value="force"
            control={
              <Checkbox
                name="force"
                onChange={(event) => handleChange(event)}
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            }
            label="Force delete (required for tasks Pending)"
            labelPlacement="end"
          />
        </FormGroup>
      </FormControl>

      {checked.confirm && (
        <RightAlignedButton variant="form" color="secondary" onClick={handleDelete}>
          {query === 'REVOKE_TASK' ? 'REVOKE TASK' : 'DELETE TASK'}
        </RightAlignedButton>
      )}
    </Modal>
  );
};

export default ConfirmModal;
