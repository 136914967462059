import { FC, ReactNode } from 'react';

import { ScraperProvider } from 'Providers';

interface WrapperProps {
  children: ReactNode;
}

export const OrderWrapper: FC<WrapperProps> = ({ children }) => {
  return <ScraperProvider>{children}</ScraperProvider>;
};
