import { FC, useState, useEffect, useRef, useContext } from 'react';

// Components
import { Grid, CircularProgress } from '@material-ui/core';
import { Check, PriorityHigh, NotInterested, ThumbUp } from '@material-ui/icons';

// Providers
import { useAuth } from 'Providers';
import { BartThemeContext } from 'Providers/BartThemeProvider';

// Styling
import {
  ImageList,
  ImageListThumbnail,
  ImageListThumbnailIconOverlay,
  ImageListThumbnailIcon,
  ImageListThumbnailSelection,
} from './TaggingInterface.styles';

// Types / Arrays
import { TaggingJobImage } from './TaggingInterface.types';

import { createTaggingImageUrl } from 'helpers';

interface TaggingInterfaceImageListProps {
  selectedId: number;
  updateSelectedId: (id: number) => void;
  images: TaggingJobImage[];
}

const TaggingInterfaceImageList: FC<TaggingInterfaceImageListProps> = ({
  selectedId,
  updateSelectedId,
  images,
}: TaggingInterfaceImageListProps) => {
  const { storageSignature, signSignature } = useAuth();

  const themeContext = useContext(BartThemeContext);

  const imageRefs = useRef<(HTMLDivElement | null)[]>([]);

  /* 
    Scroll Into View is finnicky with React rendering,
    and doesn't work with the initial selected id.
    This function ensures all images are loaded before 
    attempting to scroll initially, which works around this problem.
  */
  const [loadedImageCount, setLoadedImageCount] = useState<number>(0);
  const checkImagesLoaded = () => {
    if (loadedImageCount + 1 === images.length) scrollToImage(selectedId);
    else setLoadedImageCount(loadedImageCount + 1);
  };

  const scrollToImage = (id: number) => {
    const selectedImageRef = imageRefs.current.find((r) => r && r.id === `image_${id}`);
    if (selectedImageRef) {
      setTimeout(() => {
        selectedImageRef.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 100);
    }
  };

  useEffect(() => {
    themeContext.imageListScoll && scrollToImage(selectedId);
  }, [selectedId, images]);

  return (
    <ImageList>
      {images.map((image, index) => {
        return (
          <Grid
            key={index}
            ref={(el) => (imageRefs.current[index] = el)}
            id={`image_${image.tagging_job_image_id}`}
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            onClick={() => {
              updateSelectedId(image.tagging_job_image_id);
            }}
          >
            <ImageListThumbnail selected={selectedId === image.tagging_job_image_id}>
              {storageSignature ? (
                <>
                  <ImageListThumbnailSelection selected={selectedId === image.tagging_job_image_id}>
                    <img
                      src={signSignature(
                        createTaggingImageUrl(
                          image.chatter_image.gcs_path,
                          image.chatter_image.filename
                        )
                      )}
                      onLoad={checkImagesLoaded}
                      onError={checkImagesLoaded}
                      alt="image"
                      loading="lazy"
                    />
                  </ImageListThumbnailSelection>
                  <ImageListThumbnailIconOverlay>
                    {image.chatter_category_id !== null &&
                      (image.chatter_category_id === 0 ? (
                        <ImageListThumbnailIcon color="IGNORED">
                          <NotInterested fontSize="small" />
                        </ImageListThumbnailIcon>
                      ) : (
                        <ImageListThumbnailIcon color="TAGGED">
                          <Check fontSize="small" />
                        </ImageListThumbnailIcon>
                      ))}
                    {image.review_status === 'for_review' && (
                      <ImageListThumbnailIcon color="FOR_REVIEW">
                        <PriorityHigh fontSize="small" />
                      </ImageListThumbnailIcon>
                    )}
                    {image.review_status === 'reviewed' && (
                      <ImageListThumbnailIcon color="REVIEWED">
                        <ThumbUp fontSize="small" />
                      </ImageListThumbnailIcon>
                    )}
                  </ImageListThumbnailIconOverlay>
                </>
              ) : (
                <CircularProgress />
              )}
            </ImageListThumbnail>
          </Grid>
        );
      })}
    </ImageList>
  );
};

export default TaggingInterfaceImageList;
