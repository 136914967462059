import React, { useState, useEffect, FC } from 'react';
import { useLazyQuery } from '@apollo/client';
import { CELERY_TASKS_RESULT } from 'Queries';
import { taskResultVar } from 'libs/Apollo/Cache';
import { Grid, CardHeader, Typography, Divider } from '@material-ui/core';
import { formatDate } from 'helpers';
import { StyledChip } from 'Pages/Tasks/TasksList.styles';
import { CeleryStatus } from 'Constants';
import { STATUS } from 'Pages/Tasks/Tasks.types';
import CardBasic from 'Components/CardBasic';
import VerticalSpacer from 'Components/Spacer';
import DownloadFile from 'Components/DownloadFile';
import { useParams } from 'react-router-dom';
import Loader from 'Components/Loader';
import { downloadFileTypeForTaskADT } from 'helpers';

const SingleTask: FC = () => {
  const [task, setTask] = useState<any>({ task: { status: 'none' } });
  const params = useParams();
  const { taskId }: any = params;

  const [polling, setPolling] = useState<boolean>(false);

  const [getTask, { startPolling, stopPolling }] = useLazyQuery(CELERY_TASKS_RESULT, {
    variables: { taskId },
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ celery_worker_celery_taskmeta }) => {
      const check =
        celery_worker_celery_taskmeta.length > 0 &&
        celery_worker_celery_taskmeta[0].status === 'SUCCESS';
      if (!check && startPolling) startPolling(1000);
      if (check && stopPolling) {
        stopPolling();
        setPolling(false);
        const { task_id, status, result, date_done, traceback, id } =
          celery_worker_celery_taskmeta[0];
        const updateVar = { taskId: task_id, status, result, date_done, traceback, id, step: 0 };
        setTask({ ...updateVar });
        taskResultVar({ ...updateVar });
      }
    },
  });

  useEffect(() => {
    getTask();
    setPolling(true);
    return stopPolling && stopPolling();
  }, [getTask]);

  const { status, date_done, traceback, id, result } = task;

  const downloadType = downloadFileTypeForTaskADT(status, '');

  if (polling) {
    return (
      <Grid container direction="row" justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <CardBasic elevation={1}>
            <VerticalSpacer>
              <CardHeader
                title={<Loader color="neighbourlytics-pink" />}
                avatar={<StyledChip status={status as any} label={`${CeleryStatus.STARTED}`} />}
              />
            </VerticalSpacer>
          </CardBasic>
        </Grid>
      </Grid>
    );
  }
  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid item xs={12}>
        <CardBasic elevation={1}>
          <VerticalSpacer>
            <CardHeader
              title={
                <Typography variant="h6" component="h6" gutterBottom>{`ID: ${id}`}</Typography>
              }
              subheader={`Date: ${formatDate(date_done)}`}
              avatar={
                <StyledChip
                  status={status as any}
                  label={
                    status ? `${CeleryStatus[`${status}` as STATUS]}` : `${CeleryStatus.STARTED}`
                  }
                />
              }
            />
            <Divider />
            <Typography variant="h6" component="h6">
              Details:
            </Typography>
            <Typography variant="body2" component="p">
              <b>Task ID: </b>
              {taskId}
            </Typography>
            {traceback && (
              <Typography variant="body2" component="p">
                Error traceback:
                {traceback}
              </Typography>
            )}
            <Divider />
            {result && result.length >= 50 && (
              <DownloadFile taskId={taskId} downloadType={downloadType} />
            )}
          </VerticalSpacer>
        </CardBasic>
      </Grid>
    </Grid>
  );
};

export default SingleTask;
