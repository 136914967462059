import styled from 'styled-components';
import { Colors } from 'Constants';
import { TagThumbnailColors } from 'Constants';
import { TagThumbnailStatus } from './TaggingInterface.types';

import { Select, List, ListItem } from '@material-ui/core';

import { NAV_HEIGHT } from 'Components/Header/Header.styles';

export const TaggingContainer = styled.div<{ backgroundColor: 'light' | 'dark' }>`
  ${(props) => {
    switch (props.backgroundColor) {
      case 'light': {
        return 'background-color: #eee;';
      }
      case 'dark': {
        return 'background-color: #444;';
      }
      default: {
        return 'background-color: #eee;';
      }
    }
  }}
`;

export const ImageSidebar = styled.div`
  position: relative;
  font-size: 14px;
  height: ${87.5 - NAV_HEIGHT}vh;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  overflow-y: auto;
`;

export const TagsSidebar = styled.div`
  position: relative;
  font-size: 14px;
  height: ${99 - NAV_HEIGHT}vh;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  overflow-y: auto;
`;

export const StyledSelect = styled(Select)<{ fontcolor: 'light' | 'dark' }>`
  width: 100%;
  font-size: 1.5rem;
  text-transform: capitalize;
  font-weight: bold;
  ${(props) => (props.fontcolor === 'dark' ? 'color: #fff;' : 'color: #000;')}
`;

export const TaggedImagesCounter = styled.div<{ color: 'light' | 'dark' }>`
  position: relative;
  padding: 0.3rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #ccc;
  font-weight: bold;
  ${(props) => (props.color && props.color === 'dark' ? 'color: #fff;' : 'color: #000;')}
`;

export const ImageList = styled.div`
  width: 100%;
  border-right: 1px solid #ccc;
`;

export const ImageListThumbnail = styled.section<{ selected?: boolean | undefined }>`
  padding: 0.2rem;
  height: 100px;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: row;
  cursor: pointer;

  ${(props) => `
  ${
    props.selected &&
    `
    background-color: #C0E6E9;
    outline: 4px solid ${Colors['neighbourlytics-blue']};
    outline-offset: -3px;
    font-weight: bold;
  `
  };
  `};
`;

export const ImageListThumbnailSelection = styled.div<{ selected?: boolean | undefined }>`
  & img {
    max-width: 9.6rem;
  }
  ${(props) => `
  ${
    props.selected &&
    `
    filter: brightness(1.1);
  `
  };
  ${
    !props.selected &&
    `
    filter: brightness(0.8) saturate(60%);
  `
  };
  `};
`;

export const ImageListThumbnailIconOverlay = styled.div`
  position: absolute;
  padding: 2px;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
`;

export const ImageListThumbnailIcon = styled.div<{ color: TagThumbnailStatus }>`
  margin: 1px;
  border-radius: 3px;
  padding: 1px;
  font-weight: bold;
  height: 22px;
  width: 22px;
  filter: brightness(1.2) !important;
  background-color: ${(props) => TagThumbnailColors[`${props.color}` as TagThumbnailStatus]};
`;

export const TagList = styled(List)<any>`
  width: 100%;
  padding: 0;
`;

export const TagListItem = styled(ListItem)<any>`
  width: 100%;
  padding: 0.32rem 1rem;
  justify-content: start;
  transition: 0.2s background-color ease;
  &:hover:not([disabled]) {
    background-color: #ccc;
  }
  cursor: pointer;
  border: 1px solid #ddd;
  font-size: 1.5rem;

  ${(props) => `
    background-color: ${props.color} !important;

    ${
      props.selected &&
      `
      filter: brightness(1.25);
      box-sizing: border-box;
      outline: 5px solid white;
      outline-offset: -5px;
      font-weight: bold;
    `
    };
  `};
`;

export const FooterContainer = styled.div`
  position: sticky;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  border-top: 1px solid #ccc;
`;
