import styled from 'styled-components';
import Spacer from 'Components/Spacer';

export const Subtitle = styled.span`
  padding-left: 5px;
  line-height: 2.7;
  font-size: 13px;
  opacity: 80%;
`;

type PageProps = {
  shrink: boolean;
};

export const Wrapper = styled.section<PageProps>`
  background-color: #fff;

  ${({ shrink }) => shrink && 'padding-right: 27rem;'}
`;

export const TitleHeader = styled.div`
  padding: 10px;
`;

export const SidebarWrapper = styled.div`
  font-size: 14px;
  display: flex;
  width: 27rem;
  flex-basis: 27rem;
  align-items: center;
  flex-grow: 1;
  flex-direction: column;
  position: fixed;
  right: 0;
  top: 7.2rem;
  bottom: 0;
  background-color: #fff;
  overflow: hidden;
  padding-bottom: 20rem;
  &:hover {
    overflow-y: auto;
  }
`;

export const StyledSpacer = styled(Spacer)`
  background: #fff;
`;
