import styled from 'styled-components';

export const StyledForm = styled.div`
  display: flex;
  min-width: 30rem;
  flex-direction: column;
  form {
    margin: 1.5rem;
    label {
      flex: 1 1 0;
      > * {
        margin: 1.5rem;
      }
    }
    button {
      display: block;
      margin: auto;
    }
  }
`;
