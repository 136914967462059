import styled from 'styled-components';
import Button from 'Components/Button';

export const ExecuteButton = styled(Button)`
  margin: 1rem 0.5rem;

  &:disabled {
    background-color: #ccc;
  }
`;
