import { FC, useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import { useRouteMatch } from 'react-router-dom';
import AnimateHeight from 'react-animate-height';
import { useQuery, useLazyQuery } from '@apollo/client';
import { MANGE_NL_PLACES_COUNT_QUERY, RUN_MANAGE_NL_PLACES } from 'Queries';
import Button from 'Components/Button';
import Error from 'Components/Error';

const ManageNLPlaces: FC = () => {
  const {
    params: { order_id },
  }: any = useRouteMatch('/order/:order_id');
  const [height, setHeight] = useState<number | string>(0);
  const [startedTaskId, setStartedTaskId] = useState(undefined);
  const { loading, data, error, refetch } = useQuery<any>(MANGE_NL_PLACES_COUNT_QUERY, {
    variables: { order_id },
    onCompleted: () => {
      if (error) refetch();
    },
  });
  const [runManageNLPlaces] = useLazyQuery(RUN_MANAGE_NL_PLACES, {
    variables: { order_id },
    onCompleted: ({ runManageNeighbourlyticsPlaces: { taskId } }) => {
      if (taskId) setStartedTaskId(taskId);
    },
  });

  useEffect(() => {
    setHeight(() => 'auto');
  }, []);

  return (
    <AnimateHeight duration={500} height={height}>
      {loading && <Typography variant="body2">Loading number of places to manage...</Typography>}
      {data && (
        <Typography variant="body2">
          {data.placesToManageCount.count === 1
            ? `There is 1 place to manage`
            : `There are ${data.placesToManageCount.count} places to manage`}
        </Typography>
      )}
      {error && <Error error={error} />}
      {startedTaskId && (
        <>
          <Typography variant="h4" align="left">
            Success! Your task has been queued successfully. Refer to the task list for details
          </Typography>
        </>
      )}
      <Button variant="form" color="primary" onClick={() => runManageNLPlaces()}>
        Run manage NL places task
      </Button>
    </AnimateHeight>
  );
};

export default ManageNLPlaces;
