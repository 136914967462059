import React, { FC, useState, useEffect } from 'react';
import { MenuItem, Select, FormControl, FormLabel, Grid, Typography } from '@material-ui/core';

import { TableWrapper, TableHeader, TableRow, TableCell } from './ChatterImageSelector.styles';

// Queries
import { useQuery, useLazyQuery } from '@apollo/client';
import { GET_CHATTER_CATEGORIES, GET_ORDER_AREA_CHATTER_IMAGES } from 'Queries';

// Types
import { OrderArea } from 'Pages/Order/Order.types';
import { ChatterCategory } from 'Pages/TaggingInterface/TaggingInterface.types';
import { ChatterImages, OrderAreaToChatterLocation } from './TaggedImages.types';

import TaggedImagesList from './TaggedImagesList';

import Error from 'Components/Error';

interface ChatterSelectorProps {
  orderAreas: OrderArea[];
  disabled: boolean;
}

enum NLC_LEVEL {
  'nlc_0' = 0,
  'nlc_1' = 1,
  'nlc_2' = 2,
}

type NLCLevelType = keyof typeof NLC_LEVEL;

const selectNlcArray = (categories: ChatterCategory[], nlc: string) => {
  switch (nlc) {
    case 'nlc_0':
      return Array.from(new Set(categories.map((category) => category.nlc_0)));
    case 'nlc_1':
      return Array.from(new Set(categories.map((category) => category.nlc_1)));
    case 'nlc_2':
      return Array.from(new Set(categories.map((category) => category.nlc_2)));
  }
  return Array.from(new Set(categories.map((category) => category.nlc_2)));
};

const filterCategories = (categories: ChatterCategory[], level: number, nlcSelection: string) => {
  switch (level) {
    case 0:
      return categories.filter((category) => category.nlc_0 === nlcSelection);
    case 1:
      return categories.filter((category) => category.nlc_1 === nlcSelection);
    case 2:
      return categories.filter((category) => category.nlc_2 === nlcSelection);
    default:
      return categories;
  }
};

const ChatterImageSelector: FC<ChatterSelectorProps> = ({
  orderAreas,
  disabled,
}: ChatterSelectorProps) => {
  const [orderAreaId, setOrderAreaId] = useState<number>();
  const [categories, setCategories] = useState<ChatterCategory[]>([]);

  const [taggedImages, setTaggedImages] = useState<ChatterImages[]>([]);

  const [selectedNl_X, setSelectedNl_X] = useState<NLCLevelType>('nlc_0');
  const [selectedCategories, setSelectedCategories] = useState<ChatterCategory[]>([]);
  const [filteredCategory, setFilteredCategory] = useState<string>('');

  const { loading } = useQuery(GET_CHATTER_CATEGORIES, {
    onCompleted: (data) => {
      setCategories(
        data.chatter_category.filter(
          (category: ChatterCategory) => category.chatter_label !== 'ignore'
        )
      );
    },
  });

  const [getTaggedImages, { error }] = useLazyQuery<any>(GET_ORDER_AREA_CHATTER_IMAGES, {
    variables: {
      order_area_id: orderAreaId,
      chatter_category_ids: Array.from(
        new Set(selectedCategories.map((category) => category.chatter_category_id))
      ),
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const chatterImages: ChatterImages[] = data.order_area_to_chatter_location.flatMap(
        (order_area_to_chatter_location: OrderAreaToChatterLocation) =>
          order_area_to_chatter_location.chatter_location.chatter_images
      );
      setTaggedImages(chatterImages);
    },
  });

  const chatterCategoriesTable = (
    chatterCategories: ChatterCategory[],
    taggedImages: ChatterImages[]
  ) => {
    if (selectedCategories.length <= 0) return;

    // Sort chatterCategories
    const sortedChatterCategories = [...chatterCategories].sort((a, b) => {
      if (a.nlc_0 !== b.nlc_0) {
        return a.nlc_0.localeCompare(b.nlc_0);
      } else if (a.nlc_1 !== b.nlc_1) {
        return a.nlc_1.localeCompare(b.nlc_1);
      } else {
        return a.nlc_2.localeCompare(b.nlc_2);
      }
    });
    return (
      <>
        <Typography variant="h6">Selected Categories</Typography>
        <TableWrapper>
          <TableHeader>
            <TableCell>nlc_0</TableCell>
            <TableCell>nlc_1</TableCell>
            <TableCell>nlc_2</TableCell>
            <TableCell>Count</TableCell>
          </TableHeader>
          {sortedChatterCategories.map(({ chatter_category_id, nlc_0, nlc_1, nlc_2 }) => {
            return (
              <TableRow key={chatter_category_id}>
                <TableCell>{nlc_0}</TableCell>
                <TableCell>{nlc_1}</TableCell>
                <TableCell>{nlc_2}</TableCell>
                <TableCell>
                  {
                    taggedImages.filter(
                      (ti) =>
                        ti.chatter_image_categories.findIndex(
                          (cic) => cic.chatter_category.chatter_category_id === chatter_category_id
                        ) >= 0
                    ).length
                  }
                </TableCell>
              </TableRow>
            );
          })}
        </TableWrapper>
      </>
    );
  };

  const chatterCategoriesForm = () => {
    return (
      <Grid container>
        <Grid item xs={4} style={{ marginBottom: '20px' }}>
          <FormControl fullWidth={true}>
            <FormLabel>Select Order Area</FormLabel>
            <Select
              name="orderAreas"
              id="orderAreas"
              label="Select Order Area"
              disabled={disabled}
              MenuProps={{
                disableScrollLock: true,
              }}
              value={orderAreaId ?? ''}
              required
              onChange={(e) => {
                const val = e.target.value as string;
                setOrderAreaId(parseInt(val));
              }}
            >
              {orderAreas.map((orderArea) => (
                <MenuItem key={orderArea.order_area_id} value={orderArea.order_area_id}>
                  {`${orderArea.order_area_id} - ${orderArea.name}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4} style={{ marginBottom: '20px' }}>
          <FormControl fullWidth={true}>
            <FormLabel>Select NLC</FormLabel>
            <Select
              name="selectNlc0"
              id="selectNlc0"
              label="Select NLC_0"
              MenuProps={{
                disableScrollLock: true,
              }}
              value={selectedNl_X ?? ''}
              required
              disabled={orderAreaId === undefined}
              onChange={(e) => {
                const val = e.target.value as NLCLevelType;
                setSelectedNl_X(val);
                setTaggedImages([]);
                setFilteredCategory('');
              }}
            >
              {['nlc_0', 'nlc_1', 'nlc_2'].map((nlc_x) => (
                <MenuItem key={nlc_x} value={nlc_x}>
                  {nlc_x}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4} style={{ marginBottom: '20px' }}>
          <FormControl fullWidth={true}>
            <FormLabel>Select Category</FormLabel>
            <Select
              name="selectCategory"
              id="selectCategory"
              label="Select Category"
              MenuProps={{
                disableScrollLock: true,
              }}
              value={filteredCategory}
              required
              disabled={orderAreaId === undefined}
              onChange={(e) => {
                const val = e.target.value as NLCLevelType;
                setSelectedCategories(filterCategories(categories, NLC_LEVEL[selectedNl_X], val));
                orderAreaId && selectedCategories.length <= 0 && getTaggedImages(); // Refetch images with updated category filters
                setFilteredCategory(val);
              }}
            >
              {selectNlcArray(categories, selectedNl_X).map((category) => (
                <MenuItem key={category} value={category ?? ''}>
                  {category}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    );
  };

  useEffect(() => {
    // Wipe all selections if a user changes the selected order
    setTaggedImages([]);
  }, [orderAreas]);

  return (
    <Grid container direction="row" spacing={10}>
      <Grid item xs={8}>
        <Grid item xs={12}>
          {!loading && chatterCategoriesForm()}
          {error && <Error error={error} />}
        </Grid>
        <Grid item xs={12}>
          {taggedImages.length > 0 && <TaggedImagesList images={taggedImages} />}
        </Grid>
      </Grid>

      <Grid item xs={4}>
        {chatterCategoriesTable(selectedCategories, taggedImages)}
      </Grid>
    </Grid>
  );
};

export default ChatterImageSelector;
