import styled from 'styled-components';
import { lighten, darken } from 'polished';
import ButtonBase from '@material-ui/core/ButtonBase';
import { Colors } from 'Constants';
import { ButtonProps } from './Button.types';

export const StyledButton = styled(ButtonBase)<ButtonProps>`
  margin: 1rem;
  max-width: 25rem;
  ${(props) =>
    props.variant === 'form'
      ? `  
      background-color: ${(props.color && Colors[props.color]) || Colors.primary};
      color: ${Colors.white};
      border-radius: 0.2rem;
      padding: 0.7rem 1.4rem;
      transition: 0.2s background-color ease;
      &:hover {
        background-color: ${lighten(0.1, Colors[props.color])};
      }
      &:active {
        background-color: ${darken(0.3, Colors[props.color])};
      }
      &:disabled {
        background-color: #ddd;
      }
      `
      : `    
      border-bottom: 1px ${Colors.font} solid;
      transition: 0.2s color ease;
      &:hover {
        color: ${lighten(0.1, Colors.font)};
        border-bottom-color:${lighten(0.1, Colors.font)};
      }
      &:active {
        color: ${darken(0.3, Colors.font)};
        border-bottom-color:${darken(0.3, Colors.font)};
      }
      &:disabled {
        background-color: #eee;
      }

      `}
`;
