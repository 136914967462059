import { FC, useState, ChangeEvent } from 'react';

// Functions
import { useQuery } from '@apollo/client';
import { GET_ORDERS } from 'Queries';
import { filterArray, ppaCheck } from 'helpers';

// Types
import { OrderProps } from 'Pages/Order/Order.types';

// Components
import { Table, TableContainer, Paper } from '@material-ui/core';
import Rows from './Rows';
import TableHeader from './TableHeader';
import TempTable from './TempTable';

// Styling
import { StyledTextField } from './OrderList.styles';

const OrderList: FC = () => {
  const { loading, error, data } = useQuery(GET_ORDERS);
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchTerm(value);
  };

  if (loading || error) return <TempTable error={error && error} />;

  const ordersList: OrderProps[] = data.order.map((order: OrderProps) => ({
    ...order,
    ppaCheck: ppaCheck(order),
  }));

  return (
    <TableContainer component={Paper}>
      <StyledTextField
        id="filter"
        type="filter"
        name="filter"
        placeholder="filter"
        value={searchTerm}
        onChange={handleChange}
        label="filter terms"
        required
      />
      <Table aria-label="List of orders">
        <TableHeader />
        <Rows order={searchTerm ? filterArray(ordersList, searchTerm) : ordersList} />
      </Table>
    </TableContainer>
  );
};

export default OrderList;
