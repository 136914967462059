import styled from 'styled-components';

export const FormWrapper = styled.div`
  display: flex;
  width: 30rem;
  padding:1.5rem;
  padding-top:3rem;
  border-radius: 2px;
  flex-direction:column;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
  background-color: #fff;
  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    aling-items: center;
    flex-grow:1;
    > * {
      margin-top:1.5rem;
      flex-grow:1;
    }
  }

}
`;
