import { FC } from 'react';

import { TableCell } from '@material-ui/core';

import { TaggingStatusChip } from '../TaggingJobList.styles';
import { TaggingRowsStatisticsProps } from '../TaggingJobList.types';

const TaggingRowStatistics: FC<TaggingRowsStatisticsProps> = ({
  disabled,
  jobImages,
  taggedJobImages,
  percentTagged,
  tagsForReview,
  ignoredImages,
}) => {
  return (
    <TableCell padding="none">
      <TaggingStatusChip
        size="small"
        disabled={disabled}
        status={jobImages - taggedJobImages > 0 ? 'IN_PROGRESS' : 'COMPLETED'}
        label={`${taggedJobImages} / ${jobImages} - ${percentTagged}% Tagged`}
      />
      <br />
      <TaggingStatusChip
        size="small"
        disabled={disabled}
        status={tagsForReview > 0 ? 'FOR_REVIEW' : 'CLEAR'}
        label={`${tagsForReview} - For Review`}
      />
      <br />
      <TaggingStatusChip
        size="small"
        disabled={disabled}
        status={ignoredImages > 0 ? 'IGNORED' : 'CLEAR'}
        label={`${ignoredImages} - Ignored`}
      />
    </TableCell>
  );
};

export default TaggingRowStatistics;
