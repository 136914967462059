import { FC, useEffect, useState } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { Tab, Tabs } from '@material-ui/core';
import ProductXExport from 'Components/ProductXExport';
import Decommissioned from 'Components/Decommissioned';
import RunReviewsQuery from 'Components/RunReviewsQuery';
import Button from 'Components/Button';
import NoComponent from './NoComponent';
import RunPlacesQueryNlPlaces from 'Components/RunPlacesQueryNLPlaces';
import RunOrderAreaPlacesQuery from 'Components/RunOrderAreaPlacesQuery';
import ChatterScraper from 'Components/ChatterScraper';
import ChatterImageSelector from 'Components/ChatterImageSelector';
import OrderTaggingJobs from 'Components/OrderTaggingJobs';
import RecategorisePlaces from 'Components/RecategorisePlaces';

type tabs = {
  route: string;
  label: string;
  component?: string;
  componentProps?: Record<string, any>;
  content?: string;
};

type ComponentMapType =
  | 'RunPlacesQueryNlPlaces'
  | 'RunOrderAreaPlacesQuery'
  | 'ProductXExport'
  | 'RunReviewsQuery'
  | 'Decommissioned'
  | 'ChatterScraper'
  | 'ChatterImageSelector'
  | 'OrderTaggingJobs'
  | 'RecategorisePlaces';

interface Props {
  tabs: tabs[];
}

const ComponentMap = {
  NoComponent,
  RunPlacesQueryNlPlaces,
  RunOrderAreaPlacesQuery,
  ProductXExport,
  RunReviewsQuery,
  Decommissioned,
  ChatterScraper,
  ChatterImageSelector,
  OrderTaggingJobs,
  RecategorisePlaces,
};

const DynamicTabs: FC<Props> = ({ tabs }: Props) => {
  const [path, setPath] = useState<string>('');
  const location = useLocation();

  const getActiveTab = () => {
    const activeTab = tabs.find(({ route }) => location.pathname.split('/').slice(-1)[0] === route);
    return activeTab !== undefined ? activeTab.route : false;
  };

  useEffect(() => {
    const tmpPath = location.pathname.split('/').slice(1);
    if (path.length === 0)
      setPath(`/${tmpPath.filter((el) => !tabs.some((item) => item.route === el)).join('/')}`);
  }, [location.pathname, tabs, path.length]);

  return (
    <>
      <Tabs value={getActiveTab()} variant="scrollable" scrollButtons="auto">
        {tabs.map(({ route, label }) => (
          <Tab key={route} value={route} label={label} component={Button} to={`${path}/${route}`} />
        ))}
      </Tabs>
      <Route
        path={`/order/:order_id`}
        render={() => (
          <>
            <Switch>
              {tabs.map(({ component, route, content, componentProps }, index) => (
                <Route
                  key={route}
                  path={`/order/:order_id/${route}`}
                  render={() => {
                    const Component: any =
                      ComponentMap[component as ComponentMapType] || NoComponent;

                    return <Component {...componentProps} index={index} content={content} />;
                  }}
                />
              ))}
            </Switch>
          </>
        )}
      />
    </>
  );
};

export default DynamicTabs;
