import { FC, useState, useEffect } from 'react';

// Functions
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { GET_SINGLE_ORDER_AND_LOCATIONS } from 'Queries';

// Types
import { LocationType, OrderLocation, OrderArea, Params } from './Order.types';

// Pages
import TasksList from 'Pages/Tasks';

// Components
import DynamicTabs from 'Components/DynamicTabs';
import PPATAble from 'Components/PPATable';
import Error from 'Components/Error';
import Location from './Location';
import Button from 'Components/Button';

// Styling
import { Grid, FormLabel } from '@material-ui/core';
import { Wrapper, SidebarWrapper, StyledSpacer, TitleHeader } from './Order.styles';

// Helpers
import { sortOrderAreas } from 'helpers';

const Order: FC = () => {
  const [locations, setLocations] = useState<LocationType[]>([]);
  const [orderAreas, setOrderAreas] = useState<OrderArea[]>([]);

  const [displayLocations, setDisplayLocations] = useState<boolean>(false);

  const { order_id }: Params = useParams();
  const { loading, error, data } = useQuery(GET_SINGLE_ORDER_AND_LOCATIONS, {
    variables: { order_id: `${order_id}` },
  });

  const order = data?.order || false;
  const order_no = parseInt(order[0]?.order_no) || false;

  const allTabs = [
    {
      route: `run-places-query`,
      label: 'Run Loc Places Query',
      component: 'RunPlacesQueryNlPlaces',
      componentProps: { order_id, locations },
    },
    {
      route: `run-order-area-places-query`,
      label: 'Run OA Places Query',
      component: 'RunOrderAreaPlacesQuery',
      componentProps: { order_id, orderAreas },
    },
    {
      route: `export-px`,
      label: 'Export ProductX',
      component: 'ProductXExport',
      componentProps: { order_id, orderAreas },
    },
    {
      route: `recategorise-places`,
      label: 'Recategorise Places',
      component: 'RecategorisePlaces',
      componentProps: { order_id, orderAreas },
    },
    {
      route: `run-reviews-query`,
      label: 'Run Reviews Query',
      component: 'RunReviewsQuery',
      componentProps: { order_id, orderAreas },
    },
    {
      route: `chatter-scraper`,
      label: 'Chatter Scraper',
      component: 'ChatterScraper',
      componentProps: {
        order_id,
        orderAreas: sortOrderAreas(orderAreas, 'area_id'),
        wilson_order_id: order_no,
      },
    },
    {
      route: `order-tagging-jobs`,
      label: 'Tagging Jobs',
      component: 'OrderTaggingJobs',
      componentProps: { order_id },
    },
    {
      route: `chatter-image-selector`,
      label: 'Select Chatter Images',
      component: 'ChatterImageSelector',
      componentProps: { orderAreas },
    },
    {
      route: `decommissioned`,
      label: 'Decommissioned',
      component: 'Decommissioned',
      componentProps: { locations, order_id, wilson_order_id: order_no },
    },
  ];

  useEffect(() => {
    if (order) {
      const orderLocation = order[0].orderlocationmaps;
      setLocations(orderLocation.map(({ location }: OrderLocation) => location));
      setOrderAreas(order[0].order_areas);
    }
  }, [order]);

  const handleLocationDisplayChange = () => {
    setDisplayLocations(!displayLocations);
  };

  if (loading) return <p>Loading ..</p>;
  if (error) return <Error error={error} />;

  return (
    <>
      <Wrapper shrink={displayLocations}>
        <Grid container>
          <Grid item xs={5}>
            <TitleHeader>
              <h3>{order[0]?.name}</h3>
              <div>
                <b>Urkel Order ID: </b>
                {order[0]?.order_id}
              </div>
              <div>
                <b>Wilson Order ID: </b>
                {order[0]?.order_no}
              </div>
              <Button onClick={handleLocationDisplayChange}>Display Location IDs</Button>
            </TitleHeader>
          </Grid>
          <Grid item xs={7}>
            <PPATAble />
          </Grid>
        </Grid>
        <StyledSpacer>
          <FormLabel>Select Task</FormLabel>
          <DynamicTabs tabs={allTabs} />
          <TasksList orderId={order_id} />
        </StyledSpacer>
      </Wrapper>
      {displayLocations && (
        <SidebarWrapper>
          {locations.map((location: LocationType) => {
            const { location_id } = location;
            return <Location {...location} key={location_id} />;
          })}
        </SidebarWrapper>
      )}
    </>
  );
};

export default Order;
