import { useState, FC, SyntheticEvent } from 'react';
import { useLazyQuery } from '@apollo/client';

import { Grid, ListItemText } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import CachedIcon from '@material-ui/icons/Cached';

import TaskWithChildren from './TaskWithChildren';
import TaskNoChildren from './TaskNoChildren';
import Error from 'Components/Error';

import { STATUS, STATUS_VALS, TaskNames } from 'Pages/Tasks/Tasks.types';

import {
  TaskWrapper,
  StyledList,
  StyledRefreshButton,
  StyledFetchButton,
  StyledListItemIcon,
  ChipWrapper,
} from './TaskDetails.styles';
import { StyledChip } from 'Pages/Tasks/TasksList.styles';

import { CELERY_TASK_RESULTS } from 'Queries';
import { CeleryStatus, CeleryTaskNames } from 'Constants';

interface TaskDetailsProps {
  taskId: string;
  taskJobId: number;
  taskName: TaskNames;
  taskOwner: string;
  taskDescription?: string;
}

interface TaskChildren {
  taskResults: {
    status: STATUS;
  };
}

interface TaskResults {
  status: STATUS;
  children: unknown[];
  waiting: boolean;
}

export const getStatus = (
  status: STATUS | any,
  waiting: boolean,
  children: TaskChildren[]
): STATUS => {
  const childrenStatus = (status: STATUS, length: number) => {
    if (
      children &&
      children.length > 0 &&
      // Below is comparing the total count of children tasks with provided STATUS against provided length
      children.filter((el: TaskChildren) => el.taskResults.status === status).length === length
    ) {
      return true;
    }
    return false;
  };
  if (STATUS_VALS[status]) return status;
  // While task is incomplete
  if (status === 'false' && waiting === true) {
    // and while children tasks are active
    if (childrenStatus('PENDING', children.length) !== true) return 'STARTED';
    else {
      return 'PENDING';
    }
  }
  // If task is complete and FAILED childen tasks = 0
  if (status === 'true' && childrenStatus('FAILURE', 0) === true) return 'SUCCESS';
  // If task is complete and FAILED childen tasks > 0
  if (status === 'true' && childrenStatus('FAILURE', 0) === false) return 'FAILURE';
  if (status === 'FAILURE' && waiting === false) return 'FAILURE';
  return 'UNKNOWN';
};

const Task: FC<TaskDetailsProps> = ({
  taskId,
  taskJobId,
  taskName,
  taskOwner,
  taskDescription,
}) => {
  const [height, setHeight] = useState<number | string>(0);
  const [tempData, setTempData] = useState<TaskResults>();

  const [fetchResult, { loading, error, data: gqlData }] = useLazyQuery(CELERY_TASK_RESULTS, {
    variables: { taskId: taskId },
    fetchPolicy: 'network-only',
  });

  const data = gqlData ?? tempData;
  const { children, status, waiting } = data?.getTaskResults
    ? data.getTaskResults
    : { children: [], status: '', waiting: '' };
  const statusCheck = getStatus(status, waiting, children);
  const hasChildren = children && children.length > 0;

  const handleChildRefreshButtonClick = () => {
    setTempData(data);
    fetchResult();
  };

  const handleRefreshButtonClick = (e: SyntheticEvent<HTMLButtonElement>) => {
    if (taskId === e.currentTarget.dataset.id) {
      handleChildRefreshButtonClick();
      setHeight('auto');
    }
  };

  const ChipLabel = CeleryStatus[`${statusCheck}` as STATUS];
  const ChipStyle = statusCheck;

  return (
    <TaskWrapper>
      <Grid container>
        <Grid item xs={1}>
          <StyledRefreshButton
            data-id={taskId}
            variant="form"
            color="primary"
            onClick={handleRefreshButtonClick}
          >
            <CachedIcon />
          </StyledRefreshButton>
        </Grid>
        <Grid item container xs={11}>
          <Grid item xs={12}>
            <b>Task: </b> {CeleryTaskNames[taskName] ?? taskName}
          </Grid>
          <Grid item xs={12}>
            <b>Job ID: </b>
            {`${taskJobId}`}
          </Grid>
          <Grid item xs={12}>
            <b> Owner: </b>
            {`${taskOwner}`}
          </Grid>
          {taskDescription && (
            <Grid item xs={12}>
              <b>Description: </b>
              {taskDescription}
            </Grid>
          )}
        </Grid>
      </Grid>

      <StyledList component="div" aria-label="taskList">
        {error && (
          <>
            <p>Task ID: </p> <Error error={error} />
          </>
        )}
        {loading && children?.length <= 0 && <CircularProgress />}
        {(!loading || tempData) && (
          <>
            <StyledListItemIcon>
              <ChipWrapper>
                {ChipLabel !== CeleryStatus.UNKNOWN && (
                  <StyledChip status={ChipStyle as STATUS} label={`${ChipLabel}`} />
                )}
                {ChipLabel === CeleryStatus.UNKNOWN && (
                  <StyledFetchButton
                    data-id={taskId}
                    onClick={handleRefreshButtonClick}
                    variant="form"
                    color="primary"
                  >
                    Fetch Results
                  </StyledFetchButton>
                )}
              </ChipWrapper>
            </StyledListItemIcon>
            <ListItemText
              primary={hasChildren ? 'Group Task ID: ' : 'Task ID:'}
              secondary={taskId}
            />
          </>
        )}

        {data && (
          <>
            {!hasChildren && (
              <TaskNoChildren
                taskName={taskName}
                taskId={taskId}
                taskResults={data?.getTaskResults}
                status={status}
              />
            )}
            {hasChildren && (
              <TaskWithChildren
                taskName={taskName}
                taskId={taskId}
                taskResults={data?.getTaskResults}
                height={height}
                setHeight={setHeight}
                handleChildRefreshButtonClick={handleChildRefreshButtonClick}
              />
            )}
          </>
        )}
      </StyledList>
    </TaskWrapper>
  );
};

export default Task;
