import { FC, useState } from 'react';
import { Grid, FormControl, FormLabel, Paper, Select, MenuItem } from '@material-ui/core';

import RunPlacesQuery from 'Components/RunPlacesQuery';
import ManageNLPlaces from 'Components/ManageNLPlaces';
import GCExport from 'Components/GCExport';
import ScrapingImport from 'Components/ScrapingCsvImport';

import AnimateHeight from 'react-animate-height';
import { StyledForm } from './Decommissioned.styles';

interface Location {
  location_id: number;
  name: string;
}

interface DecommissionedProps {
  locations: Location[];
  wilson_order_id: number;
  order_id: number;
}

const Decommissioned: FC<DecommissionedProps> = ({ locations, wilson_order_id, order_id }) => {
  type ComponentMap = {
    id: number;
    component: string;
    label: string;
  };

  const [selectedComponent, setSelectedComponent] = useState<number>(0);

  const componentMap: ComponentMap[] = [
    { id: 1, component: 'RunPlacesQuery', label: 'Run Places Query' },
    { id: 2, component: 'RunManageNLPlaces', label: 'Manage NL Places' },
    { id: 3, component: 'GCExport', label: 'Export Golden Categories' },
    { id: 4, component: 'ScrapingImport', label: 'Scraping CSV Import' },
  ];

  return (
    <AnimateHeight duration={500} height={'auto'}>
      <StyledForm>
        <Paper style={{ padding: 16 }}>
          <Grid container item xs={12} alignItems="flex-start" spacing={2}>
            <Grid item xs={4}>
              <Grid item xs={12} style={{ marginBottom: '20px' }}>
                <FormControl>
                  <FormLabel>Select Decommissioned Task</FormLabel>
                  <Select
                    required
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                    value={''}
                    onChange={(e) => {
                      const val = e.target.value as string;
                      setSelectedComponent(parseInt(val));
                    }}
                  >
                    {componentMap.map(({ id, label }) => (
                      <MenuItem key={label} value={id}>
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          {selectedComponent === 1 && <RunPlacesQuery locations={locations} order_id={order_id} />}
          {selectedComponent === 2 && <ManageNLPlaces />}
          {selectedComponent === 3 && <GCExport locations={locations} order_id={order_id} />}
          {selectedComponent === 4 && (
            <ScrapingImport wilson_order_id={wilson_order_id} order_id={order_id} />
          )}
        </Paper>
      </StyledForm>
    </AnimateHeight>
  );
};

export default Decommissioned;
