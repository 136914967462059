import { FC, useState } from 'react';

// Functions
import { useQuery } from '@apollo/client';

// Queries
import { GET_CHATTER_LOCATIONS } from 'Queries';

// Components
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
} from '@material-ui/core';
import Button from 'Components/Button';
import TaggingAddNewJob from './TaggingAddNewJob';

import { ChatterLocationsQueryData, ChatterLocationsQuery } from './TaggingLocationList.types';

import { TableHeading } from 'Pages/TaggingJobList/TaggingJobList.styles';

const TaggingLocationsList: FC = () => {
  const [addJob, setAddJob] = useState<ChatterLocationsQuery | undefined>(undefined);

  const { loading, error, data } = useQuery<ChatterLocationsQueryData>(GET_CHATTER_LOCATIONS);

  if (loading || error) return <div>{error}</div>;

  return (
    <TableContainer component={Paper}>
      {addJob ? (
        <TaggingAddNewJob data={addJob} onComplete={() => setAddJob(undefined)} />
      ) : (
        <>
          <TableHeading>List of Tagging Locations</TableHeading>

          <Table aria-label="List of Tagging Locations">
            <TableHead>
              <TableRow>
                <TableCell>
                  <h4>Order ID</h4>
                </TableCell>
                <TableCell>
                  <h4>Location Name</h4>
                </TableCell>
                <TableCell>
                  <h4>Images</h4>
                </TableCell>
                <TableCell>
                  <h4>Add Job</h4>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data.chatter_location.map((location, index: number) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>
                        {location.chatter_collection.order.order_id}:{' '}
                        {location.chatter_collection.order.name}
                      </TableCell>
                      <TableCell>
                        <a href={location.source_link} target="blank">
                          {location.source_location_identifier}
                        </a>
                      </TableCell>
                      <TableCell>{location.chatter_images_aggregate.aggregate.count}</TableCell>
                      <TableCell>
                        <Button
                          variant="form"
                          color="primary"
                          disabled={location.chatter_images_aggregate.aggregate.count <= 0}
                          onClick={() => {
                            setAddJob(location);
                          }}
                        >
                          Add Job
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </>
      )}
    </TableContainer>
  );
};

export default TaggingLocationsList;
