import { FC, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation, useLazyQuery } from '@apollo/client';

import Button from 'Components/Button';

// Styles
import { FooterContainer } from './TaggingInterface.styles';

import { UPDATE_TAGGING_JOB_STATUS, CLOSE_TAGGING_JOB, GET_TAGGING_JOB_IMAGES } from 'Queries';
import {
  JobAction,
  JobActionSubmit,
  JobActionClose,
  TaggingJobImagesQueryData,
} from './TaggingInterface.types';

type TaggingInterfaceFooterProps = {
  jobAction: JobAction;
};

const getJobActionButton = (jobAction: JobAction) => {
  switch (jobAction.type) {
    case 'submit':
      return <JobActionSubmitButton jobActionSubmit={jobAction}></JobActionSubmitButton>;
    case 'close':
      return <JobActionCloseButton jobActionClose={jobAction}></JobActionCloseButton>;
    default:
      <div>Huh, shouldn't see this</div>;
  }
};

const TaggingInterfaceFooter: FC<TaggingInterfaceFooterProps> = ({ jobAction }) => {
  const actionButton = useMemo(
    () => getJobActionButton(jobAction),
    [jobAction.taggingJobId, jobAction.type, jobAction.enabled]
  );

  return <FooterContainer>{actionButton}</FooterContainer>;
};

type JobActionSubmitButtonProps = {
  jobActionSubmit: JobActionSubmit;
};

const JobActionSubmitButton: FC<JobActionSubmitButtonProps> = ({ jobActionSubmit }) => {
  const history = useHistory();

  const [updateTaggingJob] = useMutation(UPDATE_TAGGING_JOB_STATUS, {
    variables: {
      tagging_job_id: jobActionSubmit.taggingJobId,
      new_status: 'submitted',
    },
    onCompleted: () => {
      history.push('/tagging-jobs');
    },
  });
  return (
    <Button
      variant="form"
      color="secondary"
      onClick={updateTaggingJob}
      disabled={!jobActionSubmit.enabled}
    >
      Submit Job
    </Button>
  );
};

type JobActionCloseButtonProps = {
  jobActionClose: JobActionClose;
};

const JobActionCloseButton: FC<JobActionCloseButtonProps> = ({ jobActionClose }) => {
  const history = useHistory();
  const [processing, setProcessing] = useState<boolean>(false);

  const [closeTaggingJobMutation] = useMutation(CLOSE_TAGGING_JOB, {
    onCompleted: () => {
      setProcessing(false);
      history.push('/tagging-jobs');
    },
    onError: (e) => {
      setProcessing(false);
      console.error(e);
    },
  });

  const [getTaggingJobImagesQuery] = useLazyQuery<TaggingJobImagesQueryData>(
    GET_TAGGING_JOB_IMAGES,
    {
      variables: { tagging_job_id: jobActionClose.taggingJobId },
      fetchPolicy: 'network-only',
      onCompleted: (d) => {
        closeTaggingJobMutation({
          variables: {
            tagging_job_id: jobActionClose.taggingJobId,
            image_categories: d.tagging_job_image.map((ti) => ({
              chatter_image_id: ti.chatter_image.chatter_image_id,
              chatter_category_id: ti.chatter_category_id,
            })),
          },
        });
      },
      onError: (e) => {
        setProcessing(false);
        console.error(e);
      },
    }
  );

  const closeTaggingJob = () => {
    setProcessing(true);
    getTaggingJobImagesQuery();
  };
  return (
    <Button
      variant="form"
      color="secondary"
      onClick={closeTaggingJob}
      disabled={!jobActionClose.enabled || processing}
    >
      {!processing ? 'Close Job' : 'Closing...'}
    </Button>
  );
};

export default TaggingInterfaceFooter;
