import styled from 'styled-components';
import { ListItem, ListItemText } from '@material-ui/core';
import { MenuWidth } from 'Constants';

export const MenuWrapper = styled.div`
  min-height: 100%;
  display: flex;
  width: ${MenuWidth};
  flex-basis: 20rem;
  align-items: center;
  flex-grow: 1;
  flex-direction: column;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: #fff;
  overflow: hidden;
  nav {
    min-width: 100%;
    margin-top: 7rem;
  }
  &:hover {
    overflow-y: auto;
  }
`;

export const StyledListItem = styled(ListItem)<any>`
  background-color: ${(props) => (props.selected ? '#ddd' : '#fff')} !important;
  &:hover {
    background-color: rgba(0, 0, 0, 0.08) !important;
  }
  font-size: 17px;
  > div {
    padding-left: 0rem;
  }
`;

export const StyledSubListItem = styled(ListItem)<any>`
  background-color: ${(props) => (props.selected ? '#eee' : '#fff')} !important;
  &:hover {
    background-color: rgba(0, 0, 0, 0.08) !important;
  }
  font-size: 17px;
  > div {
    padding: 0.2;
  }
`;

export const StyledListItemText = styled(ListItemText)`
  margin-left: 0.2rem;
  font-size: 16px;
`;
