import React, { FC, useState } from 'react';
import {
  Paper,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
} from '@material-ui/core';
import { Form } from 'react-final-form';
import { TextField, Checkboxes } from 'mui-rff';
import { useMutation } from '@apollo/client';
import Button from 'Components/Button';
import { IMPORT_ORDER } from 'Queries';
import { StyledForm } from './ImportOrder.styles';
import CardBasic from 'Components/CardBasic';
import Error from 'Components/Error';

export interface LocationMap {
  added?: string;
  updated?: string;
  area_added?: string;
  area_updated?: string;
  location: {
    location_id: number;
    radius: number;
    geometry: {
      coordinates: number[];
      srid: number;
      data: {
        POINT: string;
      };
    };
    postcode: number;
    name: string;
    wilson_area_id: number;
  };
}

interface Results {
  orderId: number;
  orderName: string;
  locations: LocationMap[];
  error?: {
    message: string;
  };
}

const ImportOrder: FC = () => {
  const [results, setResults] = useState<Results>({ orderId: 0, orderName: '', locations: [] });

  const [runImportOrder, { loading }] = useMutation(IMPORT_ORDER, {
    onCompleted: ({ importWilsonOrder: { orderId, orderName, locations } }) => {
      setResults((prev) => ({ ...prev, orderId, orderName, locations }));
    },
  });

  const removeError = () => {
    const { orderId, orderName, locations } = results;
    return setResults({ orderId, orderName, locations });
  };

  const onSubmit = async ({ wilsonOrderId, polygonOverride }: any) => {
    const { orderId } = results;
    setResults({ orderId, orderName: '', locations: [] });
    if (wilsonOrderId === 'string' || !wilsonOrderId)
      return setResults((prev) => ({ ...prev, error: { message: 'order number required' } }));
    return runImportOrder({ variables: { wilsonOrderId, polygonOverride } }).catch((error) => {
      // you can do something with the error here like this:
      setResults((prev) => ({ ...prev, error }));
    });
  };

  return (
    <>
      <CardBasic elevation={0}>
        <Typography variant="h5" component="h2" align="center" style={{ padding: 8 }}>
          Import Order from Wilson
        </Typography>
      </CardBasic>
      <StyledForm>
        <Form
          initialValues={{ wilsonOrderId: null, polygonOverride: false }}
          onSubmit={onSubmit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} noValidate>
              <Paper style={{ padding: 16 }}>
                <Typography variant="body1" align="center">
                  Enter Order ID:
                </Typography>
                <Error error={results.error} />
                <Grid container alignItems="flex-start" spacing={4}>
                  <Grid item xs={12}>
                    <TextField
                      name="wilsonOrderId"
                      error={results.error ? true : false}
                      type="number"
                      required
                      onFocus={removeError}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Checkboxes
                      name="polygonOverride"
                      data={{
                        label: 'Override polygons?',
                        value: true,
                      }}
                      helperText="Override order area polygons (that could have been manually populated, use with caution!)"
                    />
                  </Grid>

                  <Grid item style={{ marginTop: 20 }}>
                    <Button type="submit" variant="form" color="primary">
                      {loading && <CircularProgress size={14} color="inherit" />}
                      {!loading && 'Submit'}
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </form>
          )}
        />
      </StyledForm>
      <TableContainer component={Paper} elevation={0}>
        <Typography variant="body1" align="center">
          {results.orderName}
        </Typography>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Location status</TableCell>
              <TableCell>Order Area status</TableCell>
              <TableCell>Order Id</TableCell>
              <TableCell>Wilson Area Id</TableCell>
              <TableCell>Postcode</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Geometry</TableCell>
              <TableCell>Radius</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {results.locations.length > 0 &&
              results.locations.map((location) => {
                const {
                  added,
                  updated,
                  area_added,
                  area_updated,
                  location: { radius, geometry, postcode, name, wilson_area_id },
                }: LocationMap = JSON.parse(`${location}`);
                return (
                  <TableRow key={name}>
                    <TableCell>{`Added: ${added} Updated: ${updated} `}</TableCell>
                    <TableCell>{`Added: ${area_added} Updated: ${area_updated} `}</TableCell>
                    <TableCell>{results.orderId}</TableCell>
                    <TableCell>{wilson_area_id}</TableCell>
                    <TableCell>{postcode}</TableCell>
                    <TableCell>{name}</TableCell>
                    <TableCell>{geometry.data}</TableCell>
                    <TableCell>{radius}</TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ImportOrder;
