import {
  FC,
  SetStateAction,
  useEffect,
  useState,
  useContext,
  useCallback,
  ChangeEvent,
  MouseEvent,
} from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Select,
  MenuItem,
} from '@material-ui/core';
import { ThemeContext } from 'Providers/ThemeProvider';
import { OrderArea } from 'Pages/Order/Order.types';
import VerticalSpacer from 'Components/Spacer';
import { TableWrapper, TableHeader, TableRow, TableCell } from './OrderAreaForm.styles';

// Helpers
import { sortOrderAreas } from 'helpers';

interface OrderAreaFormProps {
  selectedAreas: number[];
  setSelectedAreas: (value: SetStateAction<number[]>) => void;
  setLastSelectedArea: (value: SetStateAction<number>) => void;
  orderAreas: OrderArea[];
  disabled?: boolean;
}

const sortFilters = ['name', 'area_id'] as const;
type SortFiltersType = typeof sortFilters[number];

type ChangeType = 'all' | 'none' | number;

const maxHeight = {
  maxHeight: '40px',
};

const disableScrollLock = {
  disableScrollLock: true,
};

const OrderAreaForm: FC<OrderAreaFormProps> = ({
  selectedAreas,
  setSelectedAreas,
  setLastSelectedArea,
  orderAreas,
  disabled = false,
}) => {
  const [sortFilter, setSortFilter] = useState<SortFiltersType>('name');
  const [sortedOrderAreas, setSortedOrderAreas] = useState<OrderArea[]>(orderAreas);

  const colorContext = useContext(ThemeContext).color;

  const onCheckboxChange = useCallback(
    (change: ChangeType) => {
      if (change === 'none') {
        setSelectedAreas([]);
      } else if (change === 'all') {
        const orderAreaIds: number[] = orderAreas.map(({ order_area_id }) => order_area_id);
        setSelectedAreas(orderAreaIds);
      } else if (typeof change === 'number') {
        // This will toggle the provided order_area_id being selected/unselected
        const order_area_id: number = change;
        setLastSelectedArea(order_area_id);
        const filteredAreas =
          selectedAreas.indexOf(order_area_id) !== -1
            ? selectedAreas.filter((oa_id) => oa_id !== order_area_id)
            : [...selectedAreas, order_area_id];
        setSelectedAreas(filteredAreas);
      }
    },
    [selectedAreas]
  );

  const handleSortFilterChange = (
    event: ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    const val = event.target.value as SortFiltersType;
    setSortFilter(val);
  };

  const handleSelectedAreaChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const val = event.target.value as ChangeType;
      !disabled && onCheckboxChange(val);
    },
    [selectedAreas]
  );

  const handleRowClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      const val = event.currentTarget.id;
      !disabled && onCheckboxChange(parseInt(val));
    },
    [selectedAreas]
  );

  useEffect(() => {
    setSortedOrderAreas(sortOrderAreas(orderAreas, sortFilter));
  }, [orderAreas, sortFilter]);

  return (
    <FormControl component="fieldset">
      <FormLabel>Selected Areas: {selectedAreas.length}</FormLabel>
      <Grid item xs={12}>
        <FormControlLabel
          label="Select All"
          control={
            <Checkbox
              name="areas"
              color={colorContext}
              value={'all'}
              style={maxHeight}
              checked={selectedAreas.length === orderAreas.length}
              disabled={disabled}
              onChange={handleSelectedAreaChange}
            />
          }
        />
        <FormControlLabel
          label="Select None"
          control={
            <Checkbox
              name="areas"
              color={colorContext}
              value={'none'}
              style={maxHeight}
              checked={selectedAreas.length <= 0}
              disabled={disabled}
              onChange={handleSelectedAreaChange}
            />
          }
        />
        <FormLabel>Sort By: </FormLabel>
        <Select
          name="sort-filter"
          labelId="sort-filter"
          id="sort-filter"
          label="Select Sort"
          required
          MenuProps={disableScrollLock}
          value={sortFilter}
          onChange={handleSortFilterChange}
        >
          {sortFilters.map((val) => (
            <MenuItem key={val} value={val}>
              {val}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <VerticalSpacer />
      <TableWrapper>
        <TableHeader>
          <TableCell></TableCell>
          <TableCell>Location</TableCell>
          <TableCell>Wilson Area ID</TableCell>
          <TableCell>Order Area ID</TableCell>
        </TableHeader>
        {sortedOrderAreas.map(({ order_area_id, wilson_area_id, name }: OrderArea) => (
          <TableRow
            key={order_area_id}
            id={`${order_area_id}`}
            disabled={disabled}
            onClick={handleRowClick}
          >
            <TableCell>
              <FormLabel key={order_area_id}>
                <Checkbox
                  name="areas"
                  color={colorContext}
                  value={order_area_id}
                  style={maxHeight}
                  disabled={disabled}
                  checked={selectedAreas.indexOf(order_area_id) > -1}
                  onChange={handleSelectedAreaChange}
                />
              </FormLabel>
            </TableCell>
            <TableCell>{name}</TableCell>
            <TableCell>{wilson_area_id}</TableCell>
            <TableCell>{order_area_id}</TableCell>
          </TableRow>
        ))}
      </TableWrapper>
    </FormControl>
  );
};

export default OrderAreaForm;
