import { FC, ChangeEvent, useState, useEffect, FormEvent, useCallback, useContext } from 'react';
import { useMutation } from '@apollo/client';

import {
  MenuItem,
  Select,
  FormControl,
  FormControlLabel,
  FormLabel,
  Checkbox,
  Paper,
  Grid,
} from '@material-ui/core';
import { StyledForm } from './RunPlacesQueryNLPlaces.styles';
import AnimateHeight from 'react-animate-height';
import { Errors } from 'Components/Error/Error.types';
import SubmitJob from 'Components/SubmitJob';
import { ThemeContext } from 'Providers/ThemeProvider';

import { RUN_PLACES_QUERY_AND_NL_PLACES, QueryGranularity } from 'Queries';
import { apiStringValues, ApiStringType } from 'Pages/Order/Order.types';

interface Location {
  location_id: number;
  name: string;
}

interface RunPlacesQueryNLPlaces {
  order_id: number;
  locations: Location[];
}

interface RunPlacesQueryNLPlacesReturn {
  runPlacesQueryNlPlaces: {
    taskId?: string;
    error?: { message: string };
  };
}

type EnabledFlag = {
  name: string;
  label: string;
  checked: boolean;
  onChange: () => void;
};

const maxHeight = {
  maxHeight: '40px',
};

const RunPlacesQueryNLPlaces: FC<RunPlacesQueryNLPlaces> = ({
  order_id,
  locations,
}: RunPlacesQueryNLPlaces) => {
  const [taskId, setTaskId] = useState<string | undefined>(undefined);
  const [errors, setErrors] = useState<Errors | undefined>(undefined);
  const [height, setHeight] = useState<number | string>(0);

  const locationIds = locations.map(({ location_id }) => location_id);
  // Form States
  const [selectedLocations, setSelectedLocations] = useState<number[]>(locationIds);
  const [selectedAPI, setSelectedAPI] = useState<ApiStringType | undefined>(undefined);
  const [populatePpa, setPopulatePpa] = useState<boolean>(false);

  // Selected Enabled Flags
  const [demographicsEnabled, setDemographicsEnabled] = useState<boolean>(true);
  const [isochronesEnabled, setIsochronesEnabled] = useState<boolean>(true);
  const [relevanceOverTimeEnabled, setRelevanceOverTimeEnabled] = useState<boolean>(true);
  const [presenceEnabled, setPresenceEnabled] = useState<boolean>(true);
  const [keywordsEnabled, setKeywordsEnabled] = useState<boolean>(true);
  const [chatterEnabled, setChatterEnabled] = useState<boolean>(true);

  const colorContext = useContext(ThemeContext);

  const [runPlaceQueryNLPlaces, { loading }] = useMutation<RunPlacesQueryNLPlacesReturn>(
    RUN_PLACES_QUERY_AND_NL_PLACES,
    {
      onCompleted: ({
        runPlacesQueryNlPlaces: { taskId, error },
      }: RunPlacesQueryNLPlacesReturn) => {
        if (taskId) setTaskId(taskId);
        if (error) setErrors({ message: `${error.message}` } as Errors);
      },
      onError: (err) => {
        setErrors({ message: `${err.message}` } as Errors);
      },
    }
  );

  const enabledFlags: EnabledFlag[] = [
    {
      name: 'isochronesEnabled',
      label: 'Isochrones',
      checked: isochronesEnabled,
      onChange: () => {
        setIsochronesEnabled(!isochronesEnabled);
      },
    },
    {
      name: 'demographicsEnabled',
      label: 'Demographics',
      checked: demographicsEnabled,
      onChange: () => {
        setDemographicsEnabled(!demographicsEnabled);
      },
    },
    {
      name: 'relevanceOverTimeEnabled',
      label: 'Relevance Over Time',
      checked: relevanceOverTimeEnabled,
      onChange: () => {
        setRelevanceOverTimeEnabled(!relevanceOverTimeEnabled);
      },
    },

    {
      name: 'chatterEnabled',
      label: 'Chatter',
      checked: chatterEnabled,
      onChange: () => {
        setChatterEnabled(!chatterEnabled);
      },
    },
    {
      name: 'presenceEnabled',
      label: 'Presence',
      checked: presenceEnabled,
      onChange: () => {
        setPresenceEnabled(!presenceEnabled);
      },
    },
    {
      name: 'keywordsEnabled',
      label: 'Keywords',
      checked: keywordsEnabled,
      onChange: () => {
        setKeywordsEnabled(!keywordsEnabled);
      },
    },
  ];

  useEffect(() => {
    setHeight(() => 'auto');
    setSelectedLocations(locationIds);
    colorContext.color != 'primary' && colorContext.setColor('primary');
  }, [locations]);

  useEffect(() => {
    if (populatePpa && !(selectedLocations.length > 0)) {
      setPopulatePpa(false);
    }
    const api: boolean = selectedAPI ? true : false;
    const places: boolean = selectedLocations.length > 0;
    updateError(api, places);
  }, [selectedLocations, selectedAPI]);

  const updateError = useCallback(
    (api: boolean, places: boolean) => {
      if (api && places) {
        setErrors(undefined);
      } else if (!api && places) {
        setErrors({ message: `Please select an API` } as Errors);
      } else if (api && !places) {
        setErrors({ message: `Please select at least one location` } as Errors);
      } else {
        setErrors({ message: `Please select an API and at least one location` } as Errors);
      }
    },
    [selectedAPI, selectedLocations]
  );

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    return runPlaceQueryNLPlaces({
      variables: {
        order_id: order_id,
        apiStrings: selectedAPI,
        locationIds: selectedLocations,
        granularity: QueryGranularity.Progressive,
        populatePpa: populatePpa,
        demographicsEnabled: populatePpa && demographicsEnabled,
        isochronesEnabled: populatePpa && isochronesEnabled,
        relevanceOverTimeEnabled: populatePpa && relevanceOverTimeEnabled,
        presenceEnabled: populatePpa && presenceEnabled,
        keywordsEnabled: populatePpa && keywordsEnabled,
        chatterEnabled: populatePpa && chatterEnabled,
      },
    });
  };

  const onLocationChange = (location_id: number) => {
    const newLocations = [...selectedLocations];
    const locationIndex = newLocations.indexOf(location_id);

    if (locationIndex === -1) newLocations.push(location_id);
    else newLocations.splice(locationIndex, 1);

    setSelectedLocations(newLocations);
  };

  const handlePpaChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPopulatePpa(event.target.checked);
  };

  return (
    <AnimateHeight duration={500} height={height}>
      <StyledForm>
        <Paper style={{ padding: 16 }}>
          <Grid container item xs={12} alignItems="flex-start" spacing={2}>
            <Grid item xs={4}>
              <Grid item xs={12} style={{ marginBottom: '20px' }}>
                <FormControl>
                  <FormLabel>Select API</FormLabel>
                  <Select
                    name="apiStrings"
                    labelId="api-strings-label"
                    id="api-strings"
                    label="Please Select an API"
                    required
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                    value={selectedAPI ?? ''}
                    onChange={(e) => {
                      const val = e.target.value as ApiStringType;
                      setSelectedAPI(val);
                    }}
                    disabled={loading}
                  >
                    {apiStringValues.map((val) => (
                      <MenuItem key={val} value={val}>
                        {val}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormControlLabel
                    label="Populate PPA and PPAOA?"
                    control={
                      <Checkbox
                        disabled={loading || !(selectedLocations.length > 0)}
                        color={colorContext.color}
                        id="populate-ppa"
                        checked={populatePpa}
                        onChange={handlePpaChange}
                      />
                    }
                  ></FormControlLabel>
                  {enabledFlags.map((flag: EnabledFlag) => {
                    return (
                      <Grid item xs={12} key={flag.name}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name={flag.name}
                              color="primary"
                              checked={flag.checked}
                              onChange={flag.onChange}
                              disabled={!populatePpa}
                            />
                          }
                          label={flag.label}
                        />
                      </Grid>
                    );
                  })}
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={8}>
              <FormControl component="fieldset">
                <FormLabel>Select locations</FormLabel>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="locations"
                        color={colorContext.color}
                        value={'all'}
                        checked={selectedLocations.length === locationIds.length}
                        onChange={() => {
                          setSelectedLocations([...locationIds]);
                        }}
                        disabled={loading}
                      />
                    }
                    label="Select All"
                    style={maxHeight}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="locations"
                        color="primary"
                        value={'none'}
                        checked={selectedLocations.length <= 0}
                        onChange={() => {
                          setSelectedLocations([]);
                        }}
                      />
                    }
                    label="Select None"
                    style={maxHeight}
                  />
                </Grid>
                {locations.map(({ location_id, name }: Location) => (
                  <FormControlLabel
                    key={location_id}
                    control={
                      <Checkbox
                        name="locations"
                        color="primary"
                        value={location_id}
                        onChange={() => {
                          onLocationChange(location_id);
                        }}
                        checked={selectedLocations.indexOf(location_id) > -1}
                        disabled={loading}
                      />
                    }
                    label={name}
                    style={maxHeight}
                  />
                ))}
              </FormControl>
            </Grid>
            <SubmitJob
              taskClickAction={onSubmit}
              taskId={taskId}
              taskName={'Places Query'}
              errors={errors}
              loading={loading}
              disabled={selectedLocations.length === 0 || selectedAPI === undefined}
            />
          </Grid>
        </Paper>
      </StyledForm>
    </AnimateHeight>
  );
};

export default RunPlacesQueryNLPlaces;
