import { ROUTE_ACCESS } from 'Constants';
export interface MenuData {
  id: number;
  name: string;
  to: string;
  access: string[];
  submenu?: MenuData[];
}

export const menuData: MenuData[] = [
  {
    id: 0,
    name: 'Orders',
    to: '/orders',
    access: ROUTE_ACCESS['/orders'],
    submenu: [
      {
        id: 1,
        name: 'Orders',
        to: '/orders',
        access: ROUTE_ACCESS['/orders'],
      },
      { id: 2, name: 'All Orders', to: '/all-orders', access: ROUTE_ACCESS['/all-orders'] },
      { id: 3, name: 'Import Order', to: '/import-order', access: ROUTE_ACCESS['/import-order'] },
    ],
  },
  { id: 4, name: 'Tasks', to: '/tasks', access: ROUTE_ACCESS['/tasks'] },
  {
    id: 5,
    name: 'Tagging',
    to: '/tagging-jobs',
    access: ROUTE_ACCESS['/tagging'],
    submenu: [
      {
        id: 1,
        name: 'Job List',
        to: '/tagging-jobs',
        access: ROUTE_ACCESS['/tagging'],
      },
      {
        id: 2,
        name: 'Locations List',
        to: '/tagging-locations',
        access: ROUTE_ACCESS['/tagging-locations'],
      },
      {
        id: 3,
        name: 'Order Image Library',
        to: '/order-image-library',
        access: ROUTE_ACCESS['/order-image-library'],
      },
    ],
  },
  {
    id: 6,
    name: 'Tools',
    to: '/tools',
    access: ROUTE_ACCESS['/tools'],
    submenu: [
      {
        id: 1,
        name: 'Export KML',
        to: '/export-kml',
        access: ROUTE_ACCESS['/export-kml'],
      },
    ],
  },
];
