import styled from 'styled-components';
import { Grid, FormControl } from '@material-ui/core';

export const StyledFormControl = styled(FormControl)`
  padding-right: 2rem;
`;

export const StyledGridList = styled(Grid)`
  padding-top: 0.5rem;
`;
