import React, { FC } from 'react';
import Button from 'Components/Button';
import { useAuth } from 'Providers';
import * as Header from './Header.styles';
import { getCookie } from 'helpers';

const StyledHeader: FC = () => {
  const { signout } = useAuth();
  const cookie = getCookie('access_token');

  return (
    <Header.Nav role="navigation">
      <Header.NavHeader>
        <Header.Container>
          <Header.LinkLogo to="/">
            <img src="/neighbourlytics-logo.svg" alt="Neighbourlytics logo" />
          </Header.LinkLogo>
          <Header.LinkWrapper>
            {cookie !== null && (
              <Button color="primary" onClick={signout}>
                Sign out
              </Button>
            )}
          </Header.LinkWrapper>
        </Header.Container>
      </Header.NavHeader>
    </Header.Nav>
  );
};
export default StyledHeader;
