import styled from 'styled-components';
import Button from 'Components/Button';

export const StyledModal = styled.div`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  width: 40rem;
  background-color: #fff;
  padding: 1.5rem;
`;

export const StyledCloseButton = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;
  padding: 3px;
  border-radius: 50%;
`;
