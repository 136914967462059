import { useState, createContext } from 'react';
import { StylesProvider, ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { defaultMuiTheme } from 'Components/GlobalStyles/Theme';

import { BackgroundStyles, BartThemeContextType } from 'Providers/BartThemeProvider.types';

export const BartThemeContext = createContext<BartThemeContextType>({
  imageListScoll: true,
  setImageListScoll: () => null,
  backgroundStyle: 'light',
  setBackgroundStyle: () => null,
} as BartThemeContextType);

export const BartThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const [imageListScoll, setImageListScoll] = useState<boolean>(true);
  const [backgroundStyle, setBackgroundStyle] = useState<BackgroundStyles>('light');

  return (
    <BartThemeContext.Provider
      value={{
        imageListScoll: imageListScoll,
        setImageListScoll: setImageListScoll,
        backgroundStyle: backgroundStyle,
        setBackgroundStyle: setBackgroundStyle,
      }}
    >
      <StylesProvider>
        <MuiThemeProvider theme={defaultMuiTheme}>{children}</MuiThemeProvider>
      </StylesProvider>
    </BartThemeContext.Provider>
  );
};
