import styled from 'styled-components';
import {
  List,
  ListItem,
  ListProps,
  ListItemProps,
  ListTypeMap,
  ListItemIcon,
  TableContainer,
} from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import Button from 'Components/Button';
import { CeleryStatusColor } from 'Constants';
import { STATUS } from 'Pages/Tasks/Tasks.types';

type ComponentTypes = 'div' | 'ul' | 'li';
type FlexAlignments = 'center' | 'flex-start' | undefined;

interface StyledListItem
  extends ListItemProps,
    OverridableComponent<
      ListTypeMap<{ component: ComponentTypes; alignItems?: FlexAlignments }, 'div'>
    > {}

interface StyledList
  extends ListProps,
    OverridableComponent<
      ListTypeMap<{ component: ComponentTypes; alignItems?: FlexAlignments }, 'div'>
    > {}

export const TaskWrapper = styled.div`
  width: 100%;
  min-height: 10rem;
  position: relative;
`;

export const StyledList = styled<StyledList>(List)`
  padding-left: 1rem;
`;

export const StyledFilterList = styled<any>(List)`
  div:not(:first-child) {
    padding-left: 1rem;
  }
`;

export const StyledFilterButton = styled<any>(Button)`
  border-radius: 16px;
  font-size: 13px;
  font-family: 'Sofia Pro', 'Nexa', sans-serif;
  font-weight: 400;
  background-color: ${(props) => CeleryStatusColor[`${props.status}` as STATUS] || 'lightgrey'};
  &:hover {
    opacity: 75%;
    background-color: ${(props) => CeleryStatusColor[`${props.status}` as STATUS]};
  }
`;

export const StyledListItem = styled<StyledListItem>(ListItem)`
  flex-direction: column;
  padding-top: 0;
  padding-bottom: 0;
  cursor: pointer;
`;

export const StyledListItemAlignRight = styled<StyledListItem>(ListItem)`
  flex-direction: column;
  padding-bottom: 0;
  align-items: flex-end;
`;

export const StyledListContentBlock = styled.div`
  flex-basis: 100%;
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;

export const StyledListContentText = styled.div`
  padding-left: 1.5em;
`;

export const StyledListHeader = styled(StyledListContentBlock)`
  display: inline-flex;
  cursor: pointer;
`;

export const StyledExpandButton = styled.div`
  color: #555;
  cursor: pointer;
  position: absolute;
  top: 40%;
  right: 0.5rem;
`;

export const StyledRefreshButton = styled(Button)`
  padding: 3px;
  border-radius: 50%;
  z-index: 9;
`;

export const StyledFetchButton = styled(Button)`
  margin-left: 0;
`;

export const StyledDeleteButton = styled(Button)`
  margin-left: 6rem;
  padding: 3px 5px;
  z-index: 9;
  float: right;
  padding-right: 10px;
  & span {
    padding: 0px 2px;
  }
  cursor: pointer;
`;

export const ChipWrapper = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  justify-content: space-between;
  align-items: center;
`;

export const StyledListItemIcon = styled(ListItemIcon)`
  min-width: 100%;
  svg {
    cursor: pointer;
  }
`;

export const StyledTableContainer = styled(TableContainer)`
  width: 500px;
  padding-left: 0 !important;
  display: block;
  position: relative;
`;

export const ExpandButtonWrapper = styled.div`
  color: #555;
  cursor: pointer;
  margin-left: auto;
`;

export const ButtonWrapper = styled.div`
  height: auto;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  + div {
    margin-right: auto;
  }
`;
